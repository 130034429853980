import PaymentList from './PaymentList';

const PaymentsRoutes = [
  {
    url: "/",
    component: PaymentList,
    permission: "trips_payment",
    props: {}
  },
]

export default PaymentsRoutes;
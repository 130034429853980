import { combineReducers } from "@reduxjs/toolkit";
import { ErrorState } from "./ErrorState";
import { FiltersState } from "./FiltersState";
import { AlertState } from "./AlertState";
import { UserState } from "./UserState";
import { FiltersLocalState } from "./FiltersLocalState";
import { GeneralState } from "./GeneralState";

const AppState = {
  [GeneralState.name]: GeneralState.reducer,
  [ErrorState.name]: ErrorState.reducer,
  [FiltersState.name]: FiltersState.reducer,
  [FiltersLocalState.name]: FiltersLocalState.reducer,
  [AlertState.name]: AlertState.reducer,
  [UserState.name]: UserState.reducer,
};

export default  AppState;
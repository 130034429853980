import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const GeneralState = createSlice({
  name: 'generalState',
  initialState: {},
  reducers: {
    setChangePassword: (state, action) => {
      return {...state, changePassword: action.payload};
    },
  },
});

// this is for dispatch
export const { setChangePassword } = GeneralState.actions;

// this is for configureStore
export default GeneralState.reducer;

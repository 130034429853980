import { useEffect, useState } from "react";
import { Table } from "../../components/table";

import {
  DateInput,
  SelectInput,
} from "../../components/input";

import { formatDateForDb } from "../../utils/helpers/helpers";
import { numberFormat } from './../../utils/helpers/helpers';
import { useDispatch, useSelector } from "react-redux";
import { clearAlert, setAlert } from "../../states/AlertState";
import { clearFilters, setFilters } from "../../states/FiltersState";
import { useGetIncomeAndExpenseReportQuery } from "../../services/reports/ReportService";

interface IncomeVsExpenseReportProps {
  exportTitle?: string,
  trip?: any,
  vehicle?: any,
  employee?: any,
  useLocalFilters?: boolean,
  [rest: string]: any,
}

const IncomeVsExpenseReport = (props: IncomeVsExpenseReportProps) => {
  const filters = useSelector((state: any) => state.filtersState);
  const dispatch = useDispatch();  

  const { data, isLoading, isSuccess, isFetching, status, isError, error } = useGetIncomeAndExpenseReportQuery({
    ...filters,
    trip: props.trip?.trip_id,
    vehicle: filters.vehicle || props.vehicle?.vehicle_id, 
    employee: filters.employee || props.employee?.user_id,
  });

  const [vehicles, setVehicles] = useState<any[]>([]);

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    data?.vehicles && setVehicles(data.vehicles.map((vehicle: any) => ({label: vehicle.registration_number, value: vehicle.vehicle_id})));
  }, [data])

  useEffect(() => {
    return () => {
      dispatch(clearFilters())
    }
  }, [])

  return (
    <>
      <div className={`row shadow bg-white p-2 mb-1 ${(props.trip || props.vehicle || props.expenseCategoryID) ? "justify-center" : ""}`}>
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Date From"
            block={true}
            value={new Date(filters.from_date)}
            onChange={(value) => {
              dispatch(setFilters({
                from_date: formatDateForDb(value),
              }))
            }}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Date To"
            block={true}
            value={new Date(filters.to_date)}
            onChange={(value) => {
              dispatch(setFilters({
                to_date: formatDateForDb(value),
              }))
            }}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Vehicle"
            block={true}
            clearable
            options={vehicles}
            onChange={(value) => dispatch(setFilters({
              vehicle: value?.value
            }))}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div>
            <Table
              loading={isLoading}
              title="Income Vs Expense Report"
              exportTitle={props.exportTitle}
              noSearch
              columns={[
                {label: "Date", name: "transaction_date"},
                {label: "Vehicle", name: "vehicle_registration_number"},
                {label: "Category", name: "category"},
                {label: "Type", name: "type"},
                {label: "Dr. (Tsh)", name: "debit", customRender: true},
                {label: "Cr. (Tsh)", name: "credit", customRender: true},
                {label: "Balance (Tsh)", name: "balance", customRender: true},
              ]}
              items={data?.transactions || []}
              onSearch={(search) => dispatch(setFilters({search: search}))}
              pagination={{
                ...(data?.pagination || {}),
                onPageChange: (page) => dispatch(setFilters({page: page})),
                onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
              }}
              params={filters}
              customRenders={[
                {
                  columnName: "index",
                  render: (item, index) => <span>{index + 1}</span>
                },
                {
                  columnName: "debit",
                  render: (item, index) => <span>{item.debit && numberFormat(item.debit)}</span>
                },
                {
                  columnName: "credit",
                  render: (item, index) => <span>{item.credit && numberFormat(item.credit)}</span>
                },
                {
                  columnName: "balance",
                  render: (item, index) => <span>{item.balance && numberFormat(item.balance)}</span>
                },
              ]}
              totalColumns={[
                { name: "debit" },
                { name: "credit" },
                { name: "balance", value: data?.transactions[0]?.balance || 0 }
              ]}
              totalColSpan={5}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default IncomeVsExpenseReport;

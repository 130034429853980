import MenuItems from './MenuItems';

// styles
import './NavBar.scss';

interface NavBarProps {
  menuItems: any[],
}

const NavBar = (props: NavBarProps) => {
  return (
    <nav>
      <ul className="menus">
        {props.menuItems.map((menu, index) => {
          const depthLevel = 0;
          return (
            <MenuItems
              items={menu}
              key={index}
              depthLevel={depthLevel}
              dropdownClassName={menu?.dropdownClassName}
            />
          );
        })}
      </ul>
    </nav>
  );
};

export default NavBar;

import { AppService } from '../../../AppService';

export const PermissionService = AppService.injectEndpoints({
  endpoints: (builder) => ({
    getPermissions: builder.query({
        query: () => ({
            url: "/permissions",
            method: "GET",
        }),
        providesTags: ['App', 'Permissions', 'GetPermissions'],
    }),
  }),
});

export const {
    useGetPermissionsQuery,
} = PermissionService;

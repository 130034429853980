import React, { MutableRefObject, useMemo, useRef, useState } from "react";
import { Form } from "../../../../components/form";
import {
  SelectInput,
} from "../../../../components/input";
import { ConfirmationDialog, Modal } from "../../../../components/modal";
import { getValidationRules} from '../../../../utils/helpers/helpers';
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../../states/AlertState";
import { useCreateGeneralConfigMutation } from "../../../../services/settings/general/GeneralConfigService";

interface ChargeAddProps {
  charges: {label: any, value: any}[],
  modal?: MutableRefObject<any>,
}

const ChargeAdd = (props: ChargeAddProps) => {
  const user = useSelector((state: any) => state.userState);
  const dispatch = useDispatch();
  const inputValidation = getValidationRules(); 

  const form = useRef<any>();
  const modal = useRef<any>();
  const inputs: any = useMemo(
    () => Array.from({length: 8}).map(() =>  React.createRef()),
    []
  )

  const [formData, setFormData] = useState<any>({});
  const [ createConfig ] = useCreateGeneralConfigMutation();

  const save = () => {
    if(!form?.current?.validate()) {
      return dispatch(setAlert({type: "error", message: "Please fill all required fields."}));
    }
    modal?.current?.show(
      "Confirm Save",
      <ConfirmationDialog
      promptMessage="Are you sure want to add this Charge"
      onOkay={() => {
        dispatch(setAlert({type: "progress", message: "Processing..."}));
        modal?.current?.hide();
        let FD = {
          configs: {
            charges: `${user?.configuration?.charges ? user?.configuration?.charges + "," : ""}${formData.charge}`,
          }
        }
        createConfig({...FD})
        .unwrap()
        .then((response: any) => {
          dispatch(setAlert({type: "success", message: "Charge has successfully added", unreplaceable: true}));
          setTimeout(() => {
            props.modal?.current?.hide();
          }, 1500);
        })
        .catch((error: any) => {
          dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
        })
      }}
      onCancel={() => {
        modal?.current?.hide();
      }}
    />
    )
  }

  return (
    <>
      <div className="card scrollable y">
        <div className="card-body">
          <div>
            <Form ref={form}>
              <div className="row">
                <div className="col-12">
                  <SelectInput
                    ref={inputs[0]}
                    label="Charge"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    options={props.charges}
                    onChange={(value) => setFormData({
                      ...formData,
                      charge: value?.value
                    })}
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className="card-footer d-flex flex-row">
          <button
            className="btn bg-secondary"
            onClick={() => save()}
          >
            Add Charge
          </button>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default ChargeAdd;

import EmployeeDetails from './EmployeeDetails';
import EmployeeList from './EmployeeList';

const EmployeesRoutes = [
  {
    url: "/",
    component: EmployeeList,
    permission: "employees",
    props: {}
  },
  {
    url: "/details/:employeeID",
    component: EmployeeDetails,
    permission: "employees_view",
    props: {}
  },
]

export default EmployeesRoutes;
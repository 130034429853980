import { useState, useEffect } from "react";
import moment from "moment";

import { NavBar } from "../../menu";

import "./Footer.scss";
import { appName } from './../../../utils/constants/constants';

interface FooterProps {
  
}

const Footer = (props: FooterProps) => {

  return (
    <div className="footer d-flex flex-row align-center justify-center grey-text text-small px-2 bg-primary">
      {/* <span>{appName}&#169;{moment().format("YYYY")}</span> <span>Powered by Maipronet</span> */}
      <span>{appName} &#169; {moment().format("YYYY")}</span>
    </div>
  );
}

export default Footer;

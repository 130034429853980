import IncomeReport from './IncomeReport';
import PaymentList from './IncomeReport';
import SummaryReport from './SummaryReport';
import TripsReport from './TripsReport';
import ExpenseReport from './ExpenseReport';
import IncomeVsExpenseReport from './IncomeVsExpenseReport';
import UserActivitiesReport from './UserActivitiesReport';

const ReportsRoutes = [
  // {
  //   url: "/",
  //   redirect: "summary",
  //   props: {}
  // },
  {
    url: "/",
    component: SummaryReport,
    permission: "reports",
    props: {}
  },
  {
    url: "/summary",
    component: SummaryReport,
    permission: "reports",
    props: {}
  },
  {
    url: "/trips",
    component: TripsReport,
    permission: "reports_trips",
    props: {}
  },
  {
    url: "/income",
    component: IncomeReport,
    permission: "reports_income",
    props: {}
  },
  {
    url: "/income-vs-expense",
    component: IncomeVsExpenseReport,
    permission: "reports_income_vs_expense",
    props: {}
  },
  {
    url: "/user-activities",
    component: UserActivitiesReport,
    permission: "reports_user_activities",
    props: {}
  },
  {
    url: "/expenses/all",
    component: ExpenseReport,
    permission: "reports_expenses",
  },
  {
    url: "/expenses/allowance",
    component: ExpenseReport,
    permission: "reports_expenses",
    props: {expenseCategoryName: "Allowances", expenseCategoryID: 1}
  },
  {
    url: "/expenses/fuel",
    component: ExpenseReport,
    permission: "reports_expenses",
    props: {expenseCategoryName: "Fuel", expenseCategoryID: 2, totalColSpan: 4}
  },
  {
    url: "/expenses/oil",
    component: ExpenseReport,
    permission: "reports_expenses",
    props: {expenseCategoryName: "Oil", expenseCategoryID: 3, totalColSpan: 4}
  },
  {
    url: "/expenses/tyre",
    component: ExpenseReport,
    permission: "reports_expenses",
    props: {expenseCategoryName: "Tyre", expenseCategoryID: 4}
  },
  {
    url: "/expenses/repair",
    component: ExpenseReport,
    permission: "reports_expenses",
    props: {expenseCategoryName: "Repair", expenseCategoryID: 5, totalColSpan: 4}
  },
]

export default ReportsRoutes;
import { useEffect, useMemo, useRef, useState } from "react";
import { ConfirmationDialog, Modal } from "../../components/modal";
import { Table } from "../../components/table";
import VehicleCreate from "./VehicleCreate";

import {
  SelectInput,
} from "../../components/input";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useDeleteVehicleMutation, useGetVehiclesQuery } from "../../services/vehicles/VehicleService";
import { clearAlert, setAlert } from "../../states/AlertState";
import { setFilters } from "../../states/FiltersState";
import { getModulePermissions, getNoPermissionMessage, hasPermission } from "../../utils/helpers/helpers";
interface VehicleListProps {
}

const VehicleList = (props: VehicleListProps) => {
  const user = useSelector((state: any) => state.userState);
  const filters = useSelector((state: any) => state.filtersState);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const modal = useRef<any>();

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetVehiclesQuery(filters);
  const [ deleteVehicleRecord ] = useDeleteVehicleMutation();

  const [drivers, setDrivers] = useState<any[]>([]);

  const permission = useMemo(
    () => getModulePermissions("vehicles", user?.permissions),
    [user]
  );

  const createNewVehicle = (vehicle: any = undefined) => {
    modal.current?.show(
      `${vehicle ? "Edit Vehicle Info" : "New Vehicle"}`,
      (<VehicleCreate
        vehicleID={vehicle?.vehicle_id}
        modal={modal}
      />),
    );
  }

  const deleteVehicle = (vehicle: any) => {
    modal?.current?.show(
      "Delete Vehicle",
      <ConfirmationDialog
        promptMessage="Are you sure want to delete this vehicle"
        okayText="Delete"
        okayClassName="bg-danger"
        onOkay={() => {
          dispatch(setAlert({type: "progress", message: "Deleting..."}));
          modal?.current?.hide();
          deleteVehicleRecord(vehicle?.vehicle_id)
          .unwrap()
          .then((response: any) => {
            dispatch(setAlert({type: "success", message: "Vehicle successfully deleted"}));
          })
          .catch((error: any) => {
            dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
          })
        }}
        onCancel={() => {
          modal?.current?.hide();
        }}
      />
    )
  };

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    data?.drivers && setDrivers(data.drivers.map((driver: any) => ({label: `${driver.first_name} ${driver.last_name}` , value: driver.user_id})));
  }, [data])

  return (
    <>
      {/* {patientList?.data?.data?.map((vehicle: any) => (<div>Hey there</div>))} */}
      <div className="row justify-center shadow bg-white p-2 mb-1">
        <div className="col-12 col-md-4 col-sm-6">
          <SelectInput
            placeholder="Driver"
            block={true}
            clearable
            options={drivers}
            value={drivers.find(driver => driver.value === filters.driver)}
            onChange={(value) => dispatch(setFilters({
              driver: value?.value
            }))}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div>
            <Table
              loading={isLoading}
              title="Vehicles"
              onRowClick={(item) => {
                permission?.view ?
                navigate(`/vehicles/details/${item.vehicle_id}`) :
                dispatch(setAlert({type: "error", message: getNoPermissionMessage("view", "vehicle")}))
              }}
              columns={[
                {label: "Registration No", name: "registration_number"},
                {label: "Type", name: "vehicle_type_name"},
                {label: "Trailer", name: "trailer_registration_number"},
                {
                  label: "Driver",
                  name: "driver",
                  customRender: true,
                  valueGetter: (item) =>  `${item.first_name || ""} ${item.last_name || ""}`,
                },
                {label: "Contact", name: "phone_number"},
                {label: "Action", name: "action", customRender: true, webOnly: true},
              ]}
              items={data?.vehicles || []}
              permission={permission}
              onSearch={(search) => dispatch(setFilters({search: search}))}
              pagination={{
                ...(data?.pagination || {}),
                onPageChange: (page) => dispatch(setFilters({page: page})),
                onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
              }}
              params={filters}
              actions={[
                <button
                  className={`btn small with-icon bg-secondary ${!permission?.add && "disabled"}`}
                  onClick={() =>
                    permission?.add ?
                    createNewVehicle() :
                    dispatch(setAlert({type: "error", message: getNoPermissionMessage("add", "vehicle")}))
                  }
                  key={0}
                >
                  <span className="material-icons">add</span>
                  <span>Add</span>
                </button>
              ]}
              customRenders={[
                {
                  columnName: "index",
                  render: (item, index) => <span>{index + 1}</span>
                },
                {
                  columnName: "driver",
                  render: (item) => <span>{`${item.first_name || ""} ${item.last_name || ""}`}</span>
                },
                {
                  columnName: "action",
                  render: (item) => (
                    <div className="d-flex flex-row align-center">
                      <span
                        className={`material-icons cursor-pointer primary-text ${!permission?.view && "disabled"}`}
                        onClick={(e) => {
                          e.stopPropagation(); 
                          permission?.view ?
                          navigate(`/vehicles/details/${item.vehicle_id}`) :
                          dispatch(setAlert({type: "error", message: getNoPermissionMessage("view", "vehicle")}))
                        }}
                        title="View Details"
                      >
                        visibility
                      </span>
                      <span
                        className={`material-icons cursor-pointer ml-2 secondary-text ${!permission?.update && "disabled"}`}
                        onClick={(e) => {
                          e.stopPropagation(); 
                          permission?.update ?
                          createNewVehicle(item):
                          dispatch(setAlert({type: "error", message: getNoPermissionMessage("edit", "vehicle")}))
                        }}
                        title="Edit Info"
                      >
                        edit
                      </span>
                      <span
                        className={`material-icons cursor-pointer ml-2 danger-text ${!permission?.delete && "disabled"}`}
                        onClick={(e) => {
                          e.stopPropagation(); 
                          permission?.delete ?
                          deleteVehicle(item):
                          dispatch(setAlert({type: "error", message: getNoPermissionMessage("delete", "vehicle")}))
                        }}
                        title="Delete Record"
                      >
                        delete
                      </span>
                    </div>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default VehicleList;

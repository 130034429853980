import { useEffect, useMemo, useRef } from "react";
import { ConfirmationDialog, Modal } from "../../components/modal";
import { Table } from "../../components/table";
import RouteCreate from "./RouteCreate";

import { getModulePermissions, getNoPermissionMessage, numberFormat } from '../../utils/helpers/helpers';
import { useDispatch, useSelector } from "react-redux";
import { useDeleteRouteMutation, useGetRoutesQuery } from "../../services/routes/RouteService";
import { clearAlert, setAlert } from "../../states/AlertState";
import { setFilters as setFilter } from "../../states/FiltersState";
import { clearLocalFilters, setLocalFilters } from "../../states/FiltersLocalState";
interface RouteListProps {
  customer?: any,
  exportTitle?: string,
  useLocalFilters?: boolean,
  [rest: string]: any,
}

const RouteList = (props: RouteListProps) => {
  const user = useSelector((state: any) => state.userState);
  const setFilters: any = !props.useLocalFilters ? setFilter : setLocalFilters;
  const filters = useSelector((state: any) => !props.useLocalFilters ? state.filtersState : state.filtersLocalState);
  const dispatch = useDispatch();
  const modal = useRef<any>();

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetRoutesQuery({
    ...filters,
    customer: filters.customer || props.customer?.customer_id,
  });
  const [ deleteRouteRecord ] = useDeleteRouteMutation();

  const permission = useMemo(
    () => getModulePermissions("routes", user?.permissions),
    [user]
  )

  const createNewRoute = (route: any = undefined) => {
    modal.current?.show(
      `${route ? "Edit Route Info" : "New Route"}`,
      (<RouteCreate
        routeID={route?.route_id}
        modal={modal}
      />),
    );
  }

  const deleteRoute = (route: any) => {
    modal?.current?.show(
      "Delete Route",
      <ConfirmationDialog
        promptMessage="Are you sure want to delete this route"
        okayText="Delete"
        okayClassName="bg-danger"
        onOkay={() => {
          dispatch(setAlert({type: "progress", message: "Deleting..."}));
          modal?.current?.hide();
          deleteRouteRecord(route?.route_id)
          .unwrap()
          .then((response: any) => {
            dispatch(setAlert({type: "success", message: "Route successfully deleted"}));
          })
          .catch((error: any) => {
            dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
          })
        }}
        onCancel={() => {
          modal?.current?.hide();
        }}
      />
    )
  };

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    return () => {
      props.useLocalFilters && dispatch(clearLocalFilters());
    }
  }, [])

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div>
            <Table
              loading={isLoading}
              title="Routes"
              exportTitle={props.exportTitle}
              columns={[
                {label: "Start Location", name: "start_location"},
                {label: "End Location", name: "end_location"},
                {label: "Approx Total KM", name: "distance", customRender: true},
                {label: "Action", name: "action", customRender: true, webOnly: true},
              ]}
              items={data?.routes || []}
              permission={permission}
              onSearch={(search) => dispatch(setFilters({search: search}))}
              pagination={{
                ...(data?.pagination || {}),
                onPageChange: (page) => dispatch(setFilters({page: page})),
                onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
              }}
              params={filters}
              actions={[
                ...(!props.customer ? [<button
                  className={`btn small with-icon bg-secondary ${!permission?.add && "disabled"}`}
                  onClick={() =>
                    permission?.add ?
                    createNewRoute() :
                    dispatch(setAlert({type: "error", message: getNoPermissionMessage("add", "route")}))
                  }
                  key={0}
                >
                  <span className="material-icons">add</span>
                  <span>Add</span>
                </button>] : []),
              ]}
              customRenders={[
                {
                  columnName: "index",
                  render: (item, index) => <span>{index + 1}</span>
                },
                {
                  columnName: "distance",
                  render: (item) => <span>{item.distance && numberFormat(item.distance)}</span>
                },
                {
                  columnName: "action",
                  render: (item) => (
                    <div className="d-flex flex-row align-center">
                      <span
                        className={`material-icons cursor-pointer ml-2 secondary-text ${!permission?.update && "disabled"}`}
                        onClick={(e) => {
                          e.stopPropagation(); 
                          permission?.update ?
                          createNewRoute(item):
                          dispatch(setAlert({type: "error", message: getNoPermissionMessage("edit", "route")}))
                        }}
                        title="Edit Info"
                      >
                        edit
                      </span>
                      <span
                        className={`material-icons cursor-pointer ml-2 danger-text ${!permission?.delete && "disabled"}`}
                        onClick={(e) => {
                          e.stopPropagation(); 
                          permission?.delete ?
                          deleteRoute(item):
                          dispatch(setAlert({type: "error", message: getNoPermissionMessage("delete", "route")}))
                        }}
                        title="Delete Record"
                      >
                        delete
                      </span>
                    </div>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default RouteList;

import React, { useState, forwardRef, useImperativeHandle, ChangeEventHandler, useEffect, ReactNode, useRef } from "react";
import ReactDatePicker from "react-datepicker";
// import 'react-datepicker/dist/react-datepicker.css';
import "./react-datepicker.scss";

interface DateInputProps {
    disabled?: boolean,
    value?: Date | null,
    onChange?: (value: Date) => void,
    readOnly?: boolean,
    label?: string,
    placeholder?: string,
    dateFormat?: string,
    timeFormat?: string,
    showTimeSelect?: boolean,
    showTimeSelectOnly?: boolean,
    timeIntervals?: number,
    showYearPicker?: boolean,
    showMonthYearPicker?: boolean,
    maxTime?: any,
    minTime?: any,
    maxDate?: any,
    minDate?: any,
    filterTime?: any,
    filterDate?: any,
    requiredDecorator?: boolean,
    renderStartIcon?: () => ReactNode,
    renderEndIcon?: () => ReactNode,
    block?: boolean,
    direction?: string,
    clearable?: boolean,
    rules?: any[],
    className?: string,
    style?: object,
};

const DateInput = forwardRef((props: DateInputProps, ref) => {
  var input: any = useRef();

  const [isFocused, setIsFocused] = useState(false);
  const [val, setVal] = useState(props.value);
  const [error, setError] = useState<string>();

  const _getContainerClassName = () => {
    let className = "input-group";

    if (isFocused) {
      className += " is-focused";
    }

    if (props.block) {
      className += " block";
    }

    if (props.direction === "horizontal") {
      className += " horizontal";
    }

    if (error) {
      className += " has-error";
    }

    if (props.className) {
      className += ` ${props.className}`;
    }

    return className;
  }

  const validate = (value: any) => {
    let rules = props.rules || [],
      rulesLength = rules.length,
      i = 0;
    for (; i < rulesLength; i++) {
      let validate = rules[i](value !== undefined ? value : val);
      if (validate !== true) {
        setError(validate);
        return false;
      } else {
        setError(undefined);
      }
    }

    return true;
  }

  const clearValidation= (value: any) => {
    setError(undefined);
  }

  const _onChange = (value: any, toValidate = true) => {
    if (props.onChange) {
      props.onChange(value);
    };

    setVal(value);
    toValidate && validate(value);
  }

  const getInput = () => {
    return input;
  }

  const setValue = (value: any, validate = false) => {
    setVal(value)
    // input.setState({ value })
    _onChange(value, validate);
  }
  
  useEffect(() => {
    setVal(props.value);
  }, [props.value])

  useImperativeHandle(ref, () => {
    return {
      setValue: setValue,
      validate: validate,
      clearValidation: clearValidation,
      getInput: getInput,
    };
  });

  return (
    <div
      className={"d-inline-flex flex-column " + _getContainerClassName()}
    >
      {props.label ? (
        <div className="label px-1">
          {props.label}
          {props.requiredDecorator ? (
            <span className="red-text"> *</span>
          ) : null}
        </div>
      ) : null}
      <div className="flex-grow-1">
        <div className="d-flex flex-row align-center input">
          {props.renderStartIcon ? props.renderStartIcon() : null}
          <ReactDatePicker
            ref={(ref) => (input = ref)}
            className="d-block input-field"
            isClearable={props.clearable}
            disabled={props.disabled}
            placeholderText={props.placeholder}
            dateFormat={props.dateFormat || "yyyy-MM-dd"}
            timeFormat={props.timeFormat || "HH:mm"}
            showTimeSelect={props.showTimeSelect}
            showTimeSelectOnly={props.showTimeSelectOnly}
            timeIntervals={props.timeIntervals || 5}
            selected={val}
            showMonthDropdown
            showYearDropdown
            showYearPicker={props.showYearPicker}
            showMonthYearPicker={props.showMonthYearPicker}
            maxTime={props.maxTime}
            minTime={props.minTime}
            maxDate={props.maxDate}
            minDate={props.minDate}
            filterTime={props.filterTime}
            filterDate={props.filterDate}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={(value: any) => _onChange(value)}
          />
          {props.renderEndIcon ? props.renderEndIcon() : null}
        </div>
        {error ? (
          <div className="error px-1 red-text text-small">
            {error}
          </div>
        ) : null}
      </div>
    </div>
  );
});

export default DateInput;
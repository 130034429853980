import RouteList from './RouteList';

const RoutesRoutes = [
  {
    url: "/",
    component: RouteList,
    permission: "routes",
    props: {}
  },
]

export default RoutesRoutes;
import { AppService } from '../AppService';

export const TripService = AppService.injectEndpoints({
  endpoints: (builder) => ({
    getTrips: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/trips",
            method: "GET",
            params: params,
            // headers: {
            //     Authorization: `Bearer ${getTokenFromLocalStorage()}`,
            // },
        }),
        // keepUnusedDataFor: 5,
        providesTags: ['App', 'Trips', 'GetTrips'],
    }),
    getTrip: builder.query({
        query: (id) => ({
            url: `/trips/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Trips', 'GetTrip'],
    }),
    getTripToUpdate: builder.query({
        query: (id) => ({
            url: `/trips/edit/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Trips', 'GetTripToUpdate'],
    }),
    createTrip: builder.mutation({
        query: (data = {}) => ({
            url: `/trips/save${data.id ? ("/" + data.id) : ""}`,
            method: "POST",
            data: data,
        }),
       invalidatesTags: ['Trips', 'GetVehicle', 'GetEmployee', 'GetCustomer', 'GetCargo'],
    }),
    // updateTrip: builder.mutation({
    //     query: (id, data = {}) => ({
    //         url: `/trips/save/${id}`,
    //         method: "POST",
    //         data: data,
    //    }),
    //    invalidatesTags: ['Trips'],
    // }),
    deleteTrip: builder.mutation({
        query: (id) => ({
            url: `/trips/${id}`,
            method: "DELETE",
        }),
       invalidatesTags: ['GetTrips', 'GetVehicle', 'GetEmployee', 'GetCustomer', 'GetCargo'],
    }),
    importTrip: builder.mutation({
        query: (data = {}) => ({
            url: "/trips/import",
            method: "POST",
            data: data,
            // headers: {
            //     "Content-Type": `multipart/form-data`,
            // },
        }),
       invalidatesTags: ['Trips', 'GetVehicle', 'GetEmployee', 'GetCustomer', 'GetCargo'],
    }),
    downloadTripImportSample: builder.mutation({
        query: (id) => ({
            url: "/trips/import-sample",
            method: "GET",
        }),
    }),
  }),
});

export const {
    useGetTripsQuery,
    useGetTripQuery,
    useGetTripToUpdateQuery,
    useCreateTripMutation,
    // useUpdateTripMutation,
    useDeleteTripMutation,
    useImportTripMutation,
    useDownloadTripImportSampleMutation
} = TripService;

import { ReactNode } from "react"
import Tabs from "../tabs/Tabs"

interface ItemDetailsSummaryProps {
    title: string,
    summary: {
        value: string, 
        className?: string
    }[],
    details: {
        label: string,
        value: any
    }[]
}

const ItemDetailsSummary = (props: ItemDetailsSummaryProps) => {
    const deviceWidth = window.innerWidth;
    return (
        <div className={`${deviceWidth > 960 && "card shadow mb-5"}`}>
            <div className={`${deviceWidth > 960 && "card-body"}`}>
                <div className="d-flex flex-column align-center text-center pb-3 text-6">
                <div className="text-bold text-medium">{props?.title}</div>
                {props?.summary?.map((summary, index) => (
                    <div key={index} className={`mt-4 ${summary.className}`}>{summary.value}</div>
                ))}
                </div>
                <div className="d-flex flex-column border-top">
                {props?.details?.map((detail, index) => (
                    <div key={index} className="row py-3 justify-space-between border-bottom"><span className="text-bold">{detail.label}</span><span>{detail.value}</span></div>
                ))}
                </div>
            </div>
        </div>
    )
}

export default ItemDetailsSummary;
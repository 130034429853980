import ExpenseList from './ExpenseList';

const ExpensesRoutes = [
  {
    url: "/",
    component: ExpenseList,
    permission: "expenses",
    props: {}
  },
]

export default ExpensesRoutes;
import { useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "../../components/modal";
import { useGetVehicleQuery } from "../../services/vehicles/VehicleService";
import ExpenseList from "../expenses/ExpenseList";
import { useParams } from "react-router-dom";
import { ItemDetails } from "../../components/layout/item-details";
import { getModulePermissions, getNoPermissionMessage, hasPermission, numberFormat } from './../../utils/helpers/helpers';
import { useDispatch, useSelector } from "react-redux";
import { clearAlert, setAlert } from "../../states/AlertState";
import VehicleCreate from "./VehicleCreate";
import TripList from "../trips/TripList";
import { Table } from "../../components/table";

interface VehicleDetailsProps {
  vehicle?: any,
}

const VehicleDetails = (props: VehicleDetailsProps) => {
  const user = useSelector((state: any) => state.userState);
  const dispatch = useDispatch();
  const { vehicleID } = useParams();

  const modal = useRef<any>();

  const [basicInfo, setBasicInfo] = useState<any>([]);
  const [summary, setSummary] = useState<any>([]);
  const [fuelStatus, setFuelStatus] = useState<any>([]);
  const [oilStatus, setOilStatus] = useState<any>([]);

  const { data, isLoading, isError, error, isSuccess } = useGetVehicleQuery(vehicleID);

  const permission = useMemo(
    () => getModulePermissions("vehicles", user?.permissions),
    [user]
  )

  const onNoAccess = (action?: string, item?: string) => {
		dispatch(setAlert({type: "error", message: getNoPermissionMessage(action, item)}));
	}

  const editVehicle = (vehicle: any) => {
    modal.current?.show(
      "Edit Vehicle Info",
      (<VehicleCreate
        vehicleID={vehicle.vehicle_id}
        modal={modal}
      />),
    );
  }

  useEffect(() => {
    let vehicle = data?.vehicle;
    let current_fuel_status = data?.current_fuel_status;
    let current_oil_status = data?.current_oil_status;

    setSummary({
      title: vehicle?.registration_number,
      summary: [
        {value: vehicle?.vehicle_type_name},
        {value: vehicle?.driver_name},
        {value: vehicle?.phone_number},
      ],
      details: [
        {label: "Trips", value: numberFormat(vehicle?.total_trips || 0)},
        {label: "Mileage (Km)", value: numberFormat(vehicle?.total_distance || 0)},
        {label: "Litres", value: numberFormat(vehicle?.total_litres || 0)},
        {label: "Income", value: numberFormat(vehicle?.total_income || 0)},
        {label: "Expenses", value: numberFormat(vehicle?.total_expense || 0)},
      ]
    });

    setBasicInfo([
      {
        label: "Type",
        value: vehicle?.vehicle_type_name,
      },
      {
        label: "Registration No",
        value: vehicle?.registration_number,
      },
      {
        label: "Manufacture",
        value: vehicle?.manufacture || '---',
      },
      {
        label: "Model",
        value: vehicle?.model || '---',
      },
      {
        label: "Body Type",
        value: vehicle?.body_type || '---',
      },
      {
        label: "Color",
        value: vehicle?.color || '---',
      },
      {
        label: "Class",
        value: vehicle?.class || '---',
      },
      {
        label: "Year Of Manufacture",
        value: vehicle?.year_of_manufacture || '---',
      },
      {
        label: "Chassis No",
        value: vehicle?.chassis_number || '---',
      },
      {
        label: "Engine No",
        value: vehicle?.engine_number || '---',
      },
      {
        label: "Engine Capacity",
        value: vehicle?.engine_capacity || '---',
      },
      {
        label: "Fuel Used",
        value: vehicle?.fuel_used || '---',
      },
      {
        label: "Number Of Axles",
        value: vehicle?.number_of_axles || '---',
      },
      {
        label: "Axle Distance",
        value: vehicle?.axle_distance || '---',
      },
      {
        label: "Seating Capacity",
        value: vehicle?.seating_capacity || '---',
      },
      {
        label: "Tare Weight",
        value: vehicle?.tare_weight || '---',
      },
      {
        label: "Gross Weight",
        value: vehicle?.gross_weight || '---',
      },
      {
        label: "Imported From",
        value: vehicle?.imported_from || '---',
      },
      {
        label: "Km per litre",
        value: numberFormat(vehicle?.km_per_litre, 1),
      },
      {
        label: "Km per Oil",
        value: numberFormat(vehicle?.km_per_oil, 1) || '---',
      },
      {
        label: "Trailer",
        value: vehicle?.trailer_registration_number || '---',
      },
      {
        label: "Insurance Cover Note",
        value: vehicle?.insurance_cover_note || '---',
      },
      {
        label: "Insurance Issue Date ",
        value: vehicle?.insurance_issue_date || '---',
      },
      {
        label: "Insurance Expire Date ",
        value: vehicle?.insurance_expire_date || '---',
      },
      {
        label: "LATRA Licence No",
        value: vehicle?.latra_licence_number || '---',
      },
      {
        label: "LATRA Issue Date ",
        value: vehicle?.latra_licence_issue_date || '---',
      },
      {
        label: "LATRA Expire Date ",
        value: vehicle?.latra_licence_expire_date || '---',
      },
      {
        label: "Trailer Insurance Cover Note",
        value: vehicle?.trailer_insurance_cover_note || '---',
      },
      {
        label: "Trailer Insurance Issue Date ",
        value: vehicle?.trailer_insurance_issue_date || '---',
      },
      {
        label: "Trailer Insurance Expire Date ",
        value: vehicle?.trailer_insurance_expire_date || '---',
      },
      {
        label: "Trailer LATRA Licence No",
        value: vehicle?.trailer_latra_licence_number || '---',
      },
      {
        label: "Trailer LATRA Issue Date ",
        value: vehicle?.trailer_latra_licence_issue_date || '---',
      },
      {
        label: "Trailer LATRA Expire Date ",
        value: vehicle?.trailer_latra_licence_expire_date || '---',
      },
      {
        label: "Driver",
        value: vehicle?.driver_name ? (`${vehicle?.driver_name} / ${vehicle?.phone_number}`) : '---',
      },
      {
        label: "Created Date",
        value: vehicle?.created_at,
      },
      {
        label: "Modified Date",
        value: vehicle?.modified_at,
      },
    ]);

    setFuelStatus([
      {
        label: "Fuel Status",
        value: current_fuel_status?.status_message,
        className: `badge text-xsmal bg-${current_fuel_status?.status_message_class}`
      },
      {
        label: "Last filled",
        value: current_fuel_status?.last_filled,
      },
      {
        label: "Filled litres",
        value: numberFormat(current_fuel_status?.filled_litres),
      },
      {
        label: "Mileage after last fill (Km)",
        value: numberFormat(current_fuel_status?.km_after_last_filled),
      },
      {
        label: "Remained litres",
        value: numberFormat(current_fuel_status?.remained_litres),
        className: `badge text-xsmal bg-${current_fuel_status?.status_message_class}`
      },
      {
        label: "Remained mileage (Km)",
        value: numberFormat(current_fuel_status?.remained_km),
      },
    ]);

    setOilStatus([
      {
        label: "Oil Status",
        value: current_oil_status?.status_message,
        className: `badge text-xsmal bg-${current_oil_status?.status_message_class}`
      },
      {
        label: "Last changed",
        value: current_oil_status?.last_changed,
      },
      {
        label: "Mileage after last oil change (Km)",
        value: numberFormat(current_oil_status?.km_after_last_changed),
      },
      {
        label: "Mileage remained before next oil change (Km)",
        value: numberFormat(current_oil_status?.remained_km),
        className: `badge text-xsmal bg-${current_oil_status?.status_message_class}`
      },
    ]);
  }, [data]);

  useEffect(() => {
    isError &&  dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError]);

  useEffect(() => {
    isLoading && dispatch(setAlert({type: "progress"}));
  }, [isLoading])

  useEffect(() => {
    isSuccess && dispatch(clearAlert());
  }, [isSuccess]);

  return (
    <>
      <div className="card shadow-sm">
        <div className="card-header">Vehicle Details</div>
        <div className="card-body">
          <ItemDetails
            itemName="Vehicle"
            permission={permission}
            details={basicInfo}
            detailsEditAction={
              <button
                className={`btn bg-secondary mt-3 ${!permission?.update && "disabled"}`}
                onClick={() =>
                  permission?.update ?
                  editVehicle(data?.vehicle) :
                  onNoAccess("edit", "vehicle")
                }
              >
                Edit Vehicle
              </button>
            }
            summary={summary}
            tabs={[
              <TripList
                key={2}
                title="Trips"
                className={!hasPermission("trips", user.permissions) && "disabled"}
                onClick={!hasPermission("trips", user.permissions) ? () => onNoAccess("access", "trips") : undefined}
                exportTitle={`Vehicle(${data?.vehicle?.registration_number}) Trips`}
                vehicle={data?.vehicle}
                useLocalFilters
              />,
              <ExpenseList
                key={3}
                title="Expenses"
                className={!hasPermission("expenses", user.permissions) && "disabled"}
                onClick={!hasPermission("expenses", user.permissions) ? () => onNoAccess("access", "expenses") : undefined}
                exportTitle={`Vehicle(${data?.vehicle?.registration_number}) Expenses`}
                vehicle={data?.vehicle}
                useLocalFilters
              />,
              <Table
                key={4}
                title="Fuel Status"
                noHeaderRow
                noExport
                noSearch
                noTitle
                tableCellClassName="w-50"
                columns={[
                  {name: "label"},
                  {name: "value", customRender: true},
                ]}
                items={fuelStatus}
                customRenders={[
                  {
                    columnName: "value",
                    render: (item: any) => <span className={item.className}>{item.value}</span>
                  },
                ]}
              />,
              <Table
                key={5}
                title="Oil Status"
                noHeaderRow
                noExport
                noSearch
                noTitle
                tableCellClassName="w-50"
                columns={[
                  {name: "label"},
                  {name: "value", customRender: true},
                ]}
                items={oilStatus}
                customRenders={[
                  {
                    columnName: "value",
                    render: (item: any) => <span className={item.className}>{item.value}</span>
                  },
                ]}
              />
            ]}
          />
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default VehicleDetails;

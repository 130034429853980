import React, { useEffect, useMemo, useRef, useState } from "react";
import { Form } from "../../../components/form";

import {
  Checkbox,
  TextField,
} from "../../../components/input";

import { getValidationRules } from "../../../utils/helpers/helpers";

const PasswordRecover = (props: any) => {
  const inputValidation = getValidationRules();
  
  const form = useRef<any>();
  const inputs: any = useMemo(
    () => Array.from({length: 2}).map(() =>  React.createRef()),
    []
  )

  const [ showPassword, setShowPassword ] = useState(false);
  const { formData, setFormData } = props;

  useEffect(() => {
    props.setForm(form);
  }, [])
    
  return (
    <div>
      <Form ref={form}>
        <div className="row">
          <div className="col-12">
            <TextField
              ref={inputs[0]}
              label="New password"
              block={true}
              type={showPassword ? "text" : "password"}
              rules={[inputValidation.required, inputValidation.password]}
              onChange={(value) => setFormData({
                ...formData,
                password: value
              })}
              renderStartIcon={() => <span className="material-icons grey-text">lock</span>}
              renderEndIcon={() => (
                <span
                  className="material-icons grey-text cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <>visibility_off</> : <>visibility</>}
                </span>
              )}
            />
          </div>
          <div className="col-12">
            <TextField
              ref={inputs[1]}
              label="Confirm Password"
              block={true}
              type={showPassword ? "text" : "password"}
              rules={[
                inputValidation.required,
                (value: any) => value === formData.password || "Passwords do not match"
              ]}
              onChange={(value) => setFormData({
                ...formData,
                confirm_password: value
              })}
              renderStartIcon={() => <span className="material-icons grey-text">lock</span>}
              renderEndIcon={() => (
                <span
                  className="material-icons grey-text cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <>visibility_off</> : <>visibility</>}
                </span>
              )}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};
      
export default PasswordRecover;
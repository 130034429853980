import { useState, useEffect, useRef } from "react";
import moment from "moment";

import { NavBar } from "../../menu";

import "./Header.scss";
import { appName } from "../../../utils/constants/constants";
import logo from "../../../assets/images/logo.jpeg";
import { useLocation, useNavigate } from "react-router-dom";
import { useLogoutMutation } from "../../../services/users/UserService";
import { Alert } from "../../alert";
import { useDispatch, useSelector } from "react-redux";
import { setChangePassword } from "../../../states/GeneralState";
import { capitalize, getNoPermissionMessage } from "../../../utils/helpers/helpers";
import { setAlert } from "../../../states/AlertState";
interface HeaderProps {
  onLogout: () => void,
}

const Header = (props: HeaderProps) => {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.userState);
  const location = useLocation();
  const navigate = useNavigate();
  const alert = useRef<any>();

  const [currentDateTime, setCurrentDateTime] = useState(new Date())
  const [userExpanded, setUserExpanded] = useState(false)
  const [menuItems, setMenuItems] = useState<any[]>([])
  const [ logout ] = useLogoutMutation();
  const userMenuItems = [
    {
      title: `${user?.first_name || ""} ${user?.last_name || ""} | ${user?.role || ""}`,
      hoverBackground: false,
      subMenu: [
        {
          title: 'Change Password',
          // url: '/home/change-password',
          startIcon: "lock_reset",
          onClick: () => dispatch(setChangePassword(true))
        },
        {
          title: 'Logout',
          // url: '/login',
          startIcon: "logout",
          onClick: () => {
            props.onLogout();
          },
        }
      ],
    },
  ];
  
  const setMenu = () => {
    const menuItems = user?.modules
    ?.map((module: any, i: number) => {
      let authorized = user.permissions?.find((permission: any) => permission.permission === module.module);
      return {
        title: capitalize(module.module?.replace(/_/g, " ")),
        url: `/${module.module?.replace(/_/g, "-")}`,
        // startIcon: module.icon,
        // hoverBackground: false,
        onClick: !authorized ? () => onNoAccess(module) : undefined,
        disabled: !authorized,
      }
    }) || [];
  
    const onNoAccess = (module: any) => {
      dispatch(setAlert({type: "error", message: getNoPermissionMessage("access", module.module)}));
    }
  
    setMenuItems([
      {
        title: 'Home',
        url: '/home',
        // startIcon: 'home',
        // hoverBackground: false,
      },
      ...(window.innerWidth < 960 ? [
      {
        title: '',
        startIcon: 'menu',
        noDropDownIcon: true,
        // hoverBackground: false,
        subMenu: menuItems,
        dropdownClassName: "bg-navbar",
  
  
      }] : menuItems),
    ]);
  }
  useEffect(() => {
    if(user?.modules){
      setMenu();
    }
  }, [user])

  useEffect(() => {
    setInterval(()=> {
      setCurrentDateTime(new Date());
    }, 1000)
  }, [])

  return (
    <div className="app-header d-flex flex-column">
      <Alert ref={alert} />
      <div className="app-bar text-small d-flex flex-row align-center justify-center bg-primary">
        <div
          className={
            "relative mr-1 cursor-pointer"
          }
          // onClick={systemUpdateModal}
        >
          <img
            src={logo}
            // className="mr-1"
            width={32}
            height="auto"
          />
        </div>

        <div className="text-bold text-small cursor-pointer ml-2">{appName}</div>
        <div className="ml-4">{moment(currentDateTime).format("YYYY-MM-DD HH:mm:ss")}</div>
        <div className="flex-grow-1 d-flex flex-row justify-center text-small">{user?.company?.company_name}</div>
        {Object.keys(user)?.length > 0 && <NavBar menuItems={userMenuItems} />}
      </div>
      <div className="nav-bar row justify-end align-center text-small bg-navbar">
        {
          location.pathname.indexOf("home") !== 1 &&
          <NavBar menuItems={menuItems} />
        }
      </div>
    </div>
  );
}

export default Header;

import { useEffect } from "react";
import { Router } from "../../components/router"
import { menuType } from "../../utils/types";
import ReportsRoutes from "./ReportsRoutes";
import { useDispatch, useSelector } from "react-redux";
import { clearFilters } from "../../states/FiltersState";
import ReportsMenu from "./ReportsMenu";
import { AuthenticateMenu, AuthenticateRoutes, hasPermission } from "../../utils/helpers/helpers";
import { AccessDenied } from "../../components/permission";

interface ReportsProps {
  setMenu: (menu: menuType) => void,
  setShowSideMenu: (show: boolean) => void,
  onNoAccess: (action?: string, item?: string, prefixModule?: string, sufix?: string) => void,
}

const Reports = (props: ReportsProps) => {
  const user = useSelector((state: any) => state.userState);
  const dispatch = useDispatch();

  useEffect(() => {
    props.setShowSideMenu(true);
    props.setMenu(
      AuthenticateMenu(ReportsMenu, user.permissions, (permission: string) => props.onNoAccess("access", permission, "reports", "report"))
    );
    return () => {
      dispatch(clearFilters())
    }
  }, [])

  return (
    <Router
      routes={AuthenticateRoutes(ReportsRoutes, user.permissions, AccessDenied)}
    />
  );
}

export default Reports;

import { createSlice } from '@reduxjs/toolkit';
const stateInitials = {
  message: "",
  type: "",
  unreplaceable: false,
}
export const AlertState = createSlice({
  name: 'alertState',
  initialState: stateInitials,
  reducers: {
    setAlert: (state, action) => {
      return {message: action.payload.message, type: action.payload.type, unreplaceable: action.payload.unreplaceable};
    },
    clearAlert: (state: any) => {
      return stateInitials;
    }
  },
});

// this is for dispatch
export const { setAlert, clearAlert } = AlertState.actions;

// this is for configureStore
export default AlertState.reducer;

import React, { useState, forwardRef, useImperativeHandle, useEffect, ReactNode, ChangeEvent, useRef } from "react";

interface FileInputProps {
    disabled?: boolean,
    value?: any,
    defaultValue?: any,
    onChange?: (files?: any[], input?: object) => void,
    label?: string,
    placeholder?: string,
    accept?: string,
    requiredDecorator?: boolean,
    renderStartIcon?: () => ReactNode,
    renderEndIcon?: () => ReactNode,
    block?: boolean,
    direction?: string,
    rules?: any[],
    className?: string,
};

const FileInput = forwardRef((props: FileInputProps, ref) => {
  var input: any = useRef();

  const [isFocused, setIsFocused] = useState(false);
  const [val, setVal] = useState(props.value);
  const [error, setError] = useState<string>();

  const _getContainerClassName = () => {
    let className = "input-group";

    if (isFocused) {
      className += " is-focused";
    }

    if (props.block) {
      className += " block";
    }

    if (props.direction === "horizontal") {
      className += " horizontal";
    }

    if (error) {
      className += " has-error";
    }

    if (props.className) {
      className += ` ${props.className}`;
    }

    return className;
  }

  const validate = (value: any) => {
    let rules = props.rules || [],
      rulesLength = rules.length,
      i = 0;
    for (; i < rulesLength; i++) {
      let validate = rules[i](value !== undefined ? value : val);
      if (validate !== true) {
        setError(validate);
        return false;
      } else {
        setError(undefined);
      }
    }

    return true;
  }

  const clearValidation= (value: any) => {
    setError(undefined);
  }

  const _onChange = (input: any, toValidate = true) => {
    if (props.onChange) {
      props.onChange(input.files, input);
    };

    setVal(input.files);
    toValidate && validate(input.files);
  }

  const getInput = () => {
    return input;
  }

  useEffect(() => {
    setVal(props.defaultValue);
  }, [props.defaultValue])

  useImperativeHandle(ref, () => {
    return {
      validate: validate,
      clearValidation: clearValidation,
      getInput: getInput,
    };
  });

  return (
    <div className={_getContainerClassName()}>
      {props.label ?
        <div className="label px-1">{props.label}{props.requiredDecorator ?
          <span className="red-text"> *</span> : null}</div>
        : null
      }
      <div className="flex-grow-1">
        <div className="d-flex flex-row align-center input">
          {props.renderStartIcon ? props.renderStartIcon() : null}
          <label className="input-file flex-grow-1 input-field">
            <input
              ref={(ref) => (input = ref)}
              accept={props.accept}
              disabled={props.disabled}
              type="file"
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onChange={(event: ChangeEvent) => _onChange(event.target)}
            />
            {val?.length ?
              <>{val[0].name || null}</>
              :
              <>{props.placeholder || "Browse files..."}</>
            }
          </label>
          {props.renderEndIcon && props.renderEndIcon()}
        </div>
        {error && (
          <div className="error px-1 red-text text-small">
            {error}
          </div>
        )}
      </div>
    </div>
  );
});

export default FileInput;
import CargoDetails from './CargoDetails';
import CargoList from './CargoList';

const CargosRoutes = [
  {
    url: "/",
    component: CargoList,
    permission: "cargos",
    props: {}
  },
  {
    url: "/details/:cargoID",
    component: CargoDetails,
    permission: "cargos_view",
    props: {}
  },
]

export default CargosRoutes;
import { useEffect, useMemo, useRef, useState } from "react";
import { ConfirmationDialog, Modal } from "../../components/modal";
import { Table } from "../../components/table";
import EmployeeCreate from "./EmployeeCreate";

import {
  SelectInput,
} from "../../components/input";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useDeleteEmployeeMutation, useGetEmployeesQuery } from "../../services/employees/EmployeeService";
import { clearAlert, setAlert } from "../../states/AlertState";
import { setFilters } from "../../states/FiltersState";
import { getModulePermissions, getNoPermissionMessage } from "../../utils/helpers/helpers";
interface EmployeeListProps {
}

const EmployeeList = (props: EmployeeListProps) => {
  const user = useSelector((state: any) => state.userState);
  const filters = useSelector((state: any) => state.filtersState);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const modal = useRef<any>();

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetEmployeesQuery(filters);
  const [ deleteEmployeeRecord ] = useDeleteEmployeeMutation();

  const [roles, setRoles] = useState<any[]>([]);
  const [employmentStatuses, setEmploymentStatuses] = useState<any[]>([]);

  const permission = useMemo(
    () => getModulePermissions("employees", user?.permissions),
    [user]
  );

  const createNewEmployee = (employee: any = undefined) => {
    modal.current?.show(
      `${employee ? "Edit Employee Info" : "New Employee"}`,
      (<EmployeeCreate
        employeeID={employee?.user_id}
        modal={modal}
      />),
    );
  }

  const deleteEmployee = (employee: any) => {
    modal?.current?.show(
      "Delete Employee",
      <ConfirmationDialog
        promptMessage="Are you sure want to delete this employee"
        okayText="Delete"
        okayClassName="bg-danger"
        onOkay={() => {
          dispatch(setAlert({type: "progress", message: "Deleting..."}));
          modal?.current?.hide();
          deleteEmployeeRecord(employee?.user_id)
          .unwrap()
          .then((response: any) => {
            dispatch(setAlert({type: "success", message: "Employee successfully deleted"}));
          })
          .catch((error: any) => {
            dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
          })
        }}
        onCancel={() => {
          modal?.current?.hide();
        }}
      />
    )
  };

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    data?.roles && setRoles(data.roles.map((role: any) => ({label: role.role_name, value: role.role_id})));
    data?.employment_status && setEmploymentStatuses(data.employment_status.map((employment_status: any) => ({label: employment_status.employment_status_name, value: employment_status.employment_status_id})));
  }, [data])

  return (
    <>
      {/* {patientList?.data?.data?.map((employee: any) => (<div>Hey there</div>))} */}
      <div className="row justify-center shadow bg-white p-2 mb-1">
        <div className="col-12 col-md-4 col-sm-6">
          <SelectInput
            placeholder="Role"
            block={true}
            clearable
            options={roles}
            value={roles.find(role => role.value === filters.role)}
            onChange={(value) => dispatch(setFilters({
              role: value?.value
            }))}
          />
        </div>
        <div className="col-12 col-md-4 col-sm-6">
          <SelectInput
            placeholder="Employment Status"
            block={true}
            clearable
            options={employmentStatuses}
            value={employmentStatuses.find(employment_status => employment_status.value === filters.employment_status)}
            onChange={(value) => dispatch(setFilters({
              employment_status: value?.value
            }))}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div>
            <Table
              loading={isLoading}
              title="Employees"
              onRowClick={(item) => {
                permission?.view ?
                navigate(`/employees/details/${item.user_id}`) :
                dispatch(setAlert({type: "error", message: getNoPermissionMessage("view", "employee")}))
              }}
              columns={[
                {
                  label: "Name",
                  name: "name",
                  customRender: true,
                  valueGetter: (item) =>  `${item.first_name || ""} ${item.last_name || ""}`,
                },
                {label: "Email", name: "email"},
                {label: "Phone No", name: "phone_number"},
                {label: "Role", name: "role_name"},
                {label: "Employment", name: "employment_status_name"},
                {label: "Joining Date", name: "joining_date"},
                {label: "Action", name: "action", customRender: true, webOnly: true},
              ]}
              items={data?.employees || []}
              permission={permission}
              onSearch={(search) => dispatch(setFilters({search: search}))}
              pagination={{
                ...(data?.pagination || {}),
                onPageChange: (page) => dispatch(setFilters({page: page})),
                onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
              }}
              params={filters}
              actions={[
                <button
                  className={`btn small with-icon bg-secondary ${!permission?.add && "disabled"}`}
                  onClick={() =>
                    permission?.add ?
                    createNewEmployee() :
                    dispatch(setAlert({type: "error", message: getNoPermissionMessage("add", "employee")}))
                  }
                  key={0}
                >
                  <span className="material-icons">add</span>
                  <span>Add</span>
                </button>
              ]}
              customRenders={[
                {
                  columnName: "index",
                  render: (item, index) => <span>{index + 1}</span>
                },
                {
                  columnName: "name",
                  render: (item) => <span>{`${item.first_name || ""} ${item.last_name || ""}`}</span>
                },
                {
                  columnName: "action",
                  render: (item) => (
                    <div className="d-flex flex-row align-center">
                      <span
                        className={`material-icons cursor-pointer primary-text ${!permission?.view && "disabled"}`}
                        onClick={(e) => {
                          e.stopPropagation(); 
                          permission?.view ?
                          navigate(`/employees/details/${item.user_id}`) :
                          dispatch(setAlert({type: "error", message: getNoPermissionMessage("view", "employee")}))
                        }}
                        title="View Details"
                      >
                        visibility
                      </span>
                      <span
                        className={`material-icons cursor-pointer ml-2 secondary-text ${!permission?.update && "disabled"}`}
                        onClick={(e) => {
                          e.stopPropagation(); 
                          permission?.update ?
                          createNewEmployee(item):
                          dispatch(setAlert({type: "error", message: getNoPermissionMessage("edit", "employee")}))
                        }}
                        title="Edit Info"
                      >
                        edit
                      </span>
                      <span
                        className={`material-icons cursor-pointer ml-2 danger-text ${!permission?.delete && "disabled"}`}
                        onClick={(e) => {
                          e.stopPropagation(); 
                          permission?.delete ?
                          deleteEmployee(item):
                          dispatch(setAlert({type: "error", message: getNoPermissionMessage("delete", "employee")}))
                        }}
                        title="Delete Record"
                      >
                        delete
                      </span>
                    </div>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default EmployeeList;

import { TransactionList } from './transactions';
import UnprocessedPaymentTripList from './UnprocessedPaymentTripList';

const AccountingRoutes = [
  {
    url: "/uprocessed-payment-trips",
    component: UnprocessedPaymentTripList,
    permission: "accounting_unprocessed_payment_trips",
    props: {}
  },
  {
    url: "/transactions",
    component: TransactionList,
    permission: "accounting_transactions",
    props: {}
  },
]

export default AccountingRoutes;
const AccountingMenu = {
    menuTitle: "Accounting",
    items: [
      {
        title: 'Unprocessed Payment Trips',
        startIcon: "commute",
        url: "/accounting/uprocessed-payment-trips",
        permission: "accounting_unprocessed_payment_trips",
      },
      {
        title: 'Transactions',
        startIcon: "commute",
        url: "/accounting/transactions",
        permission: "accounting_transactions",
      },
      // {
      //   title: 'Expenses',
      //   startIcon: "paid",
      //   url: "/accounting/expenses",
      //   subMenu: [
      //     {
      //       title: 'All Expenses Report',
      //       startIcon: "paid",
      //       url: "/accounting/expenses/all",
      //       permission: "accounting_expenses",
      //     },
      //     {
      //       title: 'Allowance Report',
      //       startIcon: "paid",
      //       url: "/accounting/expenses/allowance",
      //       permission: "accounting_expenses",
      //     },
      //   ]
      // },
    ]
}

export default AccountingMenu;
import { AppService } from '../AppService';

export const VehicleService = AppService.injectEndpoints({
  endpoints: (builder) => ({
    getVehicles: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/vehicles",
            method: "GET",
            params: params,
        }),
        providesTags: ['App', 'Vehicles', 'GetVehicles'],
    }),
    getVehicle: builder.query({
        query: (id) => ({
            url: `/vehicles/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Vehicles', 'GetVehicle'],
    }),
    getVehicleToUpdate: builder.query({
        query: (id) => ({
            url: `/vehicles/edit/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Vehicles', 'GetVehicleToUpdate'],
    }),
    createVehicle: builder.mutation({
        query: (data = {}) => ({
            url: `/vehicles/save${data.id ? ("/" + data.id) : ""}`,
            method: "POST",
            data: data,
        }),
       invalidatesTags: ['Vehicles'],
    }),
    deleteVehicle: builder.mutation({
        query: (id) => ({
            url: `/vehicles/${id}`,
            method: "DELETE",
        }),
       invalidatesTags: ['GetVehicles'],
    }),
  }),
});

export const {
    useGetVehiclesQuery,
    useGetVehicleQuery,
    useGetVehicleToUpdateQuery,
    useCreateVehicleMutation,
    useDeleteVehicleMutation,
} = VehicleService;

import { AppService } from '../AppService';

export const ExpenseService = AppService.injectEndpoints({
  endpoints: (builder) => ({
    getExpenses: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/expenses",
            method: "GET",
            params: params,
            // headers: {
            //     Authorization: `Bearer ${getTokenFromLocalStorage()}`,
            // },
        }),
        // keepUnusedDataFor: 5,
        providesTags: ['App', 'Expenses', 'GetExpenses'],
    }),
    getExpenseToUpdate: builder.query({
        query: (id) => ({
            url: `/expenses/edit/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Expenses', 'GetExpenseToUpdate'],
    }),
    createExpense: builder.mutation({
        query: (data = {}) => ({
            url: `/expenses/save${data.id ? ("/" + data.id) : ""}`,
            method: "POST",
            data: data.data,
        }),
       invalidatesTags: ['Expenses', 'GetTrip', 'GetVehicle', 'GetEmployee'],
    }),
    deleteExpense: builder.mutation({
        query: (id) => ({
            url: `/expenses/${id}`,
            method: "DELETE",
        }),
       invalidatesTags: ['GetExpenses', 'GetTrip', 'GetVehicle', 'GetEmployee'],
    }),
  }),
});

export const {
    useGetExpensesQuery,
    useGetExpenseToUpdateQuery,
    useCreateExpenseMutation,
    useDeleteExpenseMutation,
} = ExpenseService;

import { MutableRefObject, useEffect, useRef, useState } from "react";
import { Form } from "../../../../components/form";
import {
  Checkbox,
} from "../../../../components/input";
import { ConfirmationDialog, Modal } from "../../../../components/modal";
import { useDispatch, useSelector } from "react-redux";
import { useGetRolePermissionsQuery, useUpdateRolePermissionsMutation } from "../../../../services/settings/hr/roles/RoleService";
import { clearAlert, setAlert } from "../../../../states/AlertState";
import { capitalize } from './../../../../utils/helpers/helpers';
import { useGetPermissionsQuery } from "../../../../services/settings/hr/permissions/PermissionService";

interface PermissionsProps {
  id?: any,
  name?: string,
  type?: string,
  unEditable?: boolean,
  modal?: MutableRefObject<any>,
}

const Permissions = (props: PermissionsProps) => {
  const user = useSelector((state: any) => state.userState);
  const dispatch = useDispatch();

  const form = useRef<any>();
  const modal = useRef<any>();

  const [formData, setFormData] = useState<any>({permissions: []});
  const { data: permissions} = useGetPermissionsQuery({});
  const { data, isLoading, isSuccess, isError, error } = useGetRolePermissionsQuery(props.id);
  const [ updatePermissions ] = useUpdateRolePermissionsMutation();

  const save = () => {
    if(!form?.current?.validate()) {
      return dispatch(setAlert({type: "error", message: "Please fill all required fields."}));
    }
    modal?.current?.show(
      "Confirm Save",
      <ConfirmationDialog
      promptMessage="Are you sure want to save these Permission(s)"
      onOkay={() => {
        dispatch(setAlert({type: "progress", message: "Processing..."}));
        modal?.current?.hide();
        let FD: any = {};
        Object.keys(formData).forEach((key) => {
         FD[key] = typeof formData[key]?.trim === "function" ? formData[key]?.trim() : formData[key];
        });
        updatePermissions({...FD, id: props.id})
        .unwrap()
        .then((response: any) => {
          dispatch(setAlert({type: "success", message: "Successfully saved permission(s)", unreplaceable: true}));
          setTimeout(() => {
            props.modal?.current?.hide();
          }, 1500);
        })
        .catch((error: any) => {
          dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message, unreplaceable: true}));
        })
      }}
      onCancel={() => {
        modal?.current?.hide();
      }}
    />
    )
  }

  const populateData = (data: any) => {
    setFormData({
      permissions: data.permissions || [],
    });
  }

  useEffect(() => {
    isError &&  dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError]);

  useEffect(() => {
    isLoading && dispatch(setAlert({type: "progress"}));
  }, [isLoading])

  useEffect(() => {
    isSuccess &&  dispatch(clearAlert());
  }, [isSuccess]);

  useEffect(() => {
    data && populateData(data);
  }, [data])

  return (
    <>
      <div className="card scrollable y">
        <div className="card-header">{capitalize(props.name || "")} Permissions</div>
        <div className="card-body">
          <div>
            <Form ref={form}>
              <div className="row">
                {(user?.modules || []).map((module: any, i: number) => (
                  <div className="col-12 col-md-6" key={i}>
                    <div className="card">
                      <div className="card-header row">
                        <span className="mr-4 text-bold">{capitalize(module.module?.replace(/_/g, " "))}</span>
                        <Checkbox
                          checked={formData.permissions?.find((permission: any) => permission.permission === module.module)}
                          onChange={(e: any) => {
                            let itemPermissions = e.target?.checked ?
                            [ ...formData.permissions, {permission: module.module}] : 
                            formData.permissions?.filter((permission: any) => permission.permission !== module.module);

                            e.target?.checked  &&
                            !formData.permissions?.find((permission: any) => permission.permission === `${module.module}_view`) &&
                            itemPermissions.push({permission: `${module.module}_view`});
                            
                            setFormData({
                              ...formData,
                              permissions: itemPermissions || [],
                            });
                          }}
                        />
                      </div>
                      {formData.permissions?.find((permission: any) => permission.permission === module.module) && (
                        <div className="card-body">
                          <div className="row">
                            {permissions?.permissions?.filter((permission: any) => (permission.module === module.module && permission.permission !== module.module))
                            ?.map((modulePermission: any, j: number) => (
                              <div className="col-12 col-md-4" key={j}>
                                <Checkbox
                                  label={capitalize(modulePermission.permission?.replace(new RegExp(`${module.module}_`), "").replace(/_/g, " "))}
                                  checked={formData.permissions?.find((permission: any) => permission.permission === modulePermission.permission)}
                                  onChange={(e: any) => {
                                    let itemPermissions = e.target?.checked ?
                                    [ ...formData.permissions, modulePermission] : 
                                    formData.permissions?.filter((permission: any) => permission.permission !== modulePermission.permission);
                                    
                                    setFormData({
                                      ...formData,
                                      permissions: itemPermissions || [],
                                    });
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </Form>
          </div>
        </div>
        {!props.unEditable &&
        <div className="card-footer d-flex flex-row">
          <button
            className="btn bg-secondary"
            onClick={() => save()}
          >
            Save
          </button>
        </div>}
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default Permissions;

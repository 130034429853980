import { useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "../../components/modal";
import { useGetCargoQuery } from "../../services/cargos/CargoService";
import { useParams } from "react-router-dom";
import { ItemDetails } from "../../components/layout/item-details";
import { getModulePermissions, getNoPermissionMessage, hasPermission, numberFormat } from '../../utils/helpers/helpers';
import { useDispatch, useSelector } from "react-redux";
import { clearAlert, setAlert } from "../../states/AlertState";
import CargoCreate from "./CargoCreate";
import TripList from "../trips/TripList";
import CustomerList from "../customers/CustomerList";

interface CargoDetailsProps {
  cargo?: any,
}

const CargoDetails = (props: CargoDetailsProps) => {
  const user = useSelector((state: any) => state.userState);
  const dispatch = useDispatch();
  const { cargoID } = useParams();

  const modal = useRef<any>();

  const [summary, setSummary] = useState<any>([]);

  const { data, isLoading, isError, error, isSuccess } = useGetCargoQuery(cargoID);

  const permission = useMemo(
    () => getModulePermissions("vehicles", user?.permissions),
    [user]
  )

  const onNoAccess = (action?: string, item?: string) => {
		dispatch(setAlert({type: "error", message: getNoPermissionMessage(action, item)}));
	}

  const editCargo = (cargo: any) => {
    modal.current?.show(
      "Edit Cargo Info",
      (<CargoCreate
        cargoID={cargo.cargo_id}
        modal={modal}
      />),
    );
  }

  useEffect(() => {
    let cargo = data?.cargo;

    setSummary({
      title: cargo?.cargo_name,
      details: [
        {label: "Trips", value: numberFormat(cargo?.total_trips || 0)},
        {label: "Income", value: numberFormat(cargo?.total_income || 0)},
        {label: "Customers", value: numberFormat(cargo?.total_customers || 0)},
      ]
    });
  }, [data]);

  useEffect(() => {
    isError &&  dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError]);

  useEffect(() => {
    isLoading && dispatch(setAlert({type: "progress"}));
  }, [isLoading])

  useEffect(() => {
    isSuccess && dispatch(clearAlert());
  }, [isSuccess]);

  return (
    <>
      <div className="card shadow-sm">
        <div className="card-header">Cargo Details</div>
        <div className="card-body">
          <ItemDetails
            itemName="Cargo"
            permission={permission}
            summary={summary}
            tabs={[
              <TripList
                key={2}
                title="Trips"
                className={!hasPermission("trips", user.permissions) && "disabled"}
                onClick={!hasPermission("trips", user.permissions) ? () => onNoAccess("access", "trips") : undefined}
                exportTitle={`Cargo(${data?.cargo?.first_name} ${data?.cargo?.last_name}) Trips`}
                cargo={data?.cargo}
                useLocalFilters
              />,
              <CustomerList
                key={3}
                title="Customers"
                className={!hasPermission("customers", user.permissions) && "disabled"}
                onClick={!hasPermission("customers", user.permissions) ? () => onNoAccess("access", "customers") : undefined}
                exportTitle={`Cargo(${data?.cargo?.first_name} ${data?.cargo?.last_name}) Customers`}
                cargo={data?.cargo}
                useLocalFilters
              />,
            ]}
          />
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default CargoDetails;

import { useEffect, useRef, useState } from "react";
import { ConfirmationDialog, Modal } from "../../../../components/modal";
import { Table } from "../../../../components/table";
import RoleCreate from "./RoleCreate";

import { useDispatch, useSelector } from "react-redux";
import { useDeleteRoleMutation, useGetRolesQuery } from "../../../../services/settings/hr/roles/RoleService";
import { clearAlert, setAlert } from "../../../../states/AlertState";
import { clearFilters, setFilters } from "../../../../states/FiltersState";
import { Permissions } from "../permissions";
interface RoleListProps {
  exportTitle?: string,
  [rest: string]: any,
}

const RoleList = (props: RoleListProps) => {
  const filters = useSelector((state: any) => state.filtersState);
  const dispatch = useDispatch();
  const modal = useRef<any>();

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetRolesQuery(filters);
  const [ deleteRoleRecord ] = useDeleteRoleMutation();

  const createNewRole = (role: any = undefined) => {
    modal.current?.show(
      `${role ? "Edit Role Info" : "New Role"}`,
      (<RoleCreate
        roleID={role?.role_id}
        modal={modal}
      />),
    );
  }

  const deleteRole = (role: any) => {
    modal?.current?.show(
      "Delete Role",
      <ConfirmationDialog
        promptMessage="Are you sure want to delete this role"
        okayText="Delete"
        okayClassName="bg-danger"
        onOkay={() => {
          dispatch(setAlert({type: "progress", message: "Deleting..."}));
          modal?.current?.hide();
          deleteRoleRecord(role?.role_id)
          .unwrap()
          .then((response: any) => {
            dispatch(setAlert({type: "success", message: "Role successfully deleted"}));
          })
          .catch((error: any) => {
            dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
          })
        }}
        onCancel={() => {
          modal?.current?.hide();
        }}
      />
    )
  };

  const showRulePermissions = (role: any = undefined) => {
    modal.current?.show(
      `${role?.role_name} Permissions`,
      (<Permissions
        id={role?.role_id}
        name={role?.role_name}
        type="role"
        modal={modal}
      />),
    );
  }

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    return () => {
      dispatch(clearFilters())
    }
  }, [])

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div>
            <Table
              loading={isLoading}
              title="Roles"
              exportTitle={props.exportTitle}
              columns={[
                {label: "Role Name", name: "role_name"},
                {label: "Action", name: "action", customRender: true, webOnly: true},
              ]}
              items={data?.roles || []}
              onSearch={(search) => dispatch(setFilters({search: search}))}
              pagination={{
                ...(data?.pagination || {}),
                onPageChange: (page) => dispatch(setFilters({page: page})),
                onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
              }}
              params={{
                ...filters,
                from_date: undefined,
                to_date: undefined
              }}
              actions={[
                <button
                  className="btn small with-icon bg-secondary"
                  onClick={() => createNewRole()}
                  key={0}
                >
                  <span className="material-icons">add</span>
                  <span>Add</span>
                </button>,
              ]}
              customRenders={[
                {
                  columnName: "index",
                  render: (item, index) => <span>{index + 1}</span>
                },
                {
                  columnName: "action",
                  render: (item) => (
                    <div className="d-flex flex-row align-center">
                      <button
                        className="btn small bg-primary cursor-pointer ml-2"
                        onClick={() => showRulePermissions(item)}
                        title="Role Permissions"
                      >
                        Permissions
                      </button>
                      {!([1,2,3,4,5].includes(parseInt(item.role_id))) ? (<>
                        <span
                          className="material-icons secondary-text cursor-pointer ml-2"
                          onClick={() => createNewRole(item)}
                          title="Edit Info"
                        >
                          edit
                        </span>
                        <span
                          className="material-icons danger-text cursor-pointer ml-2"
                          onClick={() => deleteRole(item)}
                          title="Delete Record"
                        >
                          delete
                        </span>
                      </>) : (
                        <span className="grey-text ml-2">N/A</span>
                      )}
                    </div>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default RoleList;

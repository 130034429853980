import { AppService } from '../../AppService';

export const CompanyService = AppService.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyToUpdate: builder.query({
        query: (id) => ({
            url: `/companies/edit/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Companies', 'GetCompanyToUpdate'],
    }),
    createCompany: builder.mutation({
        query: (data = {}) => ({
            url: `/companies/save${data.id ? ("/" + data.id) : ""}`,
            method: "POST",
            data: data.data,
        }),
        invalidatesTags: ['Companies', 'GetUser'],
    }),
  }),
});

export const {
    useGetCompanyToUpdateQuery,
    useCreateCompanyMutation,
} = CompanyService;

import React, { Component, useEffect, useState } from "react";
import { Routes } from "react-router-dom";

interface TabsProps {
  router?: any,
  location?: any,
  activeTab?: any,
  onTabChange?: (tab: any) => void,
  className?: string,
  bodyClassName?: string,
  basePath?: string,
  navigate?: (path: string) => void,
  [rest: string]: any,
}

const Tabs = (props: TabsProps) => {
  
  const [activeTab, setActiveTab] = useState(props.router ? props.location?.pathname : props.activeTab || 0);

  useEffect(() => {
    if (props.router && props.location) {
      setActiveTab(props.location.pathname);
    }

    if (props.onTabChange) {
      props.onTabChange(activeTab);
    }
  }, [props.location, props.activeTab])

 const _getContainerClassName = () => {
    let classes = ["tab-layout"];

    if (props.className) {
      classes.push(props.className);
    }

    return classes.join(" ");
  };

  const _getBodyClassName = () => {
    let classes = [`tab-layout-body ${window.innerWidth < 960 ? "py-2" : "pa-2"}`];

    if (props.bodyClassName) {
      classes.push(props.bodyClassName);
    }

    return classes.join(" ");
  };

  const _getTabClassName = (tab: any, index: any) => {
    let classes = [];
    if (activeTab === index || (props.router && activeTab === props.basePath + tab.props.path)) {
      classes.push("active");
    }
    tab.props.className && classes.push(tab.props.className);

    return classes.join(" ");
  }

  const _getChildren = () => {
    const children = props.children;
    return typeof children.forEach === "function"
      ? children.filter((item: any) => item !== null)
      : [children];
  }

  return (
    <div className={_getContainerClassName()}>
      <div className="tab-layout-header">
        {_getChildren().map((e: any, i: number) => (
          <button
            key={i.toString()}
            className={"tab " + _getTabClassName(e, i)}
            onClick={() => {
              if(_getTabClassName(e, i).indexOf("disabled") !== -1 || e.props.onClick) {
                e.props.onClick && e.props.onClick();
              }
              else if (props.router) {
                props.navigate &&
                props.navigate(props.basePath + e.props.path);
              } else {
                setActiveTab(i);
              }
            }}
          >
            {e.props.title}
            {e.props.badge ? (
              <span className="badge">{e.props.badge}</span>
            ) : null}
          </button>
        ))}
      </div>
      <div className={_getBodyClassName()}>
        {props.router ? (
          <Routes>{props.children}</Routes>
        ) : (
          <>
            {_getChildren().find((e: any, i: number) => (
              i === activeTab &&
              _getTabClassName(e, i).indexOf("disabled") === -1 &&
              !e.props.onClick
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Tabs;

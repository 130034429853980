import React, { useEffect, useMemo, useRef, useState } from "react";
import { ConfirmationDialog, Modal } from "../../../../components/modal";
import { Table } from "../../../../components/table";
import { Form } from "../../../../components/form";
import ChargeAdd from "./ChargeAdd";

import { useDispatch, useSelector } from "react-redux";
import { useDeleteChargeMutation, useGetChargesQuery } from "../../../../services/settings/general/ChargeService";
import { clearAlert, setAlert } from "../../../../states/AlertState";
import { clearFilters, setFilters } from "../../../../states/FiltersState";
import { capitalize, getValidationRules, numberFormat, stripCommas } from "../../../../utils/helpers/helpers";
import { useCreateGeneralConfigMutation } from "../../../../services/settings/general/GeneralConfigService";
import { SelectInput, TextField } from "../../../../components/input";
interface DiscountAndChargesProps {
  exportTitle?: string,
  [rest: string]: any,
}

const DiscountAndCharges = (props: DiscountAndChargesProps) => {
  const user = useSelector((state: any) => state.userState);
  const filters = useSelector((state: any) => state.filtersState);
  const dispatch = useDispatch();
  const inputValidation = getValidationRules(); 
  const form = useRef<any>();
  const modal = useRef<any>();
  const inputs: any = useMemo(
    () => Array.from({length: 8}).map(() =>  React.createRef()),
    []
  )

  const [formData, setFormData] = useState<any>({});
  const [ createConfig ] = useCreateGeneralConfigMutation();
  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetChargesQuery(filters);
  const [ deleteChargeRecord ] = useDeleteChargeMutation();
  const [configuredCharges, setConfiguredCharges] = useState([]);

  const [discountTypes] = useState<any[]>([
    {label: "Percent", value: "percent"},
    {label: "Fixed", value: "fixed"},
  ]);

  const addCharge = (charge: any = undefined) => {
    modal.current?.show(
      "Add Charge",
      (<ChargeAdd
        charges={
          data?.charges
          ?.filter((charge: any) => !(user?.configuration?.charges?.split(",")?.includes(charge.charge_id)))
          ?.map((charge: any) => ({label: `${charge.name} (${capitalize(charge.category)})`, value: charge.charge_id}))
          || []
        }
        modal={modal}
      />),
      0,
      "small"
    );
  }

  const save = () => {
    if(!form?.current?.validate()) {
      return dispatch(setAlert({type: "error", message: "Please fill all required fields."}));
    }
    modal?.current?.show(
      "Confirm Save",
      <ConfirmationDialog
      promptMessage="Are you sure want to save this Discount"
      onOkay={() => {
        dispatch(setAlert({type: "progress", message: "Processing..."}));
        modal?.current?.hide();
        let FD: any = {};
        Object.keys(formData).forEach((key) => {
         FD[key] = typeof formData[key]?.trim === "function" ? formData[key]?.trim() : formData[key];
        });
        let FDT = {
          configs: {
            discount: FD.discount,
            discount_type: FD.discount_type,
          }
        }
        createConfig({...FDT})
        .unwrap()
        .then((response: any) => {
          dispatch(setAlert({type: "success", message: "Discount has successfully saved", unreplaceable: true}));
        })
        .catch((error: any) => {
          dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
        })
      }}
      onCancel={() => {
        modal?.current?.hide();
      }}
    />
    )
  }

  const deleteCharge = (charge: any) => {
    modal?.current?.show(
      "Remove Charge",
      <ConfirmationDialog
        promptMessage="Are you sure want to remove this charge"
        okayText="Remove"
        okayClassName="bg-danger"
        onOkay={() => {
          dispatch(setAlert({type: "progress", message: "Removing..."}));
          modal?.current?.hide();
          let FD = {
            configs: {
              charges: user?.configuration?.charges?.split(",")?.filter((chargeID: any) => chargeID !== charge.charge_id)?.join(",") || null,
            }
          }
          createConfig({...FD})
          .unwrap()
          .then((response: any) => {
            dispatch(setAlert({type: "success", message: "Charge successfully removed"}));
          })
          .catch((error: any) => {
            dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
          })
        }}
        onCancel={() => {
          modal?.current?.hide();
        }}
      />
    )
  };

  useEffect(() => {
    let config = user?.configuration;
    config?.charges && setConfiguredCharges(data?.charges?.filter((charge: any) => config?.charges?.split(",")?.includes(charge.charge_id)))
  }, [data, user])

  useEffect(() => {
    let config = user?.configuration;
    config && setFormData({
      discount: config.discount,
      discount_type: config.discount_type,
    })
  }, [user])

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    return () => {
      dispatch(clearFilters())
    }
  }, [])

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header bg-white">Trips Discount</div>
            <div className="card-body">
              <div>
                <Form ref={form}>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <SelectInput
                        ref={inputs[0]}
                        label="Discount Type"
                        block={true}
                        clearable
                        options={discountTypes}
                        value={discountTypes.filter(discountType => discountType.value === formData.discount_type)}
                        onChange={(value) => setFormData({
                          ...formData,
                          discount_type: value?.value
                        })}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <TextField
                        ref={inputs[1]}
                        label="Discount Rate/Amount"
                        block={true}
                        rules={[inputValidation.number]}
                        value={numberFormat(formData.discount) || ""}
                        onChange={(value) => setFormData({
                          ...formData,
                          discount: stripCommas(value)
                        })}
                      />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className="card-footer d-flex flex-row">
              <button
                className="btn bg-secondary"
                onClick={() => save()}
              >
                Save Discount
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 mt-5">
          <div className="card">
            <div className="card-body">
              <div>
                <Table
                  loading={isLoading}
                  title="Trips Charges"
                  exportTitle={props.exportTitle}
                  columns={[
                    {label: "Charge Category", name: "category", customRender: true},
                    {label: "Charge Name", name: "name"},
                    {label: "Charge Type", name: "type", customRender: true},
                    {label: "Charge Rate/Amount", name: "amount", customRender: true},
                    {label: "Action", name: "action", customRender: true, webOnly: true},
                  ]}
                  items={configuredCharges || []}
                  onSearch={(search) => dispatch(setFilters({search: search}))}
                  pagination={{
                    ...(data?.pagination || {}),
                    onPageChange: (page) => dispatch(setFilters({page: page})),
                    onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
                  }}
                  params={{
                    ...filters,
                    from_date: undefined,
                    to_date: undefined
                  }}
                  actions={[
                    <button
                      className="btn small with-icon bg-secondary"
                      onClick={() => addCharge()}
                      key={0}
                    >
                      <span className="material-icons">add</span>
                      <span>Add</span>
                    </button>,
                  ]}
                  customRenders={[
                    {
                      columnName: "index",
                      render: (item, index) => <span>{index + 1}</span>
                    },
                    {
                      columnName: "type",
                      render: (item, index) => <span>{item.type && capitalize(item.type)}</span>
                    },
                    {
                      columnName: "category",
                      render: (item, index) => <span>{item.category && capitalize(item.category)}</span>
                    },
                    {
                      columnName: "amount",
                      render: (item, index) => <span>{item.amount && numberFormat(item.amount)}</span>
                    },
                    {
                      columnName: "action",
                      render: (item) => (
                        <div className="d-flex flex-row align-center">
                          <span
                            className="material-icons danger-text cursor-pointer"
                            onClick={() => deleteCharge(item)}
                            title="Remove"
                          >
                            close
                          </span>
                        </div>
                      )
                    }
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default DiscountAndCharges;

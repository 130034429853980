import { useState, useEffect, ReactNode } from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import "./NavButton.scss";

interface NavButtonProps {
  title: string,
  url: string,
  icon?: string,
  onClick?: () => void,
  disabled?: boolean,
}

const NavButton = (props: NavButtonProps) => {

  return (
    <div className="">
      <Link
        to={!props.disabled ? props.url : ""}
        onClick={(e) => {
          if(props.onClick){
            e.preventDefault();
            props.onClick();
          }
        }}
      >
        <div className={`nav-button btn shadow-lg p-3 ${!props.disabled ? "bg-white" : "disabled"} rounded`}>
          <div className="material-icons icon">{props.icon}</div>
          <div className="title">{props.title}</div>
        </div>
      </Link>
    </div>
  );
}

export default NavButton;

import { AppService } from '../AppService';

export const PaymentService = AppService.injectEndpoints({
  endpoints: (builder) => ({
    getPayments: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/payments",
            method: "GET",
            params: params,
            // headers: {
            //     Authorization: `Bearer ${getTokenFromLocalStorage()}`,
            // },
        }),
        // keepUnusedDataFor: 5,
        providesTags: ['App', 'Payments', 'GetPayments'],
    }),
    getPaymentToUpdate: builder.query({
        query: (id) => ({
            url: `/payments/edit/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Payments', 'GetPaymentToUpdate'],
    }),
    createPayment: builder.mutation({
        query: (data = {}) => ({
            url: `/payments/save${data.id ? ("/" + data.id) : ""}`,
            method: "POST",
            data: data.data,
        }),
       invalidatesTags: ['Payments', 'GetTrip', 'GetPaymentTransactions', 'GetPaymentTransaction'],
    }),
    deletePayment: builder.mutation({
        query: (id) => ({
            url: `/payments/${id}`,
            method: "DELETE",
        }),
       invalidatesTags: ['GetPayments', 'GetTrip', 'GetPaymentTransactions', 'GetPaymentTransaction'],
    }),
  }),
});

export const {
    useGetPaymentsQuery,
    useGetPaymentToUpdateQuery,
    useCreatePaymentMutation,
    useDeletePaymentMutation,
} = PaymentService;

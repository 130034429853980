import { AppService } from '../AppService';

export const RouteService = AppService.injectEndpoints({
  endpoints: (builder) => ({
    getRoutes: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/routes",
            method: "GET",
            params: params,
        }),
        providesTags: ['App', 'Routes', 'GetRoutes'],
    }),
    getRouteToUpdate: builder.query({
        query: (id) => ({
            url: `/routes/edit/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Routes', 'GetRouteToUpdate'],
    }),
    createRoute: builder.mutation({
        query: (data = {}) => ({
            url: `/routes/save${data.id ? ("/" + data.id) : ""}`,
            method: "POST",
            data: data,
        }),
       invalidatesTags: ['Routes', 'GetCustomer'],
    }),
    deleteRoute: builder.mutation({
        query: (id) => ({
            url: `/routes/${id}`,
            method: "DELETE",
        }),
       invalidatesTags: ['GetRoutes', 'GetCustomer'],
    }),
  }),
});

export const {
    useGetRoutesQuery,
    useGetRouteToUpdateQuery,
    useCreateRouteMutation,
    useDeleteRouteMutation,
} = RouteService;

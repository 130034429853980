import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { Form } from "../../../../components/form";
import {
  TextArea,
  TextField
} from "../../../../components/input";
import { ConfirmationDialog, Modal } from "../../../../components/modal";
import { getValidationRules } from '../../../../utils/helpers/helpers';
import { useDispatch } from "react-redux";
import { useCreateExpenseCategoryMutation, useGetExpenseCategoryToUpdateQuery } from "../../../../services/settings/expenses/expense-categories/ExpenseCategoryService";
import { clearAlert, setAlert } from "../../../../states/AlertState";

interface ExpenseCategoryCreateProps {
  expenseCategoryID?: any,
  modal?: MutableRefObject<any>,
}

const ExpenseCategoryCreate = (props: ExpenseCategoryCreateProps) => {
  const dispatch = useDispatch();
  const inputValidation = getValidationRules(); 

  const form = useRef<any>();
  const modal = useRef<any>();
  const inputs: any = useMemo(
    () => Array.from({length: 8}).map(() =>  React.createRef()),
    []
  )

  const [formData, setFormData] = useState<any>({});
  const { data, isLoading, isSuccess, isError, error } = useGetExpenseCategoryToUpdateQuery(props.expenseCategoryID || -1);
  const [ createExpenseCategory ] = useCreateExpenseCategoryMutation();

  const save = () => {
    if(!form?.current?.validate()) {
      return dispatch(setAlert({type: "error", message: "Please fill all required fields."}));
    }
    modal?.current?.show(
      "Confirm Save",
      <ConfirmationDialog
      promptMessage="Are you sure want to save this Expense Category Info"
      onOkay={() => {
        dispatch(setAlert({type: "progress", message: "Processing..."}));
        modal?.current?.hide();
        let FD: any = {};
        Object.keys(formData).forEach((key) => {
         FD[key] = typeof formData[key]?.trim === "function" ? formData[key]?.trim() : formData[key];
        });
        createExpenseCategory({...FD, id: props.expenseCategoryID})
        .unwrap()
        .then((response: any) => {
          if (props.expenseCategoryID) {
            dispatch(setAlert({type: "success", message: "Expense Category Info has successfully saved", unreplaceable: true}));
          }
          else {
            dispatch(setAlert({type: "success", message: "Expense Category has successfully added", unreplaceable: true}));
          }
          setTimeout(() => {
            props.modal?.current?.hide();
          }, 1500);
        })
        .catch((error: any) => {
          dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
        })
      }}
      onCancel={() => {
        modal?.current?.hide();
      }}
    />
    )
  }

  const populateData = (data: any) => {
    setFormData({
      ...data.expense_category,
    });
  }

  useEffect(() => {
    isError &&  dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError]);

  useEffect(() => {
    isLoading && dispatch(setAlert({type: "progress"}));
  }, [isLoading])

  useEffect(() => {
    isSuccess &&  dispatch(clearAlert());
  }, [isSuccess]);

  useEffect(() => {
    data && populateData(data);
  }, [data])

  return (
    <>
      <div className="card scrollable y">
        <div className="card-body">
          <div>
            <Form ref={form}>
              <div className="row">
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[0]}
                    label="Expense Category Name"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={formData.expense_category_name || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      expense_category_name: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextArea
                    ref={inputs[1]}
                    label="Description"
                    block={true}
                    value={formData.description || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      description: value
                    })}
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className="card-footer d-flex flex-row">
          <button
            className="btn bg-secondary"
            onClick={() => save()}
          >
            {props.expenseCategoryID ? "Save" : "Add Expense Category"}
          </button>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default ExpenseCategoryCreate;

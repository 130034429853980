import React from "react";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { capitalize, formatDateTimeForDb } from "../../utils/helpers/helpers";
import { appName } from "../../utils/constants/constants";

export default function Footer(props: any) {

  return (
    <View
      fixed={!props.notFixed}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: 20,
        position: "absolute",
        width: "100%",
        bottom: 10,
        marginHorizontal: 24,
      }}
    >
      {
        <Text
          style={[
            props.style,
            {
              color: "grey",
            },
          ]}
        >
          {props.noPrintedBy
            ? null
            : `Printed by ${capitalize(props.user?.first_name || "")} ${capitalize(props.user?.last_name || "")} at ${formatDateTimeForDb(new Date())}`}
        </Text>
      }

      <Text
        {...props}
        style={[
          props.style,
          {
            textAlign: "center",
            color: "grey",
            position: "absolute",
            width: "100%",
            bottom: 10,
          },
        ]}
      />

      <Text
        style={[
          props.style,
          {
            color: "grey",
          },
        ]}
      >
        Powered by {appName}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  descriptionsItem: {
    flexDirection: "row",
  },
  text: {
    fontSize: 12,
    // fontFamily: "Custom",
  },

  descriptionsItemValue: {
    width: "60%",
  },
});

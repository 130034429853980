import { Table } from "../../../components/table";


import PDFReport from "../../../components/reports/PDFReport";
import SpreadsheetReport from "../../../components/reports/SpreadsheetReport";
import ReportHeader from "../../../components/reports/ReportHeader";
import ReportFooter from "../../../components/reports/ReportFooter";
import { ReactNode, useEffect, useState } from "react";

interface ItemDetailsBasicProps {
  itemName?: string,
  items?: {
    label: string,
    value?: string,
    isSectionTitle?: boolean,
    className?: string,
  }[],
  editAction?: ReactNode,
  permission?: any,
  [rest: string]: any,
}

const ItemDetailsBasic = (props: ItemDetailsBasicProps) => {
  const [items, setItems] = useState<any>([]);
  const [params, setParams] = useState({search: undefined});

  const columns = [
    {
      name: "label",
      colSpan: (item: any, index: number, column: any) => item.isSectionTitle && 2,
      className: (item: any, index: number, column: any) => item.isSectionTitle ? "bg-d text-large text-center py-3" : "",
      flex: (item: any, index: number, column: any) => item.isSectionTitle ? 2 : 1,
    },
    {name: "value", customRender: true},
  ];

  useEffect(() => {
    props.items && setItems(props.items)
  }, [props.items])

  useEffect(() => {
    let search: any = params.search;
    search !== undefined && setItems(props.items?.filter(item =>
      item.label?.toLowerCase().includes(search?.toLowerCase()) ||
      item.value?.toLowerCase().includes(search?.toLowerCase())
    ))
  }, [params.search])
  
  return (
    <div>
      <Table
        exportTitle={`${props.itemName} Basic Info`}
        permission={props.permission}
        noHeaderRow
        hideIndex
        tableCellClassName="w-50"
        getRowClassName={(item) => item.isSectionTitle ? "no-hover" : ""}
        columns={columns}
        items={items}
        onSearch={(search) => setParams({...params, search})}
        params={params}
        customRenders={[
          {
            columnName: "value",
            render: (item) => <span className={item.className}>{item.value}</span>
          },
        ]}
      />
      {props.editAction && props.editAction}
    </div>
  );
}

export default ItemDetailsBasic;

import {
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

const PDFSignatureField = (props: any) => {
  const { signature, label } = props || {};

  return (
    <View>
      <Text style={[{fontWeight: "bold", marginBottom: 24}]}>{label}:</Text>
      <Text style={[{borderBottom: "1px solid #666666"}]}>{signature}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  spacer: {
    marginBottom: 4,
  },
  divider: {
    height: 1,
    backgroundColor: "black",
  },
  text: {
    fontSize: 8,
    // fontFamily: "Custom",
  },
  outlined: {
    border: "1pt solid black",
  },
  padded: {
    padding: 5,
  },
  grey: {
    backgroundColor: "grey",
  },
  lightGrey: {
    backgroundColor: "#F5F5F5",
  },
  row: {
    flexDirection: "row",
  },
  table: {
    border: "1pt solid #666666",
    borderTopWidth: 0,
    borderLeftWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    border: "1pt solid #666666",
    borderRightWidth: 0,
    borderBottomWidth: 0,
    padding: 4,
    flex: 1,
    fontSize: 8,
  },
});

export default PDFSignatureField;

import { AppService } from '../../../AppService';

export const ExpenseCategoryItemService = AppService.injectEndpoints({
  endpoints: (builder) => ({
    getExpenseCategoryItems: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/expense-category-items",
            method: "GET",
            params: params,
        }),
        providesTags: ['App', 'ExpenseCategoryItems', 'GetExpenseCategoryItems'],
    }),
    getExpenseCategoryItemToUpdate: builder.query({
        query: (id) => ({
            url: `/expense-category-items/edit/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'ExpenseCategoryItems', 'GetExpenseCategoryItemToUpdate'],
    }),
    createExpenseCategoryItem: builder.mutation({
        query: (data = {}) => ({
            url: `/expense-category-items/save${data.id ? ("/" + data.id) : ""}`,
            method: "POST",
            data: data,
        }),
       invalidatesTags: ['ExpenseCategoryItems', 'Expenses'],
    }),
    deleteExpenseCategoryItem: builder.mutation({
        query: (id) => ({
            url: `/expense-category-items/delete/${id}`,
            method: "DELETE",
        }),
       invalidatesTags: ['GetExpenseCategoryItems', 'Expenses'],
    }),
  }),
});

export const {
    useGetExpenseCategoryItemsQuery,
    useGetExpenseCategoryItemToUpdateQuery,
    useCreateExpenseCategoryItemMutation,
    useDeleteExpenseCategoryItemMutation,
} = ExpenseCategoryItemService;

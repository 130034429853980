import { useState, useEffect, useRef, MouseEventHandler } from 'react';
import Dropdown from './Dropdown';
import { Link, useLocation } from 'react-router-dom';

interface MenuItemsProps {
  items: {
    title: string,
    url?: string,
    hoverBackground?: boolean,
    subMenu?: [],
    noDropDownIcon?: boolean,
    startIcon?: string,
    endIcon?: string,
    onClick?: () => void,
    disabled?: boolean,
  },
  depthLevel: number,
  dropdownClassName?: string,
}

const MenuItems = (props: MenuItemsProps) => {
  const location = useLocation();
  
  const [dropdown, setDropdown] = useState(false);

  let ref = useRef<any>();

  useEffect(() => {
    const handler = (event: any) => {
      if (
        dropdown &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        setDropdown(false);
      }
    };
    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };

  const closeDropdown = () => {
    dropdown && setDropdown(false);
  };

 const {items, depthLevel, dropdownClassName} = props;

  return (
    <li
      className={`menu-items ${items.hoverBackground === false && "no-hover-bg"} ${location.pathname.indexOf(`${items.url}`) === 0 && "active"}`}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={closeDropdown}
    >
      {items.onClick || items.disabled ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? 'true' : 'false'}
            onClick={() => items.onClick && items.onClick()}
            className={`${items.disabled && "disabled"}`}
          >
            {items.startIcon && <span className="material-icons">{items.startIcon}</span>} {items.title}{' '}
          </button>
        </>
      ) :
      items.url && items.subMenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? 'true' : 'false'}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {window.innerWidth < 960 && depthLevel === 0 ? (
              items.title
            ) : (
              <Link to={items.url}>{items.startIcon && <span className="material-icons">{items.startIcon}</span>} {items.title}</Link>
            )}

            {depthLevel > 0 &&
            window.innerWidth < 960 ? null : depthLevel > 0 &&
              window.innerWidth > 960 ? (
              <span>&raquo;</span>
            ) : (
              <span className="arrow" />
            )}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            subMenus={items.subMenu}
            dropdown={dropdown}
          />
        </>
      ) : !items.url && items.subMenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? 'true' : 'false'}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {items.startIcon && <span className="material-icons">{items.startIcon}</span>} {items.title}{' '}
            {items.noDropDownIcon ? null : depthLevel > 0 ? (
              <span>&raquo;</span>
            ) : (
              <span className="arrow" />
            )}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            subMenus={items.subMenu}
            dropdown={dropdown}
            className={dropdownClassName}
          />
        </>
      ) : (
        <Link to={items.url || ""}>{items.startIcon && <span className="material-icons">{items.startIcon}</span>} {items.title}</Link>
      )}
    </li>
  );
};

export default MenuItems;

import { useEffect, useState } from "react";
import { Table } from "../../components/table";

import {
  DateInput,
  SelectInput,
} from "../../components/input";

import { capitalize, formatDateForDb, formatDateTimeForDb, localTimeToUtc } from "../../utils/helpers/helpers";
import { useDispatch, useSelector } from "react-redux";
import { clearAlert, setAlert } from "../../states/AlertState";
import { clearFilters, setFilters } from "../../states/FiltersState";
import { useGetUserActivitiesReportQuery } from "../../services/reports/ReportService";
import { utcToLocalTime } from './../../utils/helpers/helpers';

interface UserActivitiesReportProps {
  exportTitle?: string,
  employee?: any,
  [rest: string]: any,
}

const UserActivitiesReport = (props: UserActivitiesReportProps) => {
  const filters = useSelector((state: any) => state.filtersState);
  const dispatch = useDispatch();  

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetUserActivitiesReportQuery({
    ...filters,
    from_date: localTimeToUtc(filters.from_date),
    to_date: localTimeToUtc(filters.to_date),
    employee: filters.employee || props.employee?.user_id,
  });

  const [users, setUsers] = useState<any[]>([]);
  const [itemTypes, setItemTypes] = useState([
    { value: "all", label: "All"},
    { value: "trip", label: "Trip"},
    { value: "tpayment", label: "Trip Payment"},
    { value: "expense", label: "Expense"},
    { value: "expense_category", label: "Expense Category"},
    { value: "expense_category_item", label: "Expense item"},
    { value: "vehicle", label: "Vehicle"},
    { value: "route", label: "Route"},
    { value: "customer", label: "Customer"},
    { value: "cargo", label: "Cargo"},
    { value: "employee", label: "Employee"},
    { value: "designation", label: "Designation"},
    { value: "role", label: "Role"},
    { value: "user", label: "Account"},
  ]);

  const [actions, setActions] = useState([
    { value: "all", label: "All"},
    { value: "create", label: "Create"},
    { value: "update", label: "Update"},
    { value: "delete", label: "Delete"},
    { value: "login", label: "Login"},
    { value: "logout", label: "Logout"},
    { value: "change_password", label: "Change Password"},
  ]);

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    data?.employees && setUsers(data.employees.map((employee: any) => ({label: `${employee.first_name} ${employee.last_name}` , value: employee.user_id})));
  }, [data])

  useEffect(() => {
    return () => {
      dispatch(clearFilters())
    }
  }, [])

  return (
    <>
      <div className={`row shadow bg-white p-2 mb-1 ${(props.trip || props.vehicle || props.expenseCategoryID) ? "justify-center" : ""}`}>
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Date From"
            block={true}
            dateFormat="yyyy-MM-dd HH:mm"
            showTimeSelect
            value={new Date(filters.from_date)}
            onChange={(value) => {
              dispatch(setFilters({
                from_date: formatDateTimeForDb(value),
              }))
            }}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Date To"
            block={true}
            dateFormat="yyyy-MM-dd HH:mm"
            showTimeSelect
            value={new Date(filters.to_date)}
            onChange={(value) => {
              dispatch(setFilters({
                to_date: formatDateTimeForDb(value),
              }))
            }}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Entry Type"
            block={true}
            clearable
            options={itemTypes}
            onChange={(value) => dispatch(setFilters({
              item_type: value?.value
            }))}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Action Performed"
            block={true}
            clearable
            options={actions}
            onChange={(value) => dispatch(setFilters({
              action: value?.value
            }))}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="User Involved"
            block={true}
            clearable
            options={users}
            onChange={(value) => dispatch(setFilters({
              user: value?.value
            }))}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div>
            <Table
              loading={isLoading}
              title="User Activities Report"
              exportTitle={props.exportTitle}
              noSearch
              columns={[
                {label: "Entry Type", name: "item_type", customRender: true},
                {label: "Action Performed", name: "action_performed", customRender: true},
                {label: "User Involved", name: "employee_name"},
                {label: "Time", name: "created_at", customRender: true},
                {label: "Entry", name: "item_id"},
              ]}
              items={data?.user_activities || []}
              onSearch={(search) => dispatch(setFilters({search: search}))}
              pagination={{
                ...(data?.pagination || {}),
                onPageChange: (page) => dispatch(setFilters({page: page})),
                onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
              }}
              params={filters}
              customRenders={[
                {
                  columnName: "index",
                  render: (item, index) => <span>{index + 1}</span>
                },
                {
                  columnName: "item_type",
                  render: (item) => <span>{capitalize((item.item_type === "user" ? "Account" : item.item_type).replace("_"," "))}</span>
                },
                {
                  columnName: "action_performed",
                  render: (item) => <span>{capitalize(item.action.replace("_"," "))}</span>
                },
                {
                  columnName: "created_at",
                  render: (item) => <span>{utcToLocalTime(item.created_at)}</span>
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default UserActivitiesReport;

import React, { useState, forwardRef, useImperativeHandle, useEffect, ReactNode, ChangeEvent, useRef } from "react";

interface TextFieldProps {
    disabled?: boolean,
    value?: any,
    defaultValue?: any,
    onChange?: (value?: any, input?: object) => void,
    onBlur?: (value?: any, input?: object) => void,
    label?: string,
    placeholder?: string,
    type?: string,
    inputProps?: any,
    hint?: string,
    disablePaste?: boolean,
    hintClassName?: string,
    requiredDecorator?: boolean,
    renderStartIcon?: () => ReactNode,
    renderEndIcon?: () => ReactNode,
    block?: boolean,
    direction?: string,
    rules?: any[],
    className?: string,
    style?: object,
};

const TextField = forwardRef((props: TextFieldProps, ref) => {
  var input: any = useRef();

  const [isFocused, setIsFocused] = useState(false);
  const [val, setVal] = useState(props.defaultValue || props.value);
  const [error, setError] = useState<string>();
  const [hint, setHint] = useState<string>();

  const _getContainerClassName = () => {
    let className = "input-group";

    if (isFocused) {
      className += " is-focused";
    }

    if (props.block) {
      className += " block";
    }

    if (props.direction === "horizontal") {
      className += " horizontal";
    }

    if (error) {
      className += " has-error";
    }

    if (props.className) {
      className += ` ${props.className}`;
    }

    return className;
  }

  const validate = (value: any) => {
    let rules = props.rules || [],
      rulesLength = rules.length,
      i = 0;
    for (; i < rulesLength; i++) {
      let validate = rules[i](value !== undefined ? value : val);
      if (validate !== true) {
        setError(validate);
        return false;
      } else {
        setError(undefined);
      }
    }

    return true;
  }

  const clearValidation= (value: any) => {
    setError(undefined);
  }

  const _onChange = (input: any, toValidate = true) => {
    if (props.onChange) {
      props.onChange(input.value, input);
    };

    setVal(input.value);
    toValidate && validate(input.value);
  }

  const _onBlur = (input: any, toValidate = true) => {
    if (props.onBlur) {
      props.onBlur(input.value, input);
    };

    setVal(input.value);
    toValidate && validate(input.value);
  }

  const getInput = () => {
    return input;
  }

  const setValue = (value: any, validate = false) => {
    setVal(value)
    input.value = value;
    _onChange(input, validate);
  }
  
  useEffect(() => {
    setVal(props.value);
  }, [props.value])

  useEffect(() => {
    setHint(props.hint);
  }, [props.hint])

  useImperativeHandle(ref, () => {
    return {
      setValue: setValue,
      validate: validate,
      clearValidation: clearValidation,
      getInput: getInput,
    };
  });

  return (
    <div className={_getContainerClassName()}>
      {props.label ? (
        <div className="label px-1">
          {props.label}
          {props.requiredDecorator ? (
            <span className="red-text"> *</span>
          ) : null}
        </div>
      ) : null}
      <div className="flex-grow-1">
        <div className="d-flex flex-row align-center input">
          {props.renderStartIcon ? props.renderStartIcon() : null}
          <input
            ref={(ref) => (input = ref)}
            {...props.inputProps}
            disabled={props.disabled}
            className={
              "flex-grow-1 input-field" +
              ((props.inputProps && props.inputProps.className) ||
                "")
            }
            type={props.type || "text"}
            placeholder={props.placeholder}
            defaultValue={props.defaultValue}
            value={props.value}
            onPaste={(event) => !props.disablePaste}
            onFocus={() => setIsFocused(true)}
            onBlur={(event: any) => {
              setIsFocused(false);
              _onBlur(event.target);
            }}
            onChange={(event: ChangeEvent) => _onChange(event.target)}
          />
          {props.renderEndIcon ? props.renderEndIcon() : null}
        </div>
        {error ? (
          <div className="error px-1 red-text text-small">
            {error}
          </div>
        ) : null}
        {hint ? (
          <div
            className={
              "hint px-1 text-small " +
              (props.hintClassName || "hint-text")
            }
          >
            {hint}
          </div>
        ) : null}
      </div>
    </div>
  );
});

export default TextField;
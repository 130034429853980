import { AppService } from '../../AppService';

export const GeneralConfigService = AppService.injectEndpoints({
  endpoints: (builder) => ({
    getGeneralConfigs: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/general-configs",
            method: "GET",
            params: params,
        }),
        providesTags: ['App', 'GeneralConfigs', 'GetGeneralConfigs'],
    }),
    createGeneralConfig: builder.mutation({
        query: (data = {}) => ({
            url: `/general-configs/save${data.id ? ("/" + data.id) : ""}`,
            method: "POST",
            data: data,
        }),
       invalidatesTags: ['GeneralConfigs', 'GetUser'],
    }),
  }),
});

export const {
    useGetGeneralConfigsQuery,
    useCreateGeneralConfigMutation,
} = GeneralConfigService;

import React from "react";

interface ConfirmationDialogProps {
  promptMessage: string,
  onCancel?: () => void,
  onOkay?: () => void,
  okayText?: string,
  okayClassName?: string,
}

const ConfirmationDialog = (props: ConfirmationDialogProps) => {

    return (
      <div className="card">
        <div className="card-body">
          {props.promptMessage} <span className="text-bold">?</span>
        </div>
        <div className="card-footer d-flex flex-row justify-center">
          <button
            type="button"
            className="btn outlined mr-1"
            onClick={() => {
              props.onCancel && props.onCancel();
            }}
          >
            Cancel
          </button>
          <button
            className={`btn ${props.okayClassName || "bg-primary"}`}
            onClick={() => {
              props.onOkay && props.onOkay();
            }}
          >
            {props.okayText || "Yes"}
          </button>
        </div>
      </div>
    );
}

export default ConfirmationDialog;

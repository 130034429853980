import { AppService } from '../../../AppService';

export const RoleService = AppService.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/roles",
            method: "GET",
            params: params,
        }),
        providesTags: ['App', 'Roles', 'GetRoles'],
    }),
    getRole: builder.query({
        query: (id) => ({
            url: `/roles/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Roles', 'GetRole'],
    }),
    getRoleToUpdate: builder.query({
        query: (id) => ({
            url: `/roles/edit/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Roles', 'GetRoleToUpdate'],
    }),
    createRole: builder.mutation({
        query: (data = {}) => ({
            url: `/roles/save${data.id ? ("/" + data.id) : ""}`,
            method: "POST",
            data: data,
        }),
       invalidatesTags: ['Roles'],
    }),
    deleteRole: builder.mutation({
        query: (id) => ({
            url: `/roles/${id}`,
            method: "DELETE",
        }),
       invalidatesTags: ['GetRoles'],
    }),
    getRolePermissions: builder.query({
        query: (id) => ({
            url: `/roles/permissions/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Roles', 'GetRolePermissions'],
    }),
    updateRolePermissions: builder.mutation({
        query: (data = {}) => ({
            url: `/roles/permissions/save/${data.id}`,
            method: "POST",
            data: data,
        }),
       invalidatesTags: ['Roles', 'GetUser'],
    }),
  }),
});

export const {
    useGetRolesQuery,
    useGetRoleQuery,
    useGetRoleToUpdateQuery,
    useCreateRoleMutation,
    useDeleteRoleMutation,
    useGetRolePermissionsQuery,
    useUpdateRolePermissionsMutation,
} = RoleService;

import { AppService } from '../AppService';

export const CustomerService = AppService.injectEndpoints({
  endpoints: (builder) => ({
    getCustomers: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/customers",
            method: "GET",
            params: params,
        }),
        providesTags: ['App', 'Customers', 'GetCustomers'],
    }),
    getCustomer: builder.query({
        query: (id) => ({
            url: `/customers/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Customers', 'GetCustomer'],
    }),
    getCustomerToUpdate: builder.query({
        query: (id) => ({
            url: `/customers/edit/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Customers', 'GetCustomerToUpdate'],
    }),
    createCustomer: builder.mutation({
        query: (data = {}) => ({
            url: `/customers/save${data.id ? ("/" + data.id) : ""}`,
            method: "POST",
            data: data,
        }),
       invalidatesTags: ['Customers', 'GetCargo'],
    }),
    deleteCustomer: builder.mutation({
        query: (id) => ({
            url: `/customers/${id}`,
            method: "DELETE",
        }),
       invalidatesTags: ['GetCustomers', 'GetCargo'],
    }),
  }),
});

export const {
    useGetCustomersQuery,
    useGetCustomerQuery,
    useGetCustomerToUpdateQuery,
    useCreateCustomerMutation,
    useDeleteCustomerMutation,
} = CustomerService;

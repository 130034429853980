import { AppService } from '../../../AppService';

export const DesignationService = AppService.injectEndpoints({
  endpoints: (builder) => ({
    getDesignations: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/designations",
            method: "GET",
            params: params,
        }),
        providesTags: ['App', 'Designations', 'GetDesignations'],
    }),
    getDesignation: builder.query({
        query: (id) => ({
            url: `/designations/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Designations', 'GetDesignation'],
    }),
    getDesignationToUpdate: builder.query({
        query: (id) => ({
            url: `/designations/edit/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Designations', 'GetDesignationToUpdate'],
    }),
    createDesignation: builder.mutation({
        query: (data = {}) => ({
            url: `/designations/save${data.id ? ("/" + data.id) : ""}`,
            method: "POST",
            data: data,
        }),
       invalidatesTags: ['Designations', 'Employees'],
    }),
    deleteDesignation: builder.mutation({
        query: (id) => ({
            url: `/designations/${id}`,
            method: "DELETE",
        }),
       invalidatesTags: ['GetDesignations', 'Employees'],
    }),
  }),
});

export const {
    useGetDesignationsQuery,
    useGetDesignationQuery,
    useGetDesignationToUpdateQuery,
    useCreateDesignationMutation,
    useDeleteDesignationMutation,
} = DesignationService;

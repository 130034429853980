import { AppService } from '../AppService';

export const EmployeeService = AppService.injectEndpoints({
  endpoints: (builder) => ({
    getEmployees: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/employees",
            method: "GET",
            params: params,
        }),
        providesTags: ['App', 'Employees', 'GetEmployees'],
    }),
    getEmployee: builder.query({
        query: (id) => ({
            url: `/employees/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Employees', 'GetEmployee'],
    }),
    getEmployeeToUpdate: builder.query({
        query: (id) => ({
            url: `/employees/edit/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Employees', 'GetEmployeeToUpdate'],
    }),
    createEmployee: builder.mutation({
        query: (data = {}) => ({
            url: `/employees/save${data.id ? ("/" + data.id) : ""}`,
            method: "POST",
            data: data,
        }),
       invalidatesTags: ['Employees', 'GetUser'],
    }),
    deleteEmployee: builder.mutation({
        query: (id) => ({
            url: `/employees/${id}`,
            method: "DELETE",
        }),
       invalidatesTags: ['GetEmployees'],
    }),
  }),
});

export const {
    useGetEmployeesQuery,
    useGetEmployeeQuery,
    useGetEmployeeToUpdateQuery,
    useCreateEmployeeMutation,
    useDeleteEmployeeMutation,
} = EmployeeService;

import { CompanyCreate } from "./company";
import { DiscountAndCharges } from "./trips";
import { ChargeList } from "./general";
import {
  ExpenseCategoryItemList,
  ExpenseCategoryList
} from "./expenses";
import {
  DesignationList,
  RoleList,
} from "./hr";
import {
  InvoiceSetup, 
  ReceiptSetup
} from "./accounting";

const SettingsRoutes = [
  {
    url: "/company",
    component: CompanyCreate,
    permission: "settings_company",
    props: {}
  },
  // {
  //   url: "/",
  //   redirect: "index",
  //   permission: "settings",
  //   props: {}
  // },
  {
    url: "/expenses/expense-categories",
    component: ExpenseCategoryList,
    permission: "settings_expense_categories",
    props: {}
  },
  {
    url: "/expenses/expense-category-items",
    component: ExpenseCategoryItemList,
    permission: "settings_expense_category_items",
    props: {}
  },
  {
    url: "/employees/roles",
    component: RoleList,
    permission: "settings_roles",
    props: {}
  },
  {
    url: "/employees/designations",
    component: DesignationList,
    permission: "settings_designations",
    props: {}
  },
  {
    url: "/general/charges",
    component: ChargeList,
    permission: "settings_charges",
    props: {}
  },
  {
    url: "/trips/discount-and-charges",
    component: DiscountAndCharges,
    permission: "settings_discount_and_charges",
    props: {}
  },
  {
    url: "/accounting/invoice",
    component: InvoiceSetup,
    permission: "settings_invoice",
    props: {}
  },
  {
    url: "/accounting/receipt",
    component: ReceiptSetup,
    permission: "settings_receipt",
    props: {}
  },
];

export default SettingsRoutes;
import { useEffect, useMemo, useRef, useState } from "react";
import { ConfirmationDialog, Modal } from "../../components/modal";
import { Table } from "../../components/table";
import ExpenseCreate from "./ExpenseCreate";

import {
  DateInput,
  SelectInput,
} from "../../components/input";

import { formatDateForDb, getModulePermissions, getNoPermissionMessage } from "../../utils/helpers/helpers";
import { numberFormat } from './../../utils/helpers/helpers';
import { useDispatch, useSelector } from "react-redux";
import { useDeleteExpenseMutation, useGetExpensesQuery } from "../../services/expenses/ExpenseService";
import { clearAlert, setAlert } from "../../states/AlertState";
import { setFilters as setFilter } from "../../states/FiltersState";
import { clearLocalFilters, setLocalFilters } from "../../states/FiltersLocalState";

interface ExpenseListProps {
  exportTitle?: string,
  trip?: any,
  vehicle?: any,
  employee?: any,
  expenseCategoryID?: any,
  useLocalFilters?: boolean,
  [rest: string]: any,
}

const ExpenseList = (props: ExpenseListProps) => {
  const user = useSelector((state: any) => state.userState);
  const setFilters: any = !props.useLocalFilters ? setFilter : setLocalFilters;
  const filters = useSelector((state: any) => !props.useLocalFilters ? state.filtersState : state.filtersLocalState);
  const dispatch = useDispatch();
  const modal = useRef<any>();

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetExpensesQuery({
    ...filters, trip: props.trip?.trip_id,
    vehicle: filters.vehicle || props.vehicle?.vehicle_id, 
    employee: filters.employee || props.employee?.user_id,
    expense_category: filters.expense_category || props.expenseCategoryID,
  });
  const [ deleteExpenseRecord ] = useDeleteExpenseMutation();

  const [expenseCategories, setExpenseCategories] = useState<any[]>([]);

  const [vehicles, setVehicles] = useState<any[]>([]);

  const permission = useMemo(
    () => getModulePermissions("expenses", user?.permissions),
    [user]
  );

  const createNewExpense = (expense: any = undefined) => {
    modal.current?.show(
      `${expense ? "Edit Expense Info" : "New Expense"}`,
      (<ExpenseCreate
        expenseID={expense?.expense_id}
        trip={props.trip}
        vehicle={props.vehicle}
        expenseCategoryID={props.expenseCategoryID}
        employee={props.employee}
        modal={modal}
      />),
    );
  }

  const deleteExpense = (expense: any) => {
    modal?.current?.show(
      "Delete Expense",
      <ConfirmationDialog
        promptMessage="Are you sure want to delete this expense"
        okayText="Delete"
        okayClassName="bg-danger"
        onOkay={() => {
          dispatch(setAlert({type: "progress", message: "Deleting..."}));
          modal?.current?.hide();
          deleteExpenseRecord(expense?.expense_id)
          .unwrap()
          .then((response: any) => {
            dispatch(setAlert({type: "success", message: "Expense successfully deleted"}));
          })
          .catch((error: any) => {
            dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
          })
        }}
        onCancel={() => {
          modal?.current?.hide();
        }}
      />
    )
  };

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    data?.expense_categories && setExpenseCategories(data.expense_categories.map((expense_category: any) => ({label: expense_category.expense_category_name, value: expense_category.expense_category_id})));
    data?.vehicles && setVehicles(data.vehicles.map((vehicle: any) => ({label: vehicle.registration_number, value: vehicle.vehicle_id})));
  }, [data])

  useEffect(() => {
    return () => {
      props.useLocalFilters && dispatch(clearLocalFilters());
    }
  }, [])

  return (
    <>
      <div className={`row shadow bg-white p-2 mb-1 ${(props.trip || props.vehicle || props.expenseCategoryID) ? "justify-center" : ""}`}>
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Date From"
            block={true}
            value={new Date(filters.from_date)}
            onChange={(value) => {
              dispatch(setFilters({
                from_date: formatDateForDb(value),
              }))
            }}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Date To"
            block={true}
            value={new Date(filters.to_date)}
            onChange={(value) => {
              dispatch(setFilters({
                to_date: formatDateForDb(value),
              }))
            }}
          />
        </div>
        {!props.expenseCategoryID &&
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Category"
            block={true}
            clearable
            options={expenseCategories}
            onChange={(value) => dispatch(setFilters({
              expense_category: value?.value
            }))}
          />
        </div>}
        {!(props.trip || props.vehicle) &&
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Vehicle"
            block={true}
            clearable
            options={vehicles}
            onChange={(value) => dispatch(setFilters({
              vehicle: value?.value
            }))}
          />
        </div>}
      </div>
      <div className="card">
        <div className="card-body">
          <div>
            <Table
              loading={isLoading}
              title="Expenses"
              exportTitle={props.exportTitle}
              columns={[
                {label: "Date", name: "expense_date"},
                {label: "Vehicle", name: "vehicle_registration_number", show: !(props.trip || props.vehicle)},
                {label: "Category", name: "expense_category_name", show: !props.expenseCategoryID},
                {label: "Amount (Tsh)", name: "amount", customRender: true},
                {label: "Attachment", name: "attachment", customRender: true, webOnly: true},
                {label: "Description", name: "description"},
                {label: "Action", name: "action", customRender: true, webOnly: true},
              ]}
              items={data?.expenses || []}
              permission={permission}
              onSearch={(search) => dispatch(setFilters({search: search}))}
              pagination={{
                ...(data?.pagination || {}),
                onPageChange: (page) => dispatch(setFilters({page: page})),
                onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
              }}
              params={filters}
              actions={[
                ...(props.expenseCategoryID != 1 ? [
                <button
                  className={`btn small with-icon bg-secondary ${!permission?.add && "disabled"}`}
                  onClick={() =>
                    permission?.add ?
                    createNewExpense() :
                    dispatch(setAlert({type: "error", message: getNoPermissionMessage("add", "expense")}))
                  }
                  key={0}
                >
                  <span className="material-icons">add</span>
                  <span>Add</span>
                </button>] : []),
              ]}
              customRenders={[
                {
                  columnName: "index",
                  render: (item, index) => <span>{index + 1}</span>
                },
                {
                  columnName: "amount",
                  render: (item, index) => <span>{item.amount && numberFormat(item.amount)}</span>
                },
                {
                  columnName: "attachment",
                  render: (item) => (
                    <div className="d-flex flex-row align-center">
                      {item.supporting_doc &&
                      <button
                        className="btn small bg-primary cursor-pointer"
                        onClick={() => window.open(item.supporting_doc, "_blank")}
                        title="Attachment"
                      >
                        View
                      </button>}
                    </div>
                  )
                },
                {
                  columnName: "action",
                  render: (item) => (
                    <div className="d-flex flex-row align-center">
                      <span
                        className={`material-icons cursor-pointer ml-2 secondary-text ${!permission?.update && "disabled"}`}
                        onClick={(e) => {
                          e.stopPropagation(); 
                          permission?.update ?
                          createNewExpense(item):
                          dispatch(setAlert({type: "error", message: getNoPermissionMessage("edit", "expense")}))
                        }}
                        title="Edit Info"
                      >
                        edit
                      </span>
                      <span
                        className={`material-icons cursor-pointer ml-2 danger-text ${!permission?.delete && "disabled"}`}
                        onClick={(e) => {
                          e.stopPropagation(); 
                          permission?.delete ?
                          deleteExpense(item):
                          dispatch(setAlert({type: "error", message: getNoPermissionMessage("delete", "expense")}))
                        }}
                        title="Delete Record"
                      >
                        delete
                      </span>
                    </div>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default ExpenseList;

import MenuItems from './MenuItems';

interface DropdownProps {
  subMenus: any[],
  dropdown: boolean,
  depthLevel: number,
  className?: string,
}

const Dropdown = (props: DropdownProps) => {
  let {subMenus, dropdown, depthLevel, className} = props;
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? 'dropdown-submenu' : '';
  return (
    <ul
      className={`dropdown ${dropdownClass} ${
        dropdown ? 'show' : ''
      } ${className || ''}`}
    >
      {subMenus.map((subMenu, index) => (
        <MenuItems
          items={subMenu}
          key={index}
          depthLevel={depthLevel}
        />
      ))}
    </ul>
  );
};

export default Dropdown;

import { useEffect, useState } from "react";
import { Table } from "../../components/table";

import {
  DateInput,
  SelectInput,
} from "../../components/input";

import { formatDateForDb } from "../../utils/helpers/helpers";
import { numberFormat } from '../../utils/helpers/helpers';
import { useDispatch, useSelector } from "react-redux";
import { clearAlert, setAlert } from "../../states/AlertState";
import { clearFilters, setFilters } from "../../states/FiltersState";
import { useGetIncomeReportQuery } from "../../services/reports/ReportService";
interface IncomeReportProps {
  exportTitle?: string,
  useLocalFilters?: boolean,
  [rest: string]: any,
}

const IncomeReport = (props: IncomeReportProps) => {
  const filters = useSelector((state: any) => state.filtersState);
  const dispatch = useDispatch();

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetIncomeReportQuery({...filters, trip: filters.trip || props.trip?.trip_id});

  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [vehicles, setVehicles] = useState<any[]>([]);

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    data?.payment_methods && setPaymentMethods(data.payment_methods.map((payment_method: any) => ({label: payment_method.payment_method_name, value: payment_method.payment_method_id})));
    data?.vehicles && setVehicles(data.vehicles.map((vehicle: any) => ({label: vehicle.registration_number, value: vehicle.vehicle_id})));
  }, [data])

  useEffect(() => {
    return () => {
      dispatch(clearFilters())
    }
  }, [])

  return (
    <>
      {/* {patientList?.data?.data?.map((payment: any) => (<div>Hey there</div>))} */}
      <div className="row justify-center shadow bg-white p-2 mb-1">
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Date From"
            block={true}
            value={new Date(filters.from_date)}
            onChange={(value) => {
              dispatch(setFilters({
                from_date: formatDateForDb(value),
              }))
            }}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Date To"
            block={true}
            value={new Date(filters.to_date)}
            onChange={(value) => {
              dispatch(setFilters({
                to_date: formatDateForDb(value),
              }))
            }}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Vehicle"
            block={true}
            clearable
            options={vehicles}
            onChange={(value) => dispatch(setFilters({
              vehicle: value?.value
            }))}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Payment Method"
            block={true}
            clearable
            options={paymentMethods}
            onChange={(value) => dispatch(setFilters({
              payment_method: value?.value
            }))}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div>
            <Table
              loading={isLoading}
              title="Income Report"
              exportTitle={props.exportTitle}
              columns={[
                {label: "Income Date", name: "payment_date"},
                {label: "Vehicle", name: "vehicle_registration_number"},
                {label: "Payment Method", name: "payment_method_name"},
                {label: "Comment", name: "comment"},
                {label: "Income Amount (Tsh)", name: "amount", customRender: true},
              ]}
              items={data?.incomes || []}
              onSearch={(search) => dispatch(setFilters({search: search}))}
              pagination={{
                ...(data?.pagination || {}),
                onPageChange: (page) => dispatch(setFilters({page: page})),
                onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
              }}
              params={filters}
              customRenders={[
                {
                  columnName: "index",
                  render: (item, index) => <span>{index + 1}</span>
                },
                {
                  columnName: "amount",
                  render: (item, index) => <span>{item.amount && numberFormat(item.amount)}</span>
                },
              ]}
              totalColumns={[
                { name: "amount" }
              ]}
              totalColSpan={5}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default IncomeReport;

import { AppService } from '../../../AppService';

export const ExpenseCategoryService = AppService.injectEndpoints({
  endpoints: (builder) => ({
    getExpenseCategories: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/expense-categories",
            method: "GET",
            params: params,
        }),
        providesTags: ['App', 'ExpenseCategories', 'GetExpenseCategories'],
    }),
    getExpenseCategoryToUpdate: builder.query({
        query: (id) => ({
            url: `/expense-categories/edit/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'ExpenseCategories', 'GetExpenseCategoryToUpdate'],
    }),
    createExpenseCategory: builder.mutation({
        query: (data = {}) => ({
            url: `/expense-categories/save${data.id ? ("/" + data.id) : ""}`,
            method: "POST",
            data: data,
        }),
       invalidatesTags: ['ExpenseCategories', 'ExpenseCategoryItems', 'Expenses'],
    }),
    deleteExpenseCategory: builder.mutation({
        query: (id) => ({
            url: `/expense-categories/delete/${id}`,
            method: "DELETE",
        }),
       invalidatesTags: ['GetExpenseCategories', 'ExpenseCategoryItems', 'Expenses'],
    }),
  }),
});

export const {
    useGetExpenseCategoriesQuery,
    useGetExpenseCategoryToUpdateQuery,
    useCreateExpenseCategoryMutation,
    useDeleteExpenseCategoryMutation,
} = ExpenseCategoryService;

import CustomerDetails from './CustomerDetails';
import CustomerList from './CustomerList';

const CustomersRoutes = [
  {
    url: "/",
    component: CustomerList,
    permission: "customers",
    props: {}
  },
  {
    url: "/details/:customerID",
    component: CustomerDetails,
    permission: "customers_view",
    props: {}
  },
]

export default CustomersRoutes;
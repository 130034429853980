import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const EmployeeService = createApi({
  reducerPath: 'EmployeeService',
  baseQuery: fetchBaseQuery({ baseUrl: 'http://199.192.23.117:30003' }),
  tagTypes: ['employees'],
  endpoints: (builder) => ({
    getEmployees: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/employees",
            method: "GET",
            params: params,
        }),
    }),
    getEmployee: builder.query({
        query: (id) => ({
            url: `/employees/${id}`,
            method: "GET",
        }),
    }),
    createEmployee: builder.mutation({
        query: (body = {}) => ({
            url: "/employees",
            method: "POST",
            body: body,
        }),
    //    invalidatesTags: ['employees'],
    }),
    updateEmployee: builder.mutation({
        query: (id, body = {}) => ({
            url: `/employees/${id}`,
            method: "POST",
            body: body,
       }),
       invalidatesTags: ['employees'],
    }),
    deleteEmployee: builder.mutation({
        query: (id) => ({
            url: `/employees/${id}`,
            method: "DELETE",
        }),
       invalidatesTags: ['employees'],
    }),
  }),
});

export const {
    useGetEmployeesQuery,
    useGetEmployeeQuery,
    useCreateEmployeeMutation,
    useUpdateEmployeeMutation,
    useDeleteEmployeeMutation
} = EmployeeService;

import { useEffect } from "react";

import { numberFormat } from '../../utils/helpers/helpers';
import { useDispatch, useSelector } from "react-redux";
import { useGetSummaryReportQuery } from "../../services/reports/ReportService";
import { clearAlert, setAlert } from "../../states/AlertState";
import { useNavigate } from "react-router-dom";

interface SummaryReportProps {
}

const SummaryReport = (props: SummaryReportProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetSummaryReportQuery({});

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  return (
    <>
      <div className="summary-reports bg-d py-5 px-3">
  
        <div className="jumbotron">
          <div className="row w-100">
            <div className="col-md-3" onClick={() => navigate("/reports/trips")}>
              <div className="card border-info mx-sm-1 p-4 summary-report-card">
                <div className="card border-info shadow info-text p-3 icon" ><span className="material-icons">commute</span></div>
                <div className="info-text text-center mt-3"><h5>Trips</h5></div>
                <div className="number info-text text-center mt-4"><h4>{numberFormat(data?.total_trips)}</h4></div>
              </div>
            </div>
            <div className="col-md-3" onClick={() => navigate("/reports/income")}>
              <div className="card border-success mx-sm-1 p-4 summary-report-card">
                <div className="card border-success shadow success-text p-3 icon"><span className="material-icons">paid</span></div>
                <div className="success-text text-center mt-3"><h5>Income</h5></div>
                <div className="number success-text text-center mt-4"><h4>{numberFormat(data?.total_income, 1)}</h4></div>
              </div>
            </div>
            <div className="col-md-3" onClick={() => navigate("/reports/expenses/all")}>
              <div className="card border-danger mx-sm-1 p-4 summary-report-card">
                <div className="card border-danger shadow danger-text p-3 icon" ><span className="material-icons">paid</span></div>
                <div className="danger-text text-center mt-3"><h5>Expenses</h5></div>
                <div className="number danger-text text-center mt-4 text-sm"><h4>{numberFormat(data?.total_expense, 1)}</h4></div>
              </div>
            </div>
            <div className="col-md-3" onClick={() => navigate("/reports/income-vs-expense")}>
              <div className="card border-success mx-sm-1 p-4 summary-report-card">
                <div className="card border-success shadow success-text p-3 icon" ><span className="material-icons">paid</span></div>
                <div className="success-text text-center mt-3"><h5>Profit</h5></div>
                <div className="number success-text text-center mt-4"><h4>{numberFormat(data?.total_profit, 1)}</h4></div>
              </div>
            </div>
          </div>
        </div>
      
      </div>
    </>
  );
}

export default SummaryReport;

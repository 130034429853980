import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from "react";

const Alert = forwardRef((props: any, ref) => {
  // var timeOut: any = useRef();
  var element: any = useRef();

  const [visible, setVisible] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [classes, setClasses] = useState<string>("");
  const [timeOut, setTimeOut] = useState<any>();

  const _isInViewport = () => {
    const rect = element?.current?.getBoundingClientRect();
    // return (
    //   rect.top - 128 >= 0 &&
    //   rect.left >= 0 &&
    //   rect.bottom <=
    //     (window.innerHeight || document.documentElement.clientHeight) &&
    //   rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    // );
    
    // temporary return true before checking why the above commented code return false
    return true;
  }

  const _isValidMessage = (message: any) => {
    return typeof message === "string";
  }

  const _show = (message: any, classesStr: string, timeOut = undefined) => {
    if (!_isValidMessage(message)) {
      // if message is not a string
      return false;
    }

    classesStr = classesStr || "";

    setVisible(true);
    setMessage(message);
    setClasses(classesStr);

    if (!_isInViewport()) {
      setClasses(classes + " toast" )
    }
    if (classesStr.indexOf("progress") === -1) {
      if (timeOut) {
        clearTimeout(timeOut);
      }
      setTimeOut(setTimeout(() => {
        close();
      }, timeOut || 5000));
    }
    
  }

  const showProgress = (message?: string, timeOut = undefined) => {
    message = message || "Loading...";
    _show(message, "bg-info progress toast", timeOut);
  }

  const showInfo = (message: string, timeOut = undefined) =>  {
    _show(message, "bg-info dismissible toast", timeOut);
  }

  const showWarning = (message: string, timeOut = undefined) => {
    _show(message, "warning dismissible toast", timeOut);
  }

  const showError = (message: string, timeOut = undefined) =>  {
    _show(message, "error dismissible toast", timeOut);
  }

  const showSuccess = (message: string, timeOut = undefined) =>  {
    _show(message, "success dismissible toast", timeOut);
  }

  const close = () =>  {
    setVisible(false);
    setMessage("");
    setClasses("");
  }

  useEffect(() => {
    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
    }
  }, [])

  useImperativeHandle(ref, () => {
    return {
      showProgress,
      showInfo,
      showWarning,
      showError,
      showSuccess,
      close,
    };
  });

  if (!visible) return null;

  return (
    <div
      ref={(ref) => (element = ref)}
      className={
        "alert animate__animated animate__fadeIn animate__faster " +
        classes
      }
    >
      {classes.indexOf("progress") !== -1 && (
        <div className="progress-bar-circle" />
      )}
      <div className="message">{message}</div>
      {classes.indexOf("dismissible") !== -1 && (
        <div className="dismiss" onClick={() => close()} title="Close">
          <span className="material-icons">close</span>
        </div>
      )}
    </div>
  );
});

export default Alert;
import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorCount: 0,
    };
  }

  componentDidMount() {
    window.onerror = () => {
      this.setState({ hasError: true });
    };
  }

  // componentWillUnmount() {
  //   this.setState({ hasError: true });
  //   // this.setState({ errorCount: this.state.errorCount + 1});
  // }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // TODO: send error information to API
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="application d-flex align-center justify-center">
          <div className="row">
            <div className="flex-grow-1"/>
            <div className="col-12 col-md-4 col-sm-6">
              <div className="card transparent">
                <div className="card-body">
                  <span
                    className="material-icons grey-text d-block mx-auto mb-3"
                    style={{ width: "72px", fontSize: "72px" }}
                  >error_outlined</span>
                  <div className="text-large text-center mb-1">Something broke</div>
                  <p>We are sorry for the inconvenience. Here are things you can try:</p>
                  <ul>
                    <li>Refresh the page and try again</li>
                    <li>If that does not help, please contact the vendor team</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="flex-grow-1"/>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

import React, { ChangeEventHandler } from "react";

interface CheckboxProps {
    disabled?: boolean,
    value?: any,
    defaultValue?: any,
    checked?: boolean,
    defaultChecked?: boolean,
    onChange?: ChangeEventHandler,
    readOnly?: boolean,
    label?: any;
    className?: string,
    style?: object,

};

const Checkbox = (props: CheckboxProps) => {
  return (
    <label className={"checkbox cursor-pointer " + (props.className || "")} style={props.style}>
      <input
        type="checkbox"
        disabled={props.disabled}
        value={props.value}
        defaultValue={props.defaultValue}
        checked={props.checked}
        defaultChecked={props.defaultChecked}
        onChange={props.onChange}
        readOnly={props.readOnly}
      />
      {props.label}
    </label>
  );
}

export default Checkbox;
import React, { useState, forwardRef, useImperativeHandle, useEffect, ReactNode, ChangeEvent, cloneElement, FC, useRef } from "react";

const Form = forwardRef((props: any, ref) => {
  var form: any = useRef();
  // const [allFields, setAllFields] = useState<number>(0);
  // const [validFields, setValidFields] = useState<number>(0);
  var allFields = 0;
  var validFields = 0;

  const _validateInputs = (children: any) => {
    if (!children) return true;

    if (typeof children.forEach !== "function") {
      children = [children];
    }

    children.filter((e: any) => !!e).forEach((e: any) => {
      const hasChildren = e.props && e.props.children;
      if (hasChildren) {
        _validateInputs(e.props.children);
      } else {
        if (e.ref && e.props && e.props.rules) {
          var component: any = cloneElement(e).ref;
          component = component.current;
          if (component) {
            // setAllFields(allFields + 1);
            allFields +=  1;
            if (component.validate()) {
              // setValidFields(validFields + 1);
              validFields += 1;
            }
          }
        }
      }
    });
  }

  const _clearInputsValidation = (children: any) => {
    if (!children) return;

    if (typeof children.forEach !== "function") {
      children = [children];
    }

    children.filter((e: any) => !!e).forEach((e: any) => {
      const hasChildren = e.props && e.props.children;
      if (hasChildren) {
        _clearInputsValidation(e.props.children);
      } else {
        if (e.ref && e.props && e.props.rules) {
          var component: any = cloneElement(e).ref;
          component = component.current;
          if (component) {
            component.clearValidation();
          }
        }
      }
    });
  }

  const _clearInputs = (children: any) => {
    if (!children) return true;

    if (typeof children.forEach !== "function") {
      children = [children];
    }

    children.filter((e: any) => !!e).forEach((e: any) => {
      const hasChildren = e.props && e.props.children;
      if (hasChildren) {
        _clearInputs(e.props.children);
      } else {
        if (e.ref) {
          var component: any = cloneElement(e).ref;
          component = component.current;
          if (component && typeof component.setValue === "function") {
            component.setValue(null);
          }
        }
      }
    });
  }

  const validate = () => {
    // setAllFields(0);
    // setValidFields(0);
    allFields = 0;
    validFields = 0;
    _validateInputs(props.children);
    return allFields === validFields;
  }

  const clearValidation = () => {
    _clearInputsValidation(props.children);
  }

  const clear = (afterClear: () => void) => {
    _clearInputs(props.children);

    if (typeof afterClear === "function") {
      afterClear();
    }
  }

  useImperativeHandle(ref, () => {
    return {
      clear: clear,
      validate: validate,
      clearValidation: clearValidation,
      form: form,
    };
  });

  return (
    <form
      ref={(ref) => (form = ref)}
      autoComplete={"off"}
      {...props}
    >
      {props.children}
    </form>
  );
});

export default Form;
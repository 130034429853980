import React, { useState, forwardRef, useImperativeHandle, ChangeEventHandler, useEffect, ReactNode, useRef } from "react";
import ConfirmationDialog from './ConfirmationDialog';
import PromptDialogue from "./PromptDialogue";

interface ModalProps {
  hideClose?: boolean,
  onHide?: () => void,
  className?: string,
  cardClassName?: string,
}

const Modal = forwardRef((props: ModalProps, ref) => {
  var element: any = useRef();
  var prevReport: any = null;
  var thisReport: any = null;

  const [shown, setShown] = useState<boolean>(false);
  const [pageYOffset, setPageYOffset] = useState<number>(0);
  const [size, setSize] = useState<string>("default");
  const [title, setTitle] = useState<string>("");
  const [component, setComponent] = useState<any>(null);
  // componentDidUpdate(prevProps, prevState) {
  //   // fix report printing
  //   if (this.state.shown) {
  //     let prevSibling = this.element.previousElementSibling;

  //     if (prevSibling) {
  //       this.prevReport = prevSibling.querySelector(".report");
  //       this.thisReport = this.element.querySelector(".report");

  //       if (this.prevReport && this.thisReport) {
  //         this.prevReport.classList.remove("print");
  //       }
  //     }
  //   } else {
  //     if (this.prevReport) {
  //       this.prevReport.classList.add("print");
  //     }
  //   }
  // }

  const show = (title: string, component: any, pageYOffset: number = 0, size:string = "default") => {
    if (
      component.type === ConfirmationDialog ||
      component.type === PromptDialogue
    ) {
      size = "small";
    }

    let data = {
      shown: true,
      title,
      component,
      pageYOffset,
      size,
    };

    setShown(true);
    setTitle(title);
    setComponent(component);
    setPageYOffset(pageYOffset);
    setSize(size);
  }

  const hide = () => {
    setShown(false);
  }

  const getState = () => {
    return {
      shown,
      title,
      component,
      pageYOffset,
      size,
    }
  }

  useImperativeHandle(ref, () => {
    return {
      show,
      hide,
      getState,
    };
  });

  if (!shown) return null;
  return (
    <div
      ref={(ref) => (element = ref)}
      className={`modal animate__animated animate__fadeIn animate__faster ${
        size
      } ${props.className || ""}`}
      style={{ top: 0 }}
    >
      <div
        className={`card border-0 modal-content animate__animated animate__zoomIn animate__faster ${props.cardClassName}`}
      >
        <div className="card-header with-actions">
          <div className="text-uppercase">{title}</div>
          <div className="card-actions">
            {!props.hideClose ? (
              <button
                className="btn secondary icon small rounded"
                onClick={(e) => {
                  e.stopPropagation();
                  hide();
                  props.onHide && props.onHide();
                }}
              >
                <span className="material-icons">close</span>
              </button>
            ) : null}
          </div>
        </div>
        <div className="card-body">{component}</div>
      </div>
    </div>
  );
});

export default Modal;
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const FiltersLocalState = createSlice({
  name: 'filtersLocalState',
  initialState: {
    from_date: moment("2010-01-01").format("YYYY-MM-DD HH:mm:ss"),
    to_date: moment().add(1, 'year').endOf("year").format("YYYY-MM-DD HH:mm:ss"),
    page: 1,
    limit: 10,
  },
  reducers: {
    setLocalFilters: (state, action) => {
      return {...state, ...action.payload, ...(!action.payload?.page ? {page: 1} : {})};
    },
    clearLocalFilters: (state: any) => {
      return {
        from_date: moment("2010-01-01").format("YYYY-MM-DD HH:mm:ss"),
        to_date: moment().add(1, 'year').endOf("year").format("YYYY-MM-DD HH:mm:ss"),
        page: 1,
        limit: 10,
      };
    }
  },
});

// this is for dispatch
export const { setLocalFilters, clearLocalFilters } = FiltersLocalState.actions;

// this is for configureStore
export default FiltersLocalState.reducer;

import { useEffect } from "react";
import { Router } from "../../components/router"
import { menuType } from "../../utils/types";
import SettingsMenu from "./SettingsMenu";
import SettingsRoutes from "./SettingsRoutes";
import { useDispatch, useSelector } from "react-redux";
import { clearFilters } from "../../states/FiltersState";
import { AccessDenied } from "../../components/permission";
import { AuthenticateMenu, AuthenticateRoutes } from "../../utils/helpers/helpers";
interface SettingsProps {
  setMenu: (menu: menuType) => void,
  setShowSideMenu: (show: boolean) => void,
  onNoAccess: (action?: string, item?: string, prefixModule?: string, sufix?: string) => void,
}

const Settings = (props: SettingsProps) => {
  const user = useSelector((state: any) => state.userState);
  const dispatch = useDispatch();

  useEffect(() => {
    props.setShowSideMenu(true);
    return () => {
      dispatch(clearFilters())
    }
  }, []);

  useEffect(() => {
    props.setMenu(
      AuthenticateMenu(SettingsMenu, user.permissions, (permission: string) => props.onNoAccess("access", permission, "settings", "setup"))
    );
  }, [user]);

  return (
    <Router
      routes={AuthenticateRoutes(SettingsRoutes, user.permissions, AccessDenied)}
    />
  );
}

export default Settings;

import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { Form } from "../../components/form";
import {
  DateInput,
  SelectInput,
  TextField
} from "../../components/input";
import { ConfirmationDialog, Modal } from "../../components/modal";
import { formatDateForDb, formatYearForDb, getValidDateOrNull, getValidYearOrNull, stripCommas } from "../../utils/helpers/helpers";
import { getValidationRules, numberFormat } from './../../utils/helpers/helpers';
import { useDispatch } from "react-redux";
import { useCreateVehicleMutation, useGetVehicleToUpdateQuery } from "../../services/vehicles/VehicleService";
import { clearAlert, setAlert } from "../../states/AlertState";

interface VehicleCreateProps {
  vehicleID?: any,
  modal?: MutableRefObject<any>,
}

const VehicleCreate = (props: VehicleCreateProps) => {
  const dispatch = useDispatch();
  const inputValidation = getValidationRules(); 

  const form = useRef<any>();
  const modal = useRef<any>();
  const inputs: any = useMemo(
    () => Array.from({length: 20}).map(() =>  React.createRef()),
    []
  );

  const [formData, setFormData] = useState<any>({});

  const { data, isLoading, isSuccess, isError, error } = useGetVehicleToUpdateQuery(props.vehicleID || -1);
  const [ createVehicle ] = useCreateVehicleMutation();

  const [vehicleTypes, setVehicleTypes] = useState<any[]>([]);

  const [drivers, setDrivers] = useState<any[]>([]);

  const save = () => {
    if(!form?.current?.validate()) {
      return dispatch(setAlert({type: "error", message: "Please fill all required fields."}));
    }
    modal?.current?.show(
      "Confirm Save",
      <ConfirmationDialog
      promptMessage="Are you sure want to save this Vehicle Info"
      onOkay={() => {
        dispatch(setAlert({type: "progress", message: "Processing..."}));
        modal?.current?.hide();
        let FD: any = {};
        Object.keys(formData).forEach((key) => {
          FD[key] = typeof formData[key]?.trim === "function" ? formData[key]?.trim() : formData[key];
        });
        createVehicle({...FD, id: props.vehicleID})
        .unwrap()
        .then((response: any) => {
          if (props.vehicleID) {
            dispatch(setAlert({type: "success", message: "Vehicle Info has successfully saved", unreplaceable: true}));
          }
          else {
            dispatch(setAlert({type: "success", message: "Vehicle has successfully added", unreplaceable: true}));
          }
          setTimeout(() => {
            props.modal?.current?.hide();
          }, 1500);
        })
        .catch((error: any) => {
          dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
        })
      }}
      onCancel={() => {
        modal?.current?.hide();
      }}
    />
    )
  }

  const populateData = (data: any) => {
    setFormData({
      ...data.vehicle,
      reg_number: data.vehicle?.registration_number,
      trailer_reg_number: data.vehicle?.trailer_registration_number,
      driver: data.vehicle?.driver_id,
      vehicle_type: data.vehicle?.vehicle_type_id,
    });
    data?.drivers && setDrivers(data.drivers.map((driver: any) => ({label: `${driver.first_name} ${driver.last_name}` , value: driver.user_id})));
    data?.vehicle_types && setVehicleTypes(data.vehicle_types.map((vehicle_type: any) => ({label: vehicle_type.vehicle_type_name, value: vehicle_type.vehicle_type_id})));
  }

  useEffect(() => {
    isError &&  dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError]);

  useEffect(() => {
    isLoading && dispatch(setAlert({type: "progress"}));
  }, [isLoading])

  useEffect(() => {
    isSuccess &&  dispatch(clearAlert());
  }, [isSuccess]);

  useEffect(() => {
    data && populateData(data);
  }, [data])

  return (
    <>
      <div className="card scrollable y">
        <div className="card-body">
          <div>
            <Form ref={form}>
              <div className="row">
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[0]}
                    label="Vehicle Type"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    options={vehicleTypes}
                    value={vehicleTypes.filter(vehicleType => vehicleType.value === formData.vehicle_type)}
                    onChange={(value) => setFormData({
                      ...formData,
                      vehicle_type: value?.value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                     ref={inputs[1]}
                    label="Registration No"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={formData.reg_number || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      reg_number: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Manufacture"
                    block={true}
                    value={formData.manufacture || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      manufacture: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Model"
                    block={true}
                    value={formData.model || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      model: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Body Type"
                    block={true}
                    value={formData.body_type || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      body_type: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Color"
                    block={true}
                    value={formData.color || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      color: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Class"
                    block={true}
                    value={formData.class || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      class: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                  renderEndIcon={() => (
                    <span className="material-icons">calendar_today</span>
                  )}
                    label="Year of Manufacture"
                    block={true}
                    dateFormat="yyyy"
                    showYearPicker={true}
                    value={getValidYearOrNull(formData.year_of_manufacture)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        year_of_manufacture: formatYearForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Chassis No"
                    block={true}
                    value={formData.chassis_number || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      chassis_number: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Engine No"
                    block={true}
                    value={formData.engine_number || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      engine_number: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[5]}
                    label="Engine Capacity"
                    block={true}
                    value={numberFormat(formData.engine_capacity) || ""}
                    rules={[(value: any) => value ? inputValidation.integer(value) : true]}
                    onChange={(value) => setFormData({
                      ...formData,
                      engine_capacity: stripCommas(value)
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Fuel Used"
                    block={true}
                    value={formData.fuel_used || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      fuel_used: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[6]}
                    label="Number Of Axles"
                    block={true}
                    value={numberFormat(formData.number_of_axles) || ""}
                    rules={[(value: any) => value ? inputValidation.integer(value) : true]}
                    onChange={(value) => setFormData({
                      ...formData,
                      number_of_axles: stripCommas(value)
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Axle Distance"
                    block={true}
                    value={formData.axle_distance || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      axle_distance: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[7]}
                    label="Seating Capacity"
                    block={true}
                    value={numberFormat(formData.seating_capacity) || ""}
                    rules={[(value: any) => value ? inputValidation.integer(value) : true]}
                    onChange={(value) => setFormData({
                      ...formData,
                      seating_capacity: stripCommas(value)
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[8]}
                    label="Tare Weight"
                    block={true}
                    value={numberFormat(formData.tare_weight) || ""}
                    rules={[(value: any) => value ? inputValidation.number(value) : true]}
                    onChange={(value) => setFormData({
                      ...formData,
                      tare_weight: stripCommas(value)
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[9]}
                    label="Gross Weight"
                    block={true}
                    value={numberFormat(formData.gross_weight) || ""}
                    rules={[(value: any) => value ? inputValidation.number(value) : true]}
                    onChange={(value) => setFormData({
                      ...formData,
                      gross_weight: stripCommas(value)
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Imported From"
                    block={true}
                    value={formData.imported_from || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      imported_from: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                     ref={inputs[2]}
                    label="Km per Litre"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={numberFormat(formData.km_per_litre, 1) || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      km_per_litre: stripCommas(value)
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[3]}
                    label="Km per Oil Change"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={numberFormat(formData.km_per_oil) || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      km_per_oil: stripCommas(value)
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Trailer Registration No"
                    block={true}
                    value={formData.trailer_reg_number || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      trailer_reg_number: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Insurance Cover Note"
                    block={true}
                    value={formData.insurance_cover_note || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      insurance_cover_note: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                  renderEndIcon={() => (
                    <span className="material-icons">calendar_today</span>
                  )}
                    label="Insurance Issue Date"
                    block={true}
                    value={getValidDateOrNull(formData.insurance_issue_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        insurance_issue_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                  renderEndIcon={() => (
                    <span className="material-icons">calendar_today</span>
                  )}
                    label="Insurance Expire Date"
                    block={true}
                    value={getValidDateOrNull(formData.insurance_expire_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        insurance_expire_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="LATRA Licence No"
                    block={true}
                    value={formData.latra_licence_number || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      latra_licence_number: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                  renderEndIcon={() => (
                    <span className="material-icons">calendar_today</span>
                  )}
                    label="LATRA Issue Date"
                    block={true}
                    value={getValidDateOrNull(formData.latra_licence_issue_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        latra_licence_issue_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                  renderEndIcon={() => (
                    <span className="material-icons">calendar_today</span>
                  )}
                    label="LATRA Expire Date"
                    block={true}
                    value={getValidDateOrNull(formData.latra_licence_expire_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        latra_licence_expire_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Trailer Insurance Cover Note"
                    block={true}
                    value={formData.trailer_insurance_cover_note || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      trailer_insurance_cover_note: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                  renderEndIcon={() => (
                    <span className="material-icons">calendar_today</span>
                  )}
                    label="Trailer Insurance Issue Date"
                    block={true}
                    value={getValidDateOrNull(formData.trailer_insurance_issue_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        trailer_insurance_issue_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                  renderEndIcon={() => (
                    <span className="material-icons">calendar_today</span>
                  )}
                    label="Trailer Insurance Expire Date"
                    block={true}
                    value={getValidDateOrNull(formData.trailer_insurance_expire_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        trailer_insurance_expire_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Trailer LATRA Licence No"
                    block={true}
                    value={formData.trailer_latra_licence_number || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      trailer_latra_licence_number: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                  renderEndIcon={() => (
                    <span className="material-icons">calendar_today</span>
                  )}
                    label="Trailer LATRA Issue Date"
                    block={true}
                    value={getValidDateOrNull(formData.trailer_latra_licence_issue_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        trailer_latra_licence_issue_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <DateInput
                  renderEndIcon={() => (
                    <span className="material-icons">calendar_today</span>
                  )}
                    label="Trailer LATRA Expire Date"
                    block={true}
                    value={getValidDateOrNull(formData.trailer_latra_licence_expire_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        trailer_latra_licence_expire_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <SelectInput
                     ref={inputs[4]}
                    label="Driver"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    options={drivers}
                    value={drivers.filter(driver => driver.value === formData.driver)}
                    onChange={(value) => setFormData({
                      ...formData,
                      driver: value?.value
                    })}
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className="card-footer d-flex flex-row">
          <button
            className="btn bg-secondary"
            onClick={() => save()}
          >
            {props.vehicleID ? "Save" : "Add Vehicle"}
          </button>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default VehicleCreate;

import { useEffect, useMemo, useRef} from "react";
import { ConfirmationDialog, Modal } from "../../components/modal";
import { Table } from "../../components/table";
import CargoCreate from "./CargoCreate";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useDeleteCargoMutation, useGetCargosQuery } from "../../services/cargos/CargoService";
import { clearAlert, setAlert } from "../../states/AlertState";
import { setFilters as setFilter } from "../../states/FiltersState";
import { clearLocalFilters, setLocalFilters } from "../../states/FiltersLocalState"
import { getModulePermissions, getNoPermissionMessage } from "../../utils/helpers/helpers";
interface CargoListProps {
  customer?: any,
  useLocalFilters?: boolean,
  [rest: string]: any,
}

const CargoList = (props: CargoListProps) => {
  const user = useSelector((state: any) => state.userState);
  const setFilters: any = !props.useLocalFilters ? setFilter : setLocalFilters;
  const filters = useSelector((state: any) => !props.useLocalFilters ? state.filtersState : state.filtersLocalState);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const modal = useRef<any>();

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetCargosQuery({
    ...filters,
    customer: filters.customer || props.customer?.customer_id,
  });
  const [ deleteCargoRecord ] = useDeleteCargoMutation();

  const permission = useMemo(
    () => getModulePermissions("cargos", user?.permissions),
    [user]
  );

  const createNewCargo = (cargo: any = undefined) => {
    modal.current?.show(
      `${cargo ? "Edit Cargo Info" : "New Cargo"}`,
      (<CargoCreate
        cargoID={cargo?.cargo_id}
        modal={modal}
      />),
    );
  }

  const deleteCargo = (cargo: any) => {
    modal?.current?.show(
      "Delete Cargo",
      <ConfirmationDialog
        promptMessage="Are you sure want to delete this cargo"
        okayText="Delete"
        okayClassName="bg-danger"
        onOkay={() => {
          dispatch(setAlert({type: "progress", message: "Deleting..."}));
          modal?.current?.hide();
          deleteCargoRecord(cargo?.cargo_id)
          .unwrap()
          .then((response: any) => {
            dispatch(setAlert({type: "success", message: "Cargo successfully deleted"}));
          })
          .catch((error: any) => {
            dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
          })
        }}
        onCancel={() => {
          modal?.current?.hide();
        }}
      />
    )
  };

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    return () => {
      props.useLocalFilters && dispatch(clearLocalFilters());
    }
  }, [])

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div>
            <Table
              loading={isLoading}
              title="Cargos"
              onRowClick={(item) => {
                permission?.view ?
                navigate(`/cargos/details/${item.cargo_id}`) :
                dispatch(setAlert({type: "error", message: getNoPermissionMessage("view", "cargo")}))
              }}
              columns={[
                {label: "Name", name: "cargo_name"},
                {label: "Added Date", name: "created_at"},
                {
                  label: "Added By",
                  name: "name",
                  customRender: true,
                  valueGetter: (item) =>  `${item.first_name || ""} ${item.last_name || ""}`,
                },
                {label: "Modified Date", name: "modified_at"},
                {label: "Action", name: "action", customRender: true, webOnly: true},
              ]}
              items={data?.cargos || []}
              permission={permission}
              onSearch={(search) => dispatch(setFilters({search: search}))}
              pagination={{
                ...(data?.pagination || {}),
                onPageChange: (page) => dispatch(setFilters({page: page})),
                onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
              }}
              params={filters}
              actions={[
                ...(!props.customer ? [<button
                  className={`btn small with-icon bg-secondary ${!permission?.add && "disabled"}`}
                  onClick={() =>
                    permission?.add ?
                    createNewCargo() :
                    dispatch(setAlert({type: "error", message: getNoPermissionMessage("add", "cargo")}))
                  }
                  key={0}
                >
                  <span className="material-icons">add</span>
                  <span>Add</span>
                </button>] : [])
              ]}
              customRenders={[
                {
                  columnName: "index",
                  render: (item, index) => <span>{index + 1}</span>
                },
                {
                  columnName: "name",
                  render: (item) => <span>{`${item.first_name || ""} ${item.last_name || ""}`}</span>
                },
                {
                  columnName: "action",
                  render: (item) => (
                    <div className="d-flex flex-row align-center">
                      <span
                        className={`material-icons cursor-pointer primary-text ${!permission?.view && "disabled"}`}
                        onClick={(e) => {
                          e.stopPropagation(); 
                          permission?.view ?
                          navigate(`/cargos/details/${item.cargo_id}`) :
                          dispatch(setAlert({type: "error", message: getNoPermissionMessage("view", "cargo")}))
                        }}
                        title="View Details"
                      >
                        visibility
                      </span>
                      <span
                        className={`material-icons cursor-pointer ml-2 secondary-text ${!permission?.update && "disabled"}`}
                        onClick={(e) => {
                          e.stopPropagation(); 
                          permission?.update ?
                          createNewCargo(item):
                          dispatch(setAlert({type: "error", message: getNoPermissionMessage("edit", "cargo")}))
                        }}
                        title="Edit Info"
                      >
                        edit
                      </span>
                      <span
                        className={`material-icons cursor-pointer ml-2 danger-text ${!permission?.delete && "disabled"}`}
                        onClick={(e) => {
                          e.stopPropagation(); 
                          permission?.delete ?
                          deleteCargo(item):
                          dispatch(setAlert({type: "error", message: getNoPermissionMessage("delete", "cargo")}))
                        }}
                        title="Delete Record"
                      >
                        delete
                      </span>
                    </div>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default CargoList;

import { useEffect } from "react";
import { Router } from "../../components/router"
import { menuType } from "../../utils/types";
import CargosRoutes from "./CargosRoutes";
import { useDispatch, useSelector } from "react-redux";
import { clearFilters } from "../../states/FiltersState";
import { AuthenticateRoutes, hasPermission } from "../../utils/helpers/helpers";
import { AccessDenied } from "../../components/permission";
interface CargosProps {
  setMenu: (menu: menuType) => void,
  setShowSideMenu: (show: boolean) => void,
}

const Cargos = (props: CargosProps) => {
  const user = useSelector((state: any) => state.userState);
  const dispatch = useDispatch();

  useEffect(() => {
    props.setShowSideMenu(false);
    dispatch(clearFilters())
  }, [])

  return (
    <Router
      routes={AuthenticateRoutes(CargosRoutes, user.permissions, AccessDenied)}
    />
  );
}

export default Cargos;

import React, { Suspense, useEffect, useRef, useState } from 'react';
import CacheBuster from 'react-cache-buster';
// import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "../../assets/sass/app.scss";

import {
  Login,
  ForgotPassword,
  Account,
} from '.'
import ErrorBoundary from '../../components/Error/ErrorBoundary';
import { Loader } from '../../components/loader';
import packageJson from '../../../package.json';
// import { countError } from '../../states/ErrorState';

// function ErrorFallbackComponent({ error, resetErrorBoundary, ...rest }: any) {
//   const errorState = useSelector((state: any) => state.errorState);
//   const dispatch = useDispatch();
//   const ref = useRef<any>();

//    useEffect(() => {
//     ref.current && dispatch(countError());
//   }, [ref]);

//   return (
//     <div role="alert" ref={ref}>
//       <p>Something went wrong: {errorState}</p>
//       <pre style={{ color: "red" }}>{error.message}</pre>
//       <button onClick={resetErrorBoundary}>Try again</button>
//     </div>
//   );
// }

export default function App() {
  const [errorState, setErrorState] = useState(0);
  return (
    <CacheBuster
      currentVersion={packageJson.version}
      isEnabled={true}
      isVerboseMode={false}
      loadingComponent={
        <div className="preloader">
          <Loader />
        </div>
      }
      metaFileDirectory={"../../"}
    >
      {/* <ErrorBoundary FallbackComponent={ErrorFallbackComponent}> */}
      <ErrorBoundary>
        <Router>
          <Routes>
            <Route path={"/"} element={<Navigate to="/login" />} />
            <Route path={"/login"} element={<Login />} />
            <Route path={"/forgot-password/*"} element={<ForgotPassword />} />
            <Route path={"/*"} element={
              <Suspense
                fallback={
                  <div className="preloader">
                    <Loader />
                  </div>
                }
              >
                <Account />
              </Suspense>
            } />
          </Routes>
        </Router>
      </ErrorBoundary>
    </CacheBuster>
  );
}

import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  PatientService,
  EmployeeService,
} from './services/reception';

import { AppService } from './services/AppService';
import { ErrorState } from './states/ErrorState';
import AppState from './states/AppState';

export const store = configureStore({
  reducer: {
    ...AppState,
    [PatientService.reducerPath]: PatientService.reducer,
    [EmployeeService.reducerPath]: EmployeeService.reducer,
    [AppService.reducerPath]: AppService.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
    .concat(PatientService.middleware)
    .concat(EmployeeService.middleware)
    .concat(AppService.middleware)
});

setupListeners(store.dispatch);
import React, { useEffect, useMemo, useRef } from "react";
import { Form } from "../../../components/form";

import {
  TextField,
} from "../../../components/input";

import { getValidationRules } from "../../../utils/helpers/helpers";

const PasswordRecoverRequestVerify = (props: any) => {
  const inputValidation = getValidationRules();
  
  const form = useRef<any>();
  const inputs: any = useMemo(
    () => Array.from({length: 2}).map(() =>  React.createRef()),
    []
  )

  const { formData, setFormData } = props;

  useEffect(() => {
    props.setForm(form);
  }, [])
    
  return (
    <div>
      <Form ref={form}>
        <div className="row">
          <div className="col-12">
            <TextField
              ref={inputs[0]}
              label="Verification Code"
              block={true}
              rules={[inputValidation.required]}
              onChange={(value) => setFormData({
                ...formData,
                verification_code: value
              })}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};
      
export default PasswordRecoverRequestVerify;
import React, { useEffect, useMemo, useRef, useState } from "react";
import { ConfirmationDialog, Modal } from "../../../../components/modal";
import { Form } from "../../../../components/form";

import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../../states/AlertState";
import { useCreateGeneralConfigMutation } from "../../../../services/settings/general/GeneralConfigService";
import { Checkbox, TextArea } from "../../../../components/input";
interface InvoiceSetupProps {
  [rest: string]: any,
}

const InvoiceSetup = (props: InvoiceSetupProps) => {
  const user = useSelector((state: any) => state.userState);
  const dispatch = useDispatch();
  const form = useRef<any>();
  const modal = useRef<any>();
  const inputs: any = useMemo(
    () => Array.from({length: 8}).map(() =>  React.createRef()),
    []
  )

  const [formData, setFormData] = useState<any>({});
  const [ createConfig ] = useCreateGeneralConfigMutation();

  const save = () => {
    if(!form?.current?.validate()) {
      return dispatch(setAlert({type: "error", message: "Please fill all required fields."}));
    }
    modal?.current?.show(
      "Confirm Save",
      <ConfirmationDialog
      promptMessage="Are you sure want to save this info"
      onOkay={() => {
        dispatch(setAlert({type: "progress", message: "Processing..."}));
        modal?.current?.hide();
        let FD: any = {};
        Object.keys(formData).forEach((key) => {
         FD[key] = typeof formData[key]?.trim === "function" ? formData[key]?.trim() : formData[key];
        });
        let FDT = {configs: FD}
        createConfig({...FDT})
        .unwrap()
        .then((response: any) => {
          dispatch(setAlert({type: "success", message: "Successfully saved", unreplaceable: true}));
        })
        .catch((error: any) => {
          dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
        })
      }}
      onCancel={() => {
        modal?.current?.hide();
      }}
    />
    )
  }

  useEffect(() => {
    let config = user?.configuration;
    config && setFormData({
      invoice_notes: config.invoice_notes,
      invoice_terms: config.invoice_terms,
      invoice_show_client_signature: config.invoice_show_client_signature,
      invoice_show_business_signature: config.invoice_show_business_signature,
      invoice_gratefully_message: config.invoice_gratefully_message,
    })
  }, [user])

  return (
    <>
      <div className="card">
        <div className="card-header bg-white">Invoice Setup</div>
        <div className="card-body">
          <div>
            <Form ref={form}>
              <div className="row">
                <div className="col-12">
                  <TextArea
                    ref={inputs[0]}
                    label="Invoice Notes"
                    block={true}
                    rows={6}
                    value={formData.invoice_notes || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      invoice_notes: value
                    })}
                  />
                </div>
                <div className="col-12">
                  <TextArea
                    ref={inputs[1]}
                    label="Invoice Terms"
                    block={true}
                    rows={6}
                    value={formData.invoice_terms || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      invoice_terms: value
                    })}
                  />
                </div>
                <div className="col-12">
                  <TextArea
                    label="Gratefully Message"
                    block={true}
                    rows={3}
                    value={formData.invoice_gratefully_message || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      invoice_gratefully_message: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <Checkbox
                    label="Show Client Signature"
                    value={formData.invoice_gratefully_message || ""}
                    checked={formData.invoice_show_client_signature === "yes"}
                    onChange={(e: any) => setFormData({
                      ...formData,
                      invoice_show_client_signature: e.target?.checked ? "yes" : "no"
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <Checkbox
                    label="Show Business Signature"
                    value={formData.invoice_gratefully_message || ""}
                    checked={formData.invoice_show_business_signature === "yes"}
                    onChange={(e: any) => setFormData({
                      ...formData,
                      invoice_show_business_signature: e.target?.checked ? "yes" : "no"
                    })}
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className="card-footer d-flex flex-row">
          <button
            className="btn bg-secondary"
            onClick={() => save()}
          >
            Save
          </button>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default InvoiceSetup;

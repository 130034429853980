import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Sidebar, Menu, MenuItem, useProSidebar, SubMenu } from "react-pro-sidebar";
import "./SideBar.scss";

interface SideBarProps {
  menuTitle?: string,
  menuIcon?: string,
  items: {
    title: string,
    url?: string,
    hoverBackground?: boolean,
    startIcon?: string,
    endIcon?: string,
    disabled?: boolean,
    onClick?: () => void,
    subMenu?: {
      title: string,
      url?: string,
      startIcon?: string,
      endIcon?: string,
      disabled?: boolean,
      onClick?: () => void,
    }[],
  }[],
  depthLevel?: number,
}

const SideBar = (props: SideBarProps) => {
  const location = useLocation();
  const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

  const toggle = () => {
    toggleSidebar();
    if (toggled) {
      console.log(true);
      collapseSidebar();
    } else {
      console.log(false);
      collapseSidebar();
    }
  };

  useEffect(() => {
    window.innerWidth < 960 && collapseSidebar();
  }, [])

  return (
      <Sidebar
        // breakPoint="sm"
        transitionDuration={800}
        backgroundColor=""
        rtl={false}
        className="bg-secondary side-bar"
      >
        {/* {!broken && ( */}
        <Menu>
          <MenuItem
            icon={props.menuIcon &&
              <span 
                className="material-icons"
                onClick={() => {
                  collapseSidebar();
                }}
              >
                {props.menuIcon}
              </span>}
            className="menu-item menu-header bg-primary"
          >
            {props.menuTitle && <>
              {" "}<h2>{props.menuTitle}</h2>
            </>}
          </MenuItem>
          
          {props.items.map((item, index) => {
            return item.subMenu ?
            <SubMenu
              key={index}
              icon={item.startIcon && <span className="material-icons">{item.startIcon}</span>} label={item.title}
              className={`menu-item sub-menu ${item.disabled && "disabled"}`}
              title={item.title}
              onClick={item.onClick ?
                (e) => {
                  e.preventDefault();
                  item.onClick && item.onClick()
                } :
                undefined
              }
            >
              {item.subMenu.map((subItem, index) => {
                return <MenuItem
                  key={index}
                  icon={subItem.startIcon && <span className="material-icons">{subItem.startIcon}</span>}
                  component={subItem.url && <Link to={subItem.url} className={`link  ${subItem.disabled && "disabled"}`} />}
                  className={`
                    sub-menu-item bg-primary
                    ${location.pathname === subItem.url && "active"}
                  `}
                  title={subItem.title}
                  onClick={
                    subItem.onClick ?
                    (e) => {
                      e.preventDefault();
                      subItem.onClick && subItem.onClick()
                    } :
                    undefined
                  }
                >
                  {subItem.title}
                </MenuItem>
              })}
            </SubMenu> :
            <MenuItem
              key={index}
              icon={item.startIcon && <span className="material-icons">{item.startIcon}</span>}
              component={item.url && <Link to={item.url} className={`link ${item.disabled && "disabled"}`} />}
              className={`menu-item ${location.pathname === item.url && "active"}`}
              title={item.title}
              onClick={item.onClick ?
                (e) => {
                  e.preventDefault();
                  item.onClick && item.onClick()
                } :
                undefined
              }
            >
              {item.title}
            </MenuItem>
          })}
        </Menu>
        {/* )} */}
      </Sidebar>
  );
}

export default SideBar;

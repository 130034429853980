import React from "react";
import { capitalize, formatDateTimeForDb } from './../../utils/helpers/helpers';
import { useSelector } from "react-redux";
import { appName } from "../../utils/constants/constants";

export default function ReportFooter(props: any) {
  const user = useSelector((state: any) => state.userState);

  return (
    <div className="report-footer d-flex align-center justify-space-between mt-3 text-small">
      <div className="black-op6-text">
        Printed by {capitalize(user?.first_name || "")} {capitalize(user?.last_name || "")} at {formatDateTimeForDb(new Date())}
      </div>
      <div className="black-op6-text">Powered by {appName}</div>
    </div>
  );
}

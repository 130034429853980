import { useEffect, useMemo, useRef, useState } from "react";
import { ConfirmationDialog, Modal } from "../../components/modal";
import { Table } from "../../components/table";
import CustomerCreate from "./CustomerCreate";

import {
  SelectInput,
} from "../../components/input";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useDeleteCustomerMutation, useGetCustomersQuery } from "../../services/customers/CustomerService";
import { clearAlert, setAlert } from "../../states/AlertState";
import { setFilters as setFilter } from "../../states/FiltersState";
import { clearLocalFilters, setLocalFilters } from "../../states/FiltersLocalState"
import { getModulePermissions, getNoPermissionMessage } from "../../utils/helpers/helpers";
interface CustomerListProps {
  cargo?: any,
  useLocalFilters?: boolean,
  [rest: string]: any,
}

const CustomerList = (props: CustomerListProps) => {
  const user = useSelector((state: any) => state.userState);
  const setFilters: any = !props.useLocalFilters ? setFilter : setLocalFilters;
  const filters = useSelector((state: any) => !props.useLocalFilters ? state.filtersState : state.filtersLocalState);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const modal = useRef<any>();

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetCustomersQuery({
    ...filters,
    cargo: filters.cargo || props.cargo?.cargo_id,
  });
  const [ deleteCustomerRecord ] = useDeleteCustomerMutation();

  const [customerStatus, setCustomerStatus] = useState([
    {label: "Active", value: "1"},
    {label: "Inactive", value: "0"},
  ]);

  const permission = useMemo(
    () => getModulePermissions("customers", user?.permissions),
    [user]
  );

  const createNewCustomer = (customer: any = undefined) => {
    modal.current?.show(
      `${customer ? "Edit Customer Info" : "New Customer"}`,
      (<CustomerCreate
        customerID={customer?.customer_id}
        modal={modal}
      />),
    );
  }

  const deleteCustomer = (customer: any) => {
    modal?.current?.show(
      "Delete Customer",
      <ConfirmationDialog
        promptMessage="Are you sure want to delete this customer"
        okayText="Delete"
        okayClassName="bg-danger"
        onOkay={() => {
          dispatch(setAlert({type: "progress", message: "Deleting..."}));
          modal?.current?.hide();
          deleteCustomerRecord(customer?.customer_id)
          .unwrap()
          .then((response: any) => {
            dispatch(setAlert({type: "success", message: "Customer successfully deleted"}));
          })
          .catch((error: any) => {
            dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
          })
        }}
        onCancel={() => {
          modal?.current?.hide();
        }}
      />
    )
  };

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    return () => {
      props.useLocalFilters && dispatch(clearLocalFilters());
    }
  }, [])

  return (
    <>
      <div className="row justify-center shadow bg-white p-2 mb-1">
        <div className="col-12 col-md-4 col-sm-6">
          <SelectInput
            placeholder="Status"
            block={true}
            clearable
            options={customerStatus}
            value={customerStatus.find(status => status.value === filters.status)}
            onChange={(value) => dispatch(setFilters({
              status: value?.value,
            }))}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div>
            <Table
              loading={isLoading}
              title="Customers"
              onRowClick={(item) => {
                permission?.view ?
                navigate(`/customers/details/${item.customer_id}`) :
                dispatch(setAlert({type: "error", message: getNoPermissionMessage("view", "customer")}))
              }}
              columns={[
                {
                  label: "Name",
                  name: "first_name",
                },
                {label: "Phone No", name: "phone_number"},
                {label: "Email", name: "email"},
                {label: "Address", name: "physical_address"},
                {
                  label: "Status",
                  name: "is_active",
                  customRender: true,
                  valueGetter: (item) =>  item.is_active === "1" ? "Active" : "Inactive",
                },
                {label: "Action", name: "action", customRender: true, webOnly: true},
              ]}
              items={data?.customers || []}
              permission={permission}
              onSearch={(search) => dispatch(setFilters({search: search}))}
              pagination={{
                ...(data?.pagination || {}),
                onPageChange: (page) => dispatch(setFilters({page: page})),
                onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
              }}
              params={filters}
              actions={[
                ...(!props.cargo ? [<button
                  className={`btn small with-icon bg-secondary ${!permission?.add && "disabled"}`}
                  onClick={() =>
                    permission?.add ?
                    createNewCustomer() :
                    dispatch(setAlert({type: "error", message: getNoPermissionMessage("add", "customer")}))
                  }
                  key={0}
                >
                  <span className="material-icons">add</span>
                  <span>Add</span>
                </button>] : [])
              ]}
              customRenders={[
                {
                  columnName: "index",
                  render: (item, index) => <span>{index + 1}</span>
                },
                {
                  columnName: "is_active",
                  render: (item) => {
                    let customer_status = item.is_active == 1 ? "Active" : "Inactive";
                    let customer_status_class = item.is_active == 1 ? "bg-success" : "bg-danger";
                    return <span className={`badge ${customer_status_class} text-xsmall`}>{customer_status}</span>
                  }
                },
                {
                  columnName: "action",
                  render: (item) => (
                    <div className="d-flex flex-row align-center">
                      <span
                        className={`material-icons cursor-pointer primary-text ${!permission?.view && "disabled"}`}
                        onClick={(e) => {
                          e.stopPropagation(); 
                          permission?.view ?
                          navigate(`/customers/details/${item.customer_id}`) :
                          dispatch(setAlert({type: "error", message: getNoPermissionMessage("view", "customer")}))
                        }}
                        title="View Details"
                      >
                        visibility
                      </span>
                      <span
                        className={`material-icons cursor-pointer ml-2 secondary-text ${!permission?.update && "disabled"}`}
                        onClick={(e) => {
                          e.stopPropagation(); 
                          permission?.update ?
                          createNewCustomer(item):
                          dispatch(setAlert({type: "error", message: getNoPermissionMessage("edit", "customer")}))
                        }}
                        title="Edit Info"
                      >
                        edit
                      </span>
                      <span
                        className={`material-icons cursor-pointer ml-2 danger-text ${!permission?.delete && "disabled"}`}
                        onClick={(e) => {
                          e.stopPropagation(); 
                          permission?.delete ?
                          deleteCustomer(item):
                          dispatch(setAlert({type: "error", message: getNoPermissionMessage("delete", "customer")}))
                        }}
                        title="Delete Record"
                      >
                        delete
                      </span>
                    </div>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default CustomerList;

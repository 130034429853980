import React, { forwardRef, useCallback, useRef } from "react";
import { utils, writeFile } from "xlsx";
import { decimalFormat, stripCommas } from './../../utils/helpers/helpers';

interface SpreadsheetReportProps {
  title?: any,
  format?: any,
  columns?: any,
  items?: any,
  className?: any,
  buttonName?: any,
  disabled?: any,
  columnGroups?: any,
  [rest: string]: any,
}

const SpreadsheetReport = forwardRef((props: SpreadsheetReportProps, ref) => {
  const buttonRef = useRef<any>();

  const generate = useCallback(() => {
    // Flatten objects
    const rows = props.items?.map(
      (r: any, i: number) => {
        let e: any = {};
        props. columns?.forEach((c: any) => {
          e[c.name] = stripCommas(
            c.valueGetter ? c.valueGetter(r, i) : r[c.name]
          );
        });

        return e;
      },
      [props.items]
    );

    // Generate worksheet and workbook
    const worksheet = utils.json_to_sheet(rows);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet 1");

    // Fix headers

    if (props.columnGroups && props.columnGroups?.length) {
      utils.sheet_add_aoa(worksheet, [props.columnGroups.map((c: any) => c.label)], {
        origin: "A1",
      });
      utils.sheet_add_aoa(worksheet, [props.columns?.map((c: any) => c.label)], {
        origin: "A2",
      });
    } else {
      utils.sheet_add_aoa(worksheet, [props.columns?.map((c: any) => c.label)], {
        origin: "A1",
      });
    }

    /* Create a file */
    writeFile(workbook, `${props.title}.${props.format}`);
  }, [props.title, props.format, props.columns, props.items]);

  const clickButton = () => {
    buttonRef.current.click();
  };

  React.useImperativeHandle(ref, () => ({
    click: () => clickButton(),
  }));

  return (
    <button
      type="button"
      disabled={props.disabled}
      className={`btn small ${props.className || ""}`}
      onClick={(e) => {
        props.className?.indexOf("disabled") === -1 ?
        generate() :
        props.onClick && props.onClick();
      }}
      ref={buttonRef}
    >
      {props.buttonName ? props.buttonName : props.format === "csv" ? "CSV" : "Excel"}
    </button>
  );
})

export default SpreadsheetReport;

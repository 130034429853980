import VehicleDetails from './VehicleDetails';
import VehicleList from './VehicleList';

const VehiclesRoutes = [
  {
    url: "/",
    component: VehicleList,
    permission: "vehicles",
    props: {}
  },
  {
    url: "/details/:vehicleID",
    component: VehicleDetails,
    permission: "vehicles_view",
    props: {}
  },
]

export default VehiclesRoutes;
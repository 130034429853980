import { useEffect, useState } from "react";
import { menuType } from "../../../utils/types";
import { SideBar } from "../../menu";
import { Router } from "../../router";
import "./Page.scss";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../states/AlertState";
import { getNoPermissionMessage } from "../../../utils/helpers/helpers";

interface PageProps {
  homeUrl?: string,
  hideHomeMenu?: boolean;
  menu?: menuType,
  routes: {
    url: string,
    component: any,
    props?: any,
  }[],
}

const Page = (props: PageProps) => {
  const dispatch = useDispatch();
  const [ menu, setMenu ] = useState({items: []});
  const [ fullMenu, setFullMenu ] = useState<menuType>({items: []});
  const [ showSideMenu , setShowSideMenu  ] = useState(false);
  const [ routes, setRoutes ] = useState([]);

  const onNoAccess = (action?: string, item?: string, prefixModule?: string, sufix?: string) => {
		dispatch(setAlert({type: "error", message: getNoPermissionMessage(action, item, prefixModule, sufix)}));
	}

  useEffect(() => {
    const homeMenuItem: any = (!props.hideHomeMenu && props.homeUrl) ? [{url: props.homeUrl, title: "Home" , startIcon: "home"}] : [];
    const menuItems = [
      ...homeMenuItem,
      ...menu.items
    ];
    setFullMenu({
      ...menu,
      items: menuItems,
      menuIcon: "menu"
    });
  }, [menu])

  useEffect(() => {
    const routes: any = props.routes.map((route => {
      route.props = {
        ...route.props || {},
        setMenu,
        setShowSideMenu,
        onNoAccess
      }
      return route;
    }))
    setRoutes(routes);
  }, [props.routes])

  return (
    <div
      className="page"
    >
      {showSideMenu &&
      <SideBar
        {...(props.menu || fullMenu)}
      />}
      <div className="page-content">
        <Router
          routes={routes}
        />
      </div>
    </div>
  );
}

export default Page;

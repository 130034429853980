import TripDetails from './TripDetails';
import TripList from './TripList';

const TripsRoutes = [
  {
    url: "/",
    component: TripList,
    permission: "trips",
    props: {}
  },
  {
    url: "/details/:tripID",
    component: TripDetails,
    permission: "trips_view",
    props: {}
  },
]

export default TripsRoutes;
import { AppService } from '../AppService';

export const PaymentTransactionService = AppService.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentTransactions: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/payment-transactions",
            method: "GET",
            params: params,
        }),
        providesTags: ['App', 'PaymentTransactions', 'GetPaymentTransactions'],
    }),
    getPaymentTransaction: builder.query({
        query: (id) => ({
            url: `/payment-transactions/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'PaymentTransactions', 'GetPaymentTransaction'],
    }),
    getPaymentTransactionToUpdate: builder.query({
        query: (id) => ({
            url: `/payment-transactions/edit/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'PaymentTransactions', 'GetPaymentTransactionToUpdate'],
    }),
    getPaymentTripDetails: builder.query({
        query: (id) => ({
            url: `/payment-transactions/payment-trip-details/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'PaymentTransactions', 'GetPaymentTripDetails'],
    }),
    createPaymentTransaction: builder.mutation({
        query: (data = {}) => ({
            url: `/payment-transactions/save${data.id ? ("/" + data.id) : ""}`,
            method: "POST",
            data: data,
        }),
       invalidatesTags: ['PaymentTransactions', 'GetTrips'],
    }),
    deletePaymentTransaction: builder.mutation({
        query: (id) => ({
            url: `/payment-transactions/${id}`,
            method: "DELETE",
        }),
       invalidatesTags: ['GetPaymentTransactions', 'GetTrips'],
    }),
  }),
});

export const {
    useGetPaymentTransactionsQuery,
    useGetPaymentTransactionQuery,
    useGetPaymentTransactionToUpdateQuery,
    useGetPaymentTripDetailsQuery,
    useCreatePaymentTransactionMutation,
    // useUpdatePaymentTransactionMutation,
    useDeletePaymentTransactionMutation,
} = PaymentTransactionService;

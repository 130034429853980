import { ReactNode } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

interface RouteProps {
  routes: {
    url: string,
    component?: any,
    redirect?: string,
    props?: any,
  }[], 
}

const Router = (props: RouteProps) => {
  return (
    <Routes>
      {props.routes?.map((route, index) =>
        <Route
          key={index}
          path={route.url}
          element={
            !route.redirect ?
            <route.component
            {...route.props}
            /> :
            <Navigate to={`${route.redirect}`} />
          }
        />
      )}
    </Routes>
  );
}

export default Router;

import { useEffect, useRef, useState } from "react";
import { ConfirmationDialog, Modal } from "../../../../components/modal";
import { Table } from "../../../../components/table";
import ExpenseCategoryCreate from "./ExpenseCategoryCreate";

import { useDispatch, useSelector } from "react-redux";
import { useDeleteExpenseCategoryMutation, useGetExpenseCategoriesQuery } from "../../../../services/settings/expenses/expense-categories/ExpenseCategoryService";
import { clearAlert, setAlert } from "../../../../states/AlertState";
import { clearFilters, setFilters } from "../../../../states/FiltersState";
interface ExpenseCategoryListProps {
  exportTitle?: string,
  useLocalFilters?: boolean,
  [rest: string]: any,
}

const ExpenseCategoryList = (props: ExpenseCategoryListProps) => {
  const filters = useSelector((state: any) => state.filtersState);
  const dispatch = useDispatch();
  const modal = useRef<any>();

  const { data, isLoading, isSuccess, isFetching, status, isError, error } = useGetExpenseCategoriesQuery(filters);
  const [ deleteExpenseCategoryRecord ] = useDeleteExpenseCategoryMutation();

  const createNewExpenseCategory = (expenseCategory: any = undefined) => {
    modal.current?.show(
      `${expenseCategory ? "Edit Expense Category Info" : "New Expense Category"}`,
      (<ExpenseCategoryCreate
        expenseCategoryID={expenseCategory?.expense_category_id}
        modal={modal}
      />),
    );
  }

  const deleteExpenseCategory = (expenseCategory: any) => {
    modal?.current?.show(
      "Delete ExpenseCategory",
      <ConfirmationDialog
        promptMessage="Are you sure want to delete this expense category"
        okayText="Delete"
        okayClassName="bg-danger"
        onOkay={() => {
          dispatch(setAlert({type: "progress", message: "Deleting..."}));
          modal?.current?.hide();
          deleteExpenseCategoryRecord(expenseCategory?.expense_category_id)
          .unwrap()
          .then((response: any) => {
            dispatch(setAlert({type: "success", message: "Expense category successfully deleted"}));
          })
          .catch((error: any) => {
            dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
          })
        }}
        onCancel={() => {
          modal?.current?.hide();
        }}
      />
    )
  };

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    return () => {
      dispatch(clearFilters())
    }
  }, [])

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div>
            <Table
              loading={isLoading}
              title="Expense Categories"
              exportTitle={props.exportTitle}
              columns={[
                {label: "Category Name", name: "expense_category_name"},
                {label: "Description", name: "description"},
                {label: "Action", name: "action", customRender: true, webOnly: true},
              ]}
              items={data?.expense_categories || []}
              onSearch={(search) => dispatch(setFilters({search: search}))}
              pagination={{
                ...(data?.pagination || {}),
                onPageChange: (page) => dispatch(setFilters({page: page})),
                onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
              }}
              params={filters}
              actions={[
                <button
                  className="btn small with-icon bg-secondary"
                  onClick={() => createNewExpenseCategory()}
                  key={0}
                >
                  <span className="material-icons">add</span>
                  <span>Add</span>
                </button>,
              ]}
              customRenders={[
                {
                  columnName: "index",
                  render: (item, index) => <span>{index + (data?.pagination.page_start || 1)}</span>
                },
                {
                  columnName: "action",
                  render: (item) => (
                    !([1,2,3,4,5].includes(parseInt(item.expense_category_id))) ?
                    <div className="d-flex flex-row align-center">
                      <span
                        className="material-icons secondary-text cursor-pointer ml-2"
                        onClick={() => createNewExpenseCategory(item)}
                        title="Edit Info"
                      >
                        edit
                      </span>
                      <span
                        className="material-icons danger-text cursor-pointer ml-2"
                        onClick={() => deleteExpenseCategory(item)}
                        title="Delete Record"
                      >
                        delete
                      </span>
                    </div> :
                    <span className="grey-text">N/A</span>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default ExpenseCategoryList;

import { useDispatch, useSelector } from "react-redux";
import { NavButton } from "../../../components/menu";
import "./Home.scss";
import { capitalize, getNoPermissionMessage } from "../../../utils/helpers/helpers";
import { setAlert } from "../../../states/AlertState";

const Home = () => {
  const user = useSelector((state: any) => state.userState);
  const dispatch = useDispatch();
  
  const onNoAccess = (module: any) => {
    dispatch(setAlert({type: "error", message: getNoPermissionMessage("access", module.module)}));
  }
  
  return (
    <>
    <div className="home-contents">
    <div className="home-menu shadow mb-5">
    {user?.modules?.map((module: any, i: number) => {
      let authorized = user.permissions?.find((permission: any) => permission.permission === module.module);
      return <NavButton
      key={i}
      title={capitalize(module.module?.replace(/_/g, " "))}
      url={`/${module.module?.replace(/_/g, "-")}`}
      icon={module.icon}
      onClick={!authorized ? () => onNoAccess(module) : undefined}
      disabled={!authorized}
      />
    })}
    </div>
    </div>
    </>
  );
};

export default Home;
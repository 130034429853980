import { ReactNode } from "react"
import Tabs from "../tabs/Tabs"
import ItemDetailsSummary from "./ItemDetailsSummary"
import ItemDetailsBasic from "./ItemDetailsBasic"

interface ItemDetailsProps {
    itemName?: string,
    details?: {
        label: string,
        value?: string,
        isSectionTitle?: boolean,
        className?: string,
    }[],
    detailsEditAction?: ReactNode,
    summary: {
        title: string,
        summary: {
            value: string, 
            className?: string
        }[],
        details: {
            label: string,
            value: any
        }[]
    },
    tabs: ReactNode[],
    permission?: any,
}

const ItemDetails = (props: ItemDetailsProps) => {
    const deviceWidth = window.innerWidth
    return (
        <div className="row justify-space-between">
            <div className="col-12 col-md-3">
                <ItemDetailsSummary
                    title= {props.summary?.title}
                    summary={props.summary?.summary}
                    details={props.summary?.details}
                />
            </div>
            <div className="col-12 col-md-9">
                <div className={`${deviceWidth > 960 && "card shadow mb-5"}`}>
                    <div className={`${deviceWidth > 960 && "card-body"}`}>
                        <Tabs className="outlined">
                            {[
                                ...(props.details ? [<ItemDetailsBasic
                                    key={1}
                                    permission={props.permission}
                                    title="Basic Info"
                                    itemName={props.itemName}
                                    items={props.details}
                                    editAction={props.detailsEditAction}
                                />] : []),
                                ...(props?.tabs || [])
                            ]}
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ItemDetails;
import React, { useRef, useState } from "react";
import { TextArea } from "../input";

interface PromptDialogueProps {
  promptMessage: string,
  defaultValue?: any,
  rules?: any[],
  onOkay?: (value: any) => void,
  onCancel?: () => void,
}

const PromptDialogue = (props: PromptDialogueProps) => {
  const inputRef: any = useRef();
  const [value, setValue] = useState(props.defaultValue || "");

  const onSubmit = () => {
    if (inputRef.current.validate()) {
      props.onOkay && props.onOkay(value);
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <span className="material-icons">info</span> &nbsp;{" "}
        {props.promptMessage}
        <div className="row block mt-2">
          <TextArea
            ref={inputRef}
            placeholder="Response..."
            block={true}
            defaultValue={value}
            rows={5}
            rules={props.rules || []}
            onChange={(value) => setValue(value)}
          />
        </div>
      </div>
      <div className="card-footer d-flex flex-row justify-center">
        <button
          type="button"
          className="btn outlined mr-1"
          onClick={() => {
            props.onCancel && props.onCancel();
          }}
        >
          Cancel
        </button>
        <button
          className="btn primary"
          onClick={() => {
            onSubmit();
          }}
        >
          OK
        </button>
      </div>
    </div>
  );
}

export default PromptDialogue;

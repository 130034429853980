import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Document,
  Font,
  Image,
  Page,
  PDFDownloadLink,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

// import fontRegular from "../../fonts/Custom-Regular.ttf";
// import fontItalic from "../../fonts/Custom-Italic.ttf";
// import fontBold from "../../fonts/Custom-Bold.ttf";

import Header from "../../../components/pdf/Header";
import Footer from "../../../components/pdf/Footer";
import PDFDocTable from "../../../components/pdf/PDFDocTable";

import { appName } from "../../../utils/constants/constants";
import { useGetPaymentTransactionQuery } from "../../../services/accounting/PaymentTransactionService";
import { clearAlert, setAlert } from "../../../states/AlertState";
import { capitalize, formatDateForDb, numberFormat, padStart } from "../../../utils/helpers/helpers";
import PDFAddressDetails from "../../../components/pdf/PDFAddressDetails";
import PDFSignatureField from "../../../components/pdf/PDFSignatureField";

Font.register({
  family: "Custom",
  fonts: [
    // { src: fontRegular },
    // { src: fontItalic, fontStyle: "italic" },
    // { src: fontBold, fontWeight: 700 },
  ],
});

export const Receipt = (props: any) => {
  const { data, user } = props || {};
  const config = props.user?.configuration || {};

  let {
    freight_rate,
    discount,
    discount_type,
    charges,
    total_charges,
    total_amount,
    total_discount,
    payment_amount,
    total_payment,
    payment_amount_due,
    receipt_number,
    receipt_issue_date,
    receipt_due_date,
    trip_number,
    vehicle_registration_number,
    driver_name,
  } = data;
  
  const summary = [
    {
      label: "Subtotal",
      value: numberFormat(freight_rate),
      className: "text-bold"
    },
    {
      label: `Discount${discount_type === "percent" ? `(${numberFormat(discount || 0)}%)` : ""}`,
      value: numberFormat(total_discount),
    },
    ...(
      charges
      ?.filter((charge: any) => charge.charge_category === "tax")
      ?.map((charge: any) => ({
        label: `${charge.charge_name}${charge.charge_type === "percent" ? `(${numberFormat(charge.charge_amount || 0)}%)` : ""} - Tax`,
        value: numberFormat(charge.charge_type === "percent" ? numberFormat(freight_rate * (charge.charge_amount / 100)) : numberFormat(charge.charge_amount)),
      })) || []
    ),
    ...(
      charges
      ?.filter((charge: any) => charge.charge_category === "other")
      ?.map((charge: any) => ({
        label: `${charge.charge_name}${charge.charge_type === "percent" ? `(${numberFormat(charge.charge_amount || 0)}%)` : ""} - Charge`,
        value: numberFormat(charge.charge_type === "percent" ? numberFormat(freight_rate * (charge.charge_amount / 100)) : numberFormat(charge.charge_amount)),
      })) || []
    ),
    {
      label: "Total",
      value: numberFormat(payment_amount),
      className: "text-bold"
    },
    {
      label: "Amount Paid",
      value: numberFormat(total_payment),
    },
    {
      label: `${payment_amount_due > 0 ? "Amount Due" : "Change Due"}, TZS`,
      name: "amount_due",
      value: numberFormat(Math.abs(payment_amount_due)),
    },
  ];

  const columns =[
    {
      label: "Description",
      name: "description",
      getValue: (item: any) => `Shipping ${item.cargos} cargo from ${item.start_location} to ${item.end_location}`,
    },
    {
      label: "Rate, TZS",
      name: "freight_rate",
      getValue: (item: any) => numberFormat(item.freight_rate),
    },
    {
      label: "Qty/Weight, Kg",
      name: "quantity",
      getValue: (item: any) => numberFormat(item.cargo_weight),
    },
    {
      label: "Amount, TZS",
      name: "amount",
      getValue: (item: any) => numberFormat(item.freight_rate * 1),
    },
  ];

  const docDetails = [
    {
      label: "Receipt#:",
      value: padStart(receipt_number, 4, '0'),
    },
    {
      label: "Receipt Date:",
      value: formatDateForDb(receipt_issue_date),
    },
    {
      label: "Trip#:",
      value: padStart(trip_number, 4, '0'),
    },
  ]

  
  return (
    <Document title="Receipt" creator={appName} producer={appName}>
    <Page
    size="A4"
    style={{
      width: "100%",
      backgroundColor: "white",
      paddingHorizontal: 24,
      paddingTop: 24,
      paddingBottom: 35,
    }}
    orientation="portrait"
    >
    <Header
      title="Receipt"
      titleTextStyle={styles.text}
      contactsTextStyle={styles.text}
      user={user}
      showTIN
    />
    <View style={[styles.row, {justifyContent: "space-between", marginBottom: 24}]}>
      <View style={[{width: `${3/12 * 100}%`}]}>
        <PDFAddressDetails data={data} title="Customer" showTIN />
      </View>
      <View style={[{width: `${4/12 * 100}%`}]}>
        <PDFDocTable
          columns={[
            {name: "label"},
            {name: "value", getValue: (item: any) => item.value},
          ]}
          items={docDetails}
          noTitle
          hideIndex
          getRowStyle = {(item: any, index: any) => ({textAlign: "right"})}
        />
      </View>
    </View>

    <PDFDocTable
      // title="Items"
      columns={columns}
      items={data ? [data] : []}
      hideIndex
    />

    <View style={[styles.row, {justifyContent: "flex-end", marginTop: 12}]}>
      <View style={[{width: `${5/12 * 100}%`}]}>
        <PDFDocTable
          columns={[
            {name: "label"},
            {
              name: "value",
              getValue: (item: any) => item.value,
              getStyle: (item: any, index: any) => ({textAlign: "right"})
            },
          ]}
          items={summary}
          noTitle
          hideIndex
          // getRowStyle = {(item: any, index: any) => (item.name === "amount_due" && {fontWeight: "bold", color: "red"})}
        />
      </View>
    </View>
    
    {(config?.receipt_notes || config?.receipt_terms) &&
    <View style={[styles.row, styles.text, {justifyContent: "space-between", marginTop: 36}]}>
      {config?.receipt_notes &&
      <View style={[{width: `${5/12 * 100}%`}]}>
        <Text style={[{fontSize: 10, fontWeight: "bold", marginBottom: 3}]}>Notes:</Text>
        <Text style={[]}>{config?.receipt_notes}</Text>
      </View>}
      {config?.receipt_terms &&
      <View style={[{width: `${5/12 * 100}%`}]}>
        <Text style={[{fontSize: 10, fontWeight: "bold", marginBottom: 3}]}>Terms:</Text>
        <Text style={[]}>{config?.receipt_terms}</Text>
      </View>}
    </View>}

    {(config?.receipt_show_client_signature === "yes" || config?.receipt_show_business_signature === "yes") &&
    <View style={[styles.row, styles.text, {justifyContent: "space-between", marginTop: 48}]}>
      {config?.receipt_show_client_signature === "yes" &&
      <View style={[{width: `${5/12 * 100}%`}]}>
        <PDFSignatureField label="Client Signature" />
      </View>}
      {config?.receipt_show_business_signature === "yes" &&
      <View style={[{width: `${5/12 * 100}%`}]}>
        <PDFSignatureField label="Business Signature" />
      </View>}
    </View>}

    {config?.receipt_gratefully_message &&
    <View style={[styles.row, styles.text, {justifyContent: "center", marginTop: 60}]}>
      <View style={[{width: `${5/12 * 100}%`}]}>
        <Text style={[{textAlign: "center"}]}>{config?.receipt_gratefully_message}</Text>
      </View>
    </View>}
    
    <Footer
      style={styles.text}
      user={user}
      render={({ pageNumber, totalPages }: any) =>
        `${pageNumber} / ${totalPages}`
      }
    />
  </Page>
  </Document>
);
}

const ReceiptPreview = (props: any) => {
  const user = useSelector((state: any) => state.userState);
  const dispatch = useDispatch();
  const alert = useRef<any>();
  
  const [docData, setDocData] = useState<any>();
  const [transactionSummary, setTransactionSummary] = useState<any>([]);
  
  const { data, isLoading, isSuccess, isError, error} = useGetPaymentTransactionQuery(props.transactionID);
  
  const populateData = (data: any) => {
    setDocData({...data.payment_transaction});
  }
  
  useEffect(() => {
    isError &&  dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError]);
  
  useEffect(() => {
    isLoading && dispatch(setAlert({type: "progress"}));
  }, [isLoading])
  
  useEffect(() => {
    isSuccess &&  dispatch(clearAlert());
  }, [isSuccess]);
  
  useEffect(() => {
    data && populateData(data);
  }, [data])
  
  return (
    <div className="card">
      <div className="card-header with-actions">
        <div>Receipt</div>
        <div className="card-actions">
        {docData ? (
          <PDFDownloadLink
          document={
            <Receipt data={docData} user={user} {...props} />
          }
          fileName={`Receipt_${docData.receipt_number}`}
          children={() => (
            <button type="button" className="btn secondary small">
              Download
            </button>
          )}
          />
        ) : null}
        </div>
      </div>
      <div className="card-body">
        {docData ? (
          <PDFViewer className="pdf-viewer">
            <Receipt data={docData} user={user} {...props} />
          </PDFViewer>
        ) : null}
      </div>
      <div className="card-footer d-flex flex-row justify-center">
        <button
          type="button"
          className="btn outlined"
          onClick={() => {
            props.modal.hide();
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  spacer: {
    marginBottom: 4,
  },
  divider: {
    height: 1,
    backgroundColor: "black",
  },
  text: {
    fontSize: 8,
    // fontFamily: "Custom",
  },
  outlined: {
    border: "1pt solid black",
  },
  padded: {
    padding: 5,
  },
  grey: {
    backgroundColor: "grey",
  },
  lightGrey: {
    backgroundColor: "#F5F5F5",
  },
  row: {
    flexDirection: "row",
  },
  table: {
    border: "1pt solid #666666",
    borderTopWidth: 0,
    borderLeftWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    border: "1pt solid #666666",
    borderRightWidth: 0,
    borderBottomWidth: 0,
    padding: 4,
    flex: 1,
    fontSize: 8,
  },
});

export default ReceiptPreview;
import { AppService } from '../AppService';

export const UserService = AppService.injectEndpoints({
  endpoints: (builder) => ({
    logout: builder.mutation({
        query: () => ({
            url: "/users/logout",
            method: "GET",
        }),
       invalidatesTags: ['App'],
    }),
    login: builder.mutation({
        query: (data = {}) => ({
            url: "/users/login",
            method: "POST",
            data: data,
        }),
       invalidatesTags: ['App'],
    }),
    changePassword: builder.mutation({
      query: (data = {}) => ({
          url: "/employees/change-password",
          method: "POST",
          data: data,
      }),
    }),
    requestPasswordRecover: builder.mutation({
      query: (data = {}) => ({
          url: "/password-recovery/request",
          method: "POST",
          data: data,
      }),
    }),
    verifyPasswordRecoverRequest: builder.mutation({
      query: (data = {}) => ({
          url: "/password-recovery/verify",
          method: "POST",
          data: data,
      }),
    }),
    recoverPassword: builder.mutation({
      query: (data = {}) => ({
          url: "/password-recovery/recover",
          method: "POST",
          data: data,
      }),
    }),
    getUser: builder.query({
      query: () => ({
          url: "/users/get-user",
          method: "GET",
      }),
      providesTags: ['App', 'GetUser'],
    }),
  }),
  // overrideExisting: false,
});

export const {
    useLoginMutation,
    useLogoutMutation,
    useGetUserQuery,
    useChangePasswordMutation,
    useRequestPasswordRecoverMutation,
    useVerifyPasswordRecoverRequestMutation,
    useRecoverPasswordMutation,
} = UserService;

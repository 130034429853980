import { createSlice } from '@reduxjs/toolkit';

export const ErrorState = createSlice({
  name: 'errorState',
  initialState: 0,
  reducers: {
    countError: (state: any) => {
      state += 1;
    //   return [...state, todo];
  }},
});

// this is for dispatch
export const { countError } = ErrorState.actions;

// this is for configureStore
export default ErrorState.reducer;

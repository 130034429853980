import { useEffect, useRef, useState } from "react";

import { appName } from "../../../utils/constants/constants";
import logo from "../../../assets/images/logo.jpeg";
import { Alert } from "../../../components/alert";
import { Router } from "../../../components/router";
import PasswordRecoverRequest from "./PasswordRecoverRequest";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoverPasswordMutation, useRequestPasswordRecoverMutation, useVerifyPasswordRecoverRequestMutation } from "../../../services/users/UserService";
import PasswordRecoverRequestVerify from "./PasswordRecoverRequestVerify";
import PasswordRecover from "./PasswordRecover";
import secureLocalStorage from 'react-secure-storage';

const ForgotPassword = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const alert = useRef<any>();
  
  const [formData, setFormData] = useState<any>(secureLocalStorage.getItem("fpd") || {});
  const [subPage, setSubPage] = useState<any>("request");
  const [form, setForm] = useState<any>();

  const [ requestPasswordRecover] = useRequestPasswordRecoverMutation();
  const [ verifyPasswordRecoverRequest ] = useVerifyPasswordRecoverRequestMutation();
  const [ recoverPassword ] = useRecoverPasswordMutation();

  const saveData = (data: any) => {
    setFormData(data);
    secureLocalStorage.setItem("fpd", data);
  }

  const request = () => {
    if(!form?.current?.validate()) {
      return;
    }
    alert?.current?.showProgress("Please Wait...");
    let FD: any = {};
    Object.keys(formData).forEach((key) => {
      FD[key] = typeof formData[key]?.trim === "function" ? formData[key]?.trim() : formData[key];
    });
    requestPasswordRecover(FD)
    .unwrap()
    .then((response: any) => {
      if(response?.success){
        alert?.current?.showInfo(`Enter the verification code sent to ${response.verification_code_email}`, 20000);
        saveData(response);
        navigate("verify")
      }
      else{
        alert?.current?.showError(response?.errors);
      }
    })
    .catch((error: any) => {
      alert?.current?.showError(error);
    })
  }

  const verify = () => {
    if(!form?.current?.validate()) {
      return;
    }
    alert?.current?.showProgress("Please Wait...");
    let FD: any = {};
    Object.keys(formData).forEach((key) => {
      FD[key] = typeof formData[key]?.trim === "function" ? formData[key]?.trim() : formData[key];
    });
    verifyPasswordRecoverRequest(FD)
    .unwrap()
    .then((response: any) => {
      if(response?.success){
        alert?.current?.close();
        saveData(response);
        navigate("recover")
      }
      else{
        alert?.current?.showError(response?.errors);
      }
    })
    .catch((error: any) => {
      alert?.current?.showError(error);
    })
  }

  const recover = () => {
    if(!form?.current?.validate()) {
      return;
    }
    alert?.current?.showProgress("Please Wait...");
    let FD: any = {};
    Object.keys(formData).forEach((key) => {
      FD[key] = typeof formData[key]?.trim === "function" ? formData[key]?.trim() : formData[key];
    });
    recoverPassword(FD)
    .unwrap()
    .then((response: any) => {
      if(response?.success){
        alert?.current?.showSuccess("Password has been recovered successfully");
        secureLocalStorage.removeItem("fpd");
        setTimeout(() => {
          navigate("/login")
        }, 1500)
      }
      else{
        alert?.current?.showError(response?.errors);
      }
    })
    .catch((error: any) => {
      alert?.current?.showError(error);
    })
  }

  const subPageProps = {
    formData,
    setFormData,
    setForm,
  };

  useEffect(() => {
    if(location.pathname.indexOf("verify") !== -1){
      setSubPage("verify")
    }
    else if(location.pathname.indexOf("recover") !== -1){
      setSubPage("recover")
    }
    else{
      setSubPage("request")
    }
  }, [location.pathname])
    
  return (
    <div className="h-100vh d-flex flex-column justify-center">
      <div className="d-flex flex-column">
        <div className="d-flex flex-column align-center mb-5">
          <div className="d-flex flex-column align-center mb-1">
            <img src={logo} width={80} height="auto" />
          </div>
          <div className="text-large"><h3>{appName}</h3></div>
        </div>
        <div className="row justify-center">
        <Alert ref={alert} />
          <div className="col-11 col-md-3 col-sm-6 card shadow py-5">
            <div className="card-body py-3 px-5">
              <Router
                routes={[
                  {
                    url: "/",
                    component: PasswordRecoverRequest,
                    props: subPageProps,
                  },
                  {
                    url: "verify",
                    component: PasswordRecoverRequestVerify,
                    props: subPageProps,
                  },
                  {
                    url: "recover",
                    component: PasswordRecover,
                    props: subPageProps,
                  }
                ]}
              />
            </div>
            <div className="d-flex flex-column justify-center align-center px-5">
              <button
                className="btn bg-secondary p-3 w-100"
                onClick={() => {
                  subPage === "verify" ? verify() :
                  subPage === "recover" ? recover() :
                  request();
                }}
              >
                {
                  subPage === "verify" ? "Verify" :
                  subPage === "recover" ? "Reset password" :
                  "Request password reset"
                }
              </button>
              <div className="my-3 text-right w-100">
                <Link to="/login" >Login?</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
      
export default ForgotPassword;
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "../../../components/form";

import {
  TextField,
} from "../../../components/input";

import "./Login.scss";
import { appName } from "../../../utils/constants/constants";
import logo from "../../../assets/images/logo.jpeg";
import { useLoginMutation } from "../../../services/users/UserService";
import { Alert } from "../../../components/alert";
import secureLocalStorage from "react-secure-storage";
import { getValidationRules } from "../../../utils/helpers/helpers";

const Login = (props: any) => {
  const inputValidation = getValidationRules();
  
  const navigate = useNavigate()
  const form = useRef<any>();
  const alert = useRef<any>();
  const inputs: any = useMemo(
    () => Array.from({length: 2}).map(() =>  React.createRef()),
    []
  )
    
  const [formData, setFormData] = useState<any>({});
  const [ userLogin, { data: user, isLoading, isError, error, isSuccess } ] = useLoginMutation();
  const [ showPassword, setShowPassword ] = useState(false);
  
  const login = () => {
    if(!form?.current?.validate()) {
      return;
    }
    userLogin(formData);
  }
  
  useEffect(() => {
    isError && (props.alert || alert)?.current?.showError(error);
  }, [isError])
  
  useEffect(() => {
    isLoading && (props.alert || alert)?.current?.showProgress("Authenticating...");
  }, [isLoading])
  
  useEffect(() => {
    if(isSuccess) {
      if(user?.success){
        // secureLocalStorage.setItem('ud', user?.user);
        navigate('/home');
      }
      else{
        (props.alert || alert)?.current?.showError(user?.errors);
      }
    }
    
  }, [isSuccess])
    
  return (
    <div className="h-100vh d-flex flex-column justify-center">
      <div className="d-flex flex-column">
        <div className="d-flex flex-column align-center mb-5">
          <div className="d-flex flex-column align-center mb-1">
            <img src={logo} width={80} height="auto" />
          </div>
          <div className="text-large"><h3>{appName}</h3></div>
        </div>
        <div className="row justify-center">
        <Alert ref={alert} />
          <div className="col-11 col-md-3 col-sm-6 card shadow py-5">
            <div className="card-body py-3 px-5">
              <div>
                <Form ref={form}>
                  <div className="row">
                    <div className="col-12">
                      <TextField
                        ref={inputs[0]}
                        label="Usernamer"
                        block={true}
                        rules={[inputValidation.required]}
                        onChange={(value) => setFormData({
                          ...formData,
                          username: value
                        })}
                        renderStartIcon={() => <span className="material-icons grey-text">person</span>}
                      />
                    </div>
                    <div className="col-12">
                      <TextField
                        ref={inputs[1]}
                        label="Password"
                        block={true}
                        type={showPassword ? "text" : "password"}
                        rules={[inputValidation.required]}
                        onChange={(value) => setFormData({
                          ...formData,
                          password: value
                        })}
                        renderStartIcon={() => <span className="material-icons grey-text">lock</span>}
                        renderEndIcon={() => (
                          <span
                            className="material-icons grey-text cursor-pointer"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <>visibility_off</> : <>visibility</>}
                          </span>
                        )}
                      />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className="d-flex flex-column justify-center align-center px-5">
              <button
                className="btn bg-secondary p-3 w-100"
                onClick={() => login()}
              >
                Login
              </button>
              <div className="my-3 text-right w-100">
                <Link to="/forgot-password" >Forgot Password?</Link>
                {/* <Link to="#" >Forgot Password?</Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
      
export default Login;
import React from "react";
import { TailSpin } from "react-loader-spinner";

const Loader = () => {
  return (
    <div className="floral-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    // <div className="loader d-flex align-center justify-center">
    //   <TailSpin color="red" radius={"8px"} />
    // </div>
  );
};

export default Loader;

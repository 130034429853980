
// Axios.ts
import axios from "axios";
import { baseUrl } from "../utils/constants/constants";
import { AppService } from "./AppService";
import secureLocalStorage from "react-secure-storage";
const Axios = axios.create({
 baseURL: baseUrl, // Replace with your API base URL
 withCredentials: true,
//  headers: {
//    'Content-Type': 'application/json',
//     Authorization: `Bearer ${TOKEN}`,
//    // Add any other headers or configurations you need
//  },
});

// Add a request interceptor
Axios.interceptors.request.use(
 (config) => {
   // You can modify the request config here, e.g., add authentication headers
   // config.headers.Authorization = `Bearer ${getToken()}`;
   return config;
 },
 (error) => {
   return Promise.reject(error);
 }
);

// Add a response interceptor
Axios.interceptors.response.use(
 (response) => {
   // You can modify the response data here, e.g., handling pagination
   return response;
 },
 (error) => {
    if (error.response?.status === 401 && window.location.href.indexOf("/login") === -1) {
        // if (localStorage.getItem("ehms_api_token")) {
        //   window.axios.post(`${baseUrl}/users/logout`);
        // }
        Axios.get(`${baseUrl}/users/logout`);
        secureLocalStorage.clear();
        return (window.location.href = "/login");
    }
  
    return Promise.reject(error);
 }
);

export default Axios;
import { useLocation } from "react-router-dom";
import { Router } from "../../router";

import "./Body.scss";
import { Alert } from "../../alert";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reportErrors } from "../../../utils/helpers/helpers";
import { Modal } from "../../modal";
import { setChangePassword } from "../../../states/GeneralState";
import PasswordChange from "../../../pages/app/change-password/PasswordChange";

interface BodyProps {
  homeComponent?: any,
  homeUrl?: string,
  hideHomeMenu?: boolean;
  pageComponent: any,
  pageUrl: string,
  pageRoutes: {
    url: string,
    component: any,
    props?: any,
  }[],
};

const Body = (props: BodyProps) => {
  const dispatch = useDispatch();
  const modal = useRef<any>();

  const [alertUnreplaceable, setAlertUnreplaceable] = useState(false);

  const notification = useSelector((state: any) => state.alertState)
  const changePassword = useSelector((state: any) => state.generalState?.changePassword)

  const location = useLocation();
  const alert = useRef<any>();

  const showChangePasswordForm = () => {
    modal.current?.show(
        "Change Password",
        <PasswordChange
          modal={modal}
        />,
        window.pageYOffset,
        "small"
    )
  }

  const {homeComponent, homeUrl, pageComponent, pageUrl, pageRoutes} = props;
  const homeRoute: any = homeComponent && homeUrl ? [{url: homeUrl, component: homeComponent}] : [];

  const showNotification = () => {
    switch(notification?.type) {
      case "progress":
        alert.current?.showProgress(notification.message || undefined);
        break;
      case "success":
        alert.current?.showSuccess(notification.message);
        break;
      case "error":
        alert.current?.showError(notification.message);
        break;
      case "info":
        alert.current?.showInfo(notification.message);
        break;
      case "warning":
        alert.current?.showWarning(notification.message);
        break;
      case "reportError":
        reportErrors(alert.current, notification.message)
        break;
      default:
        alert.current?.close();
    }
  }

  useEffect(() => {
    if(!alertUnreplaceable){
      if(notification.unreplaceable){
        setAlertUnreplaceable(true);
        setTimeout(() => {
          setAlertUnreplaceable(false);
          // showNotification();
        }, 1500)
      }
      showNotification();
    }
  }, [notification])

  useEffect(() => {
    changePassword && showChangePasswordForm();
  }, [changePassword])

  return (
    <div
      className={
      "body " +
      (location.pathname.indexOf("dashboard") !== -1 ? "dashboard" : "")
      }
    >
      <Alert ref={alert} />
      <Router
        routes={[
          ...homeRoute,
          {
            url: pageUrl,
            component: pageComponent,
            props: {routes: pageRoutes, homeUrl: props.homeUrl, hideHomeMenu: props.hideHomeMenu}
          }
        ]}
      />
      <Modal
          onHide={() => dispatch(setChangePassword(false))}
          ref={modal}
      />
    </div>
  );
}

export default Body;

import React from "react";
import { useNavigate } from "react-router-dom";

const AccessDenied = () => {
  const navigate = useNavigate();
  return (
    <div
      className="d-flex align-center justify-center"
      style={{ height: "calc(100vh - 48px - 64px - 76px - 50px)" }}
    >
      <div className="row">
        <div className="flex-grow-1" />
        <div className="col-12 col-md-4 col-sm-6">
          <div className="card transparent">
            <div className="card-body">
              <span
                className="material-icons danger-text d-block mx-auto mb-3"
                style={{ width: "72px", fontSize: "72px" }}
              >
                block
              </span>
              <div className="danger-text text-large text-center mb-1">Access Denied!</div>
              {/* <p>
                Sorry, You do not have access to view this page. Here are things
                you can try:
              </p>
              <ul>
                <li>Contact your IT to give you access</li>
                <li>
                  If not, continue to use other modules you have access to
                </li>
              </ul> */}
              {/* <div className="row justify-center mt-2">
                <button
                  className="btn primary small"
                  onClick={() => navigate("/private/dashboard")}
                >
                  Home
                </button>
              </div> */}
            </div>
          </div>
        </div>
        <div className="flex-grow-1" />
      </div>
    </div>
  );
};

export default AccessDenied;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const PatientService = createApi({
  reducerPath: 'PatientService',
  baseQuery: fetchBaseQuery({
    baseUrl: 'http://199.192.23.117:30003',
    // headers: {Authorization: `Bearer ${TOKEN}`},
}),
  tagTypes: ['Patients'],
  endpoints: (builder) => ({
    getPatients: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/patients",
            method: "GET",
            params: params,
            // headers: {
            //     Authorization: `Bearer ${getTokenFromLocalStorage()}`,
            // },
        }),
    }),
    getPatient: builder.query({
        query: (id) => ({
            url: `/patients/${id}`,
            method: "GET",
        }),
    }),
    createPatient: builder.mutation({
        query: (body = {}) => ({
            url: "/patients",
            method: "POST",
            body: body,
        }),
    //    invalidatesTags: ['Patients'],
    }),
    updatePatient: builder.mutation({
        query: (id, body = {}) => ({
            url: `/patients/${id}`,
            method: "POST",
            body: body,
       }),
       invalidatesTags: ['Patients'],
    }),
    deletePatient: builder.mutation({
        query: (id) => ({
            url: `/patients/${id}`,
            method: "DELETE",
        }),
       invalidatesTags: ['Patients'],
    }),
  }),
});

export const {
    useGetPatientsQuery,
    useGetPatientQuery,
    useCreatePatientMutation,
    useUpdatePatientMutation,
    useDeletePatientMutation
} = PatientService;

import React, { ReactNode } from "react";
import { capitalize, getAge } from "../../utils/helpers/helpers";
import banner from "../../assets/images/logo.jpeg";
import { useSelector } from "react-redux";
// import { baseUrl } from "../../constants";
interface ReportHeaderProps {
  params?: any,
  title?: string,
  patient?: any,
  showAddress?: boolean,
  subtitleClassName?: string,
  subtitle?: string,
  [rest: string]: any,
};

export default function ReportHeader(props: ReportHeaderProps) {
  const user = useSelector((state: any) => state.userState);
  const savedBanner = window.banner;
  const [packageName, setPackageName] = React.useState();

  // const loadPackage = () => {
  //   let url = `${baseUrl}api/reception/sponsor-package/?ehms=v5`;
  //   url += `&Sponsor_ID=${props.patient?.Sponsor_ID}`;
  //   url += `&Registration_ID=${props.patient?.Registration_ID}`;

  //   window.axios
  //     .get(url)
  //     .then((response) => {
  //       setPackageName(response.data?.data);
  //     })
  //     .catch((error) => {});
  // };

  // React.useEffect(() => {
  //   if (patient) {
  //     loadPackage();
  //   }
  // }, [patient]);

  return (
    <div className="report-header pb-2 mb-5">
      {/* <img src={savedBanner || banner} /> */}
      <div
        style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}
      >
        <img
          // fixed={props.fixed}
          // src={user?.company?.logo || banner}
          src={banner}
          style={{
            width: "100%",
            maxWidth: "100%",
            height: "auto",
            flex: 0.1
          }}
        />
        <div
          style={{ flex: 0.9 , display: "flex", flexDirection: "column", alignItems: "center", fontSize: 28}}
        >
          <span>
            {user?.company?.company_name}
          </span>
          <span style={{ fontSize: 21 }}>
            {user?.company?.physical_address}
          </span >
          {props.showTIN &&
          <span style={{ fontSize: 21 }}>
            {user?.company?.tin_number && `TIN: ${user?.company?.tin_number}`}
          </span>}
          <span style={{ fontSize: 21 }}>
            {user?.company?.phone_number && `Phone#: ${user?.company?.phone_number}`}
          </span>
          <span style={{ fontSize: 21 }}>
            {user?.company?.email && `Email: ${user?.company?.email}`}
          </span>
          <span style={{ fontSize: 21 }}>
            {user?.company?.pobox && `P.O. Box: ${user?.company?.pobox}`}
          </span>
        </div>
      </div>

      {props.patient ? (
        <div
          style={{
            width: "100%",
            borderBottom: "1pt solid black",
            paddingBottom: "5pt",
            paddingTop: "10pt",
          }}
        >
          <div className="row justify-space-between">
            <div className="mr-1">
              <div className="row mb-2">
                <span className="text-bold ">PATIENT NAME: </span>

                <span>{capitalize(props.patient.Patient_Name)}</span>
              </div>
            </div>
            <div className="mr-1">
              <div className="row mb-2">
                <span className="text-bold mr-1">PATIENT NUMBER: </span>

                <span>{props.patient.Registration_ID}</span>
              </div>
            </div>
            <div className="mr-1">
              <div className="row mb-2">
                <span className="text-bold mr-1 ">AGE: </span>

                <span>
                  {props.patient.Date_Of_Birth ? getAge(props.patient.Date_Of_Birth) : ""}
                </span>
              </div>
            </div>
            <div className="mr-1">
              <div className="row mb-2">
                <span className="text-bold mr-1 ">GENDER: </span>

                <span>{props.patient.Gender}</span>
              </div>
            </div>
            <div className="mr-1">
              <div className="row mb-2">
                <span className="text-bold mr-1 ">SPONSOR: </span>

                <span>{props.patient.sponsor?.Guarantor_Name}</span>
              </div>
            </div>
            {packageName && (
              <>
                <div>
                  <div className="text-small text-bold red-text">Package</div>
                  <div className="text-small red-text">{packageName}</div>
                </div>
                <div className="mx-2" />
              </>
            )}
            {props.showAddress && (
              <div className="mr-1">
                <div className="row mb-2">
                  <span className="text-bold mr-1 ">PATIENT ADDRESS: </span>

                  <span>
                    {props.patient.Region} {props.patient.Region}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
      <div className="divider mb-3 mt-3" />

      <div className="text-large text-bold text-uppercase text-center">
        {props.title}
      </div>
      {props.params?.from_date && props.params?.to_date && (
        <div className="text-medium text-bold text-uppercase text-center mt-1">
          {props.params?.from_date} - {props.params?.to_date}
        </div>
      )}
      {props.subtitle && (
        <div
          className={
            "text-medium text-bold text-uppercase text-center mt-1 " +
            (props.subtitleClassName || "")
          }
        >
          {props.subtitle}
        </div>
      )}
    </div>
  );
}

import { AppService } from '../AppService';

export const CargoService = AppService.injectEndpoints({
  endpoints: (builder) => ({
    getCargos: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/cargos",
            method: "GET",
            params: params,
        }),
        providesTags: ['App', 'Cargos', 'GetCargos'],
    }),
    getCargo: builder.query({
        query: (id) => ({
            url: `/cargos/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Cargos', 'GetCargo'],
    }),
    getCargoToUpdate: builder.query({
        query: (id) => ({
            url: `/cargos/edit/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Cargos', 'GetCargoToUpdate'],
    }),
    createCargo: builder.mutation({
        query: (data = {}) => ({
            url: `/cargos/save${data.id ? ("/" + data.id) : ""}`,
            method: "POST",
            data: data,
        }),
       invalidatesTags: ['Cargos', 'GetCustomer'],
    }),
    deleteCargo: builder.mutation({
        query: (id) => ({
            url: `/cargos/${id}`,
            method: "DELETE",
        }),
       invalidatesTags: ['GetCargos', 'GetCustomer'],
    }),
  }),
});

export const {
    useGetCargosQuery,
    useGetCargoQuery,
    useGetCargoToUpdateQuery,
    useCreateCargoMutation,
    useDeleteCargoMutation,
} = CargoService;

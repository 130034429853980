import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const UserState = createSlice({
  name: 'userState',
  initialState: {},
  reducers: {
    setUser: (state, action) => {
      return action.payload;
    },
    clearUser: (state: any) => {
      return {}
    }
  },
});

// this is for dispatch
export const { setUser, clearUser } = UserState.actions;

// this is for configureStore
export default UserState.reducer;

import React, { MutableRefObject, useMemo, useRef, useState } from "react";
import { Form } from "../../components/form";
import {
  FileInput,
} from "../../components/input";
import { ConfirmationDialog, Modal } from "../../components/modal";
import { useDownloadTripImportSampleMutation, useGetTripToUpdateQuery, useImportTripMutation } from "../../services/trips/TripService";
import { getValidationRules } from '../../utils/helpers/helpers';
import { useDispatch } from "react-redux";
import { setAlert } from "../../states/AlertState";

interface TripImportProps {
  tripID?: any,
  modal?: MutableRefObject<any>,
}

const TripImport = (props: TripImportProps) => {
  const dispatch = useDispatch();
  const inputValidation = getValidationRules(); 

  const form = useRef<any>();
  const modal = useRef<any>();
  const inputs: any = useMemo(
    () => Array.from({length: 1}).map(() =>  React.createRef()),
    []
);

  const [formData, setFormData] = useState<any>({});

  const [ downloadSampleFile ] = useDownloadTripImportSampleMutation();
  const [ importTrip ] = useImportTripMutation();

  const save = () => {
    if(!form?.current?.validate()) {
      return dispatch(setAlert({type: "error", message: "Please fill all required fields.."}));
    }
    modal?.current?.show(
      "Confirm Import",
      <ConfirmationDialog
      promptMessage="Are you sure want to perform this action"
      onOkay={() => {
        dispatch(setAlert({type: "progress", message: "Processing..."}));
        modal?.current?.hide();
        let FD = new FormData();
        Object.keys(formData).forEach((key) => {
          FD.append(key, formData[key]);
        });
        importTrip(FD)
        .unwrap()
        .then((response: any) => {
            dispatch(setAlert({type: "success", message: "Trip(s) has successfully added", unreplaceable: true}));
          setTimeout(() => {
            props.modal?.current?.hide();
          }, 1500);
        })
        .catch((error: any) => {
          dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message, unreplaceable: true}));
        })
      }}
      onCancel={() => {
        modal?.current?.hide();
      }}
    />
    )
  }
  
  const downloadSample = () => {
    dispatch(setAlert({type: "progress", message: "Downloading..."}));
    downloadSampleFile({})
    .unwrap()
    .then((response: any) => {
      // window.open(response.url);
      window.location.href = response.url;
      dispatch(setAlert({type: "success", message: "File has successfully downloaded"}));
    })
    .catch((error: any) => {
      dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
    })
  }

  return (
    <>
    {/* {formData.cargos?.map((itm: any) => itm)} */}
      <div className="card scrollable y">
        <div className="card-header d-flex align-center justify-end">
          <button
            className="btn bg-secondary"
            onClick={() => downloadSample()}
          >
            <span className="material-icons">file_download</span>
            <span>Download Sample File</span>
          </button>
        </div>
        <div className="card-body">
          <div>
            <Form ref={form}>
              <div className="row">
                <div className="col-12 col-md-6">
                  <FileInput
                    ref={inputs[0]}
                    label="Excel File"
                    placeholder="Chose file..."
                    block={true}
                    requiredDecorator
                    rules={[inputValidation.required]}
                    onChange={(value) => setFormData({
                      ...formData,
                      import_file: value && value[0]
                    })}
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className="card-footer d-flex flex-row">
          <button
            className="btn bg-secondary"
            onClick={() => save()}
          >
            Import
          </button>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default TripImport;

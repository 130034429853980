import React from "react";

interface PaginationProps {
  // data: {
  //   page: number,
  //   pages: number,
  //   total: number,
  //   page_start: number,
  //   page_end: number,
  //   linksLiimit?: number,
  //   [rest: string]: any,
  // },
  data: any,
  onChange?: (page: number) => void,
}

const Pagination = (props: PaginationProps) => {
  const getLinks = () => {
    let pages = props.data.pages,
      page = props.data.page,
      linksLimit = props.data.linksLiimit || 10;
    let links = [];

    if (pages && linksLimit) {
      let sideLinks = Math.floor(linksLimit / 2);

      // links before current page
      links.push({
        label: "keyboard_double_arrow_left",
        page: 1,
        className: "icon",
        disabled: page <= 1,
      });
      links.push({
        label: "keyboard_arrow_left",
        page: page - 1,
        className: "icon",
        disabled: page <= 1,
      });

      for (let i = page - sideLinks; i <= page; i++) {
        if (i === page) {
          links.push({ label: i, page: i, className: "secondary active" });
        } else {
          if (i > 0 && i <= pages) {
            links.push({ label: i, page: i });
          }
        }
      }

      // links after current page
      for (let i = page + 1; i <= page + sideLinks; i++) {
        if (i === page) {
          links.push({ label: i, page: i, className: "secondary active" });
        } else {
          if (i > 0 && i <= pages) {
            links.push({ label: i, page: i });
          }
        }
      }

      links.push({
        label: "keyboard_arrow_right",
        page: page + 1,
        className: "icon",
        disabled: page >= pages,
      });
      links.push({
        label: "keyboard_double_arrow_right",
        page: pages,
        className: "icon",
        disabled: page >= pages,
      });
    }

    return links;
  };

  return (
    <div
      className={"pagination text-center d-flex justify-space-between align-center" + (getLinks().length ? " mt-2" : "")}
    >
      <div>
        Showing {props.data.page_start || 0} - {props.data.page_end || 0} ({props.data.total || 0} total)
      </div>
      <div className="items d-flex flex-row align-center justify-end">
        {getLinks().map((e, i) => (
          <button
            disabled={e.disabled}
            className={`btn small mr-1 ${e.className}`}
            key={i}
            onClick={() => props.onChange && props.onChange(e.page)}
          >
            {e.className && e.className.indexOf("icon") !== -1 ? (
              <span className="material-icons">{e.label}</span>
            ) : (
              <>{e.label}</>
            )}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Pagination;

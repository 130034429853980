import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Form } from "../../../components/form";
import {
  DateInput,
  FileInput,
  SelectInput,
  TextArea,
  TextField
} from "../../../components/input";
import { ConfirmationDialog, Modal } from "../../../components/modal";
import { capitalize, formatDateForDb } from "../../../utils/helpers/helpers";
import { getValidationRules } from '../../../utils/helpers/helpers';
import { numberFormat } from '../../../utils/helpers/helpers';
import { clearAlert, setAlert } from "../../../states/AlertState";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "../../../components/table";
import { useCreatePaymentTransactionMutation, useGetPaymentTripDetailsQuery } from "../../../services/accounting/PaymentTransactionService";
import { Invoice } from "./Invoice";
import { pdf } from "@react-pdf/renderer";
import { Receipt } from "./Receipt";
import { padStart } from './../../../utils/helpers/helpers';

interface TransactionCreateProps {
  trip?: any,
  tripID?: any,
  vehicle?: any,
  employee?: any,
  customer?: any,
  cargo?: any,
  modal?: MutableRefObject<any>,
}

const TransactionCreate = (props: TransactionCreateProps) => {
  const user = useSelector((state: any) => state.userState);
  const dispatch = useDispatch();
  const inputValidation = getValidationRules(); 

  const form = useRef<any>();
  const modal = useRef<any>();
  const inputs: any = useMemo(
    () => Array.from({length: 10}).map(() =>  React.createRef()),
    []
  );

  const [formData, setFormData] = useState<any>({});
  const [pageData, setPageData] = useState<any>({});

  const { data, isLoading, isSuccess, isError, error } = useGetPaymentTripDetailsQuery(props.trip?.trip_id);
  const [ createTransaction ] = useCreatePaymentTransactionMutation();

  const [transactionSummary, setTransactionSummary] = useState<any>([]);


  const [transactionTypes] = useState([
    {label: "Cash", value: "cash", document_type: "receipt"},
    {label: "Invoice", value: "credit", document_type: "invoice"},
  ]);

  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);

  const save = () => {
    if(!form?.current?.validate()) {
      return dispatch(setAlert({type: "error", message: "Please fill all required fields."}));
    }
    modal?.current?.show(
      "Confirm Save",
      <ConfirmationDialog
      promptMessage={`Are you sure want to create ${capitalize(formData?.document_type)}`}
      onOkay={() => {
        dispatch(setAlert({type: "progress", message: "Processing..."}));
        modal?.current?.hide();
        let formDt = {
          ...formData,
          trip: pageData?.trip_id,
          amount: pageData?.payment_amount_due
        }
        let FD: any = {};
        Object.keys(formDt).forEach((key) => {
          if(formDt[key]){
            FD[key] = typeof formDt[key]?.trim === "function" ? formDt[key]?.trim() : formDt[key];
          }
        });
        let FData = new FormData();
        Object.keys(FD).forEach((key) => {
          FData.append(key, FD[key]);
        });
        createTransaction(FData)
        .unwrap()
        .then((response: any) => {
          let docData = response?.payment_transaction || {};
          dispatch(setAlert({type: "success", message: `${capitalize(formData?.document_type)} has successfully created`, unreplaceable: true}));
          generateDoc(docData, formData.document_type);
          setTimeout(() => {
            props.modal?.current?.hide();
          }, 1500);
        })
        .catch((error: any) => {
          dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message, unreplaceable: true}));
        })
      }}
      onCancel={() => {
        modal?.current?.hide();
      }}
    />
    )
  }

  const generateDoc = useCallback(async (data: any, docType: string) => {
    const blob = await pdf(
     docType === "receipt" ? <Receipt data={data} user={user} /> : <Invoice data={data} user={user} />
    ).toBlob();

    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  }, []);

  const populateData = (data: any) => {
    setPageData({...data.payment_trip_details});

    let {
      freight_rate,
      discount,
      discount_type,
      charges,
      total_charges,
      total_amount,
      total_discount,
      payment_amount,
      total_payment,
      payment_amount_due,
    } = data?.payment_trip_details || {};

    setTransactionSummary([
      {
        label: "Subtotal",
        value: numberFormat(freight_rate),
         className: "text-bold"
      },
      {
        label: `Discount${discount_type === "percent" ? `(${numberFormat(discount || 0)}%)` : ""}`,
        value: numberFormat(total_discount),
      },
      ...(
        charges
        ?.filter((charge: any) => charge.charge_category === "tax")
        ?.map((charge: any) => ({
          label: `${charge.charge_name}${charge.charge_type === "percent" ? `(${numberFormat(charge.charge_amount || 0)}%)` : ""} - Tax`,
          value: numberFormat(charge.charge_type === "percent" ? numberFormat(freight_rate * (charge.charge_amount / 100)) : numberFormat(charge.charge_amount)),
        }))
      ),
      ...(
        charges
        ?.filter((charge: any) => charge.charge_category === "other")
        ?.map((charge: any) => ({
          label: `${charge.charge_name}${charge.charge_type === "percent" ? `(${numberFormat(charge.charge_amount || 0)}%)` : ""} - Charge`,
          value: numberFormat(charge.charge_type === "percent" ? numberFormat(freight_rate * (charge.charge_amount / 100)) : numberFormat(charge.charge_amount)),
        }))
      ),
      {
        label: "Total",
        value: numberFormat(payment_amount),
        className: "text-bold"
      },
      {
        label: "Amount Paid",
        value: numberFormat(total_payment),
      },
      {
        label: "Amount Due, TZS",
        value: numberFormat(payment_amount_due),
        className: "text-bold text-large"
      },
    ]);

    data?.payment_methods && setPaymentMethods(data.payment_methods.map((payment_method: any) => ({label: payment_method.payment_method_name, value: payment_method.payment_method_id})));
  }

  useEffect(() => {
    isError &&  dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError]);

  useEffect(() => {
      isLoading && dispatch(setAlert({type: "progress"}));
  }, [isLoading])

  useEffect(() => {
      isSuccess &&  dispatch(clearAlert());
  }, [isSuccess]);

  useEffect(() => {
    data && populateData(data);
  }, [data])

  return (
    <>
      <div className="card scrollable y">
        <div className="card-body">
          <div>
            <Form ref={form}>
              <div className="row">
              <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[0]}
                    label="Transaction Type"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    options={transactionTypes}
                    onChange={(value) => setFormData({
                      ...formData,
                      transaction_type: value?.value,
                      document_type: value?.document_type
                    })}
                  />
                </div>
                {formData.transaction_type === "cash" &&
                <div className="col-12 col-md-4">
                  <DateInput
                    ref={inputs[1]}
                    renderEndIcon={() => (
                      <span className="material-icons">calendar_today</span>
                    )}
                    label="Payment Date"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        payment_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>}
                {formData.transaction_type === "cash" &&
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[2]}
                    label="Payment Method"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    options={paymentMethods}
                    onChange={(value) => setFormData({
                      ...formData,
                      payment_method: value?.value
                    })}
                  />
                </div>}
                {formData.transaction_type === "cash" &&
                <div className="col-12 col-md-4">
                  <TextField
                    label="Payment Amount"
                    block={true}
                    disabled
                    value={numberFormat(pageData?.payment_amount_due)}
                  />
                </div>}
                {formData.transaction_type === "cash" &&
                <div className="col-12 col-md-4">
                  <FileInput
                    label="Attachment"
                    placeholder={"Chose file..."}
                    block={true}
                    onChange={(value) => setFormData({
                      ...formData,
                      supporting_doc: value && value[0]
                    })}
                  />
                </div>}
                {formData.transaction_type === "cash" &&
                <div className="col-12 col-md-4">
                  <TextArea
                    ref={inputs[3]}
                    label="Payment Comment"
                    block={true}
                    onChange={(value) => setFormData({
                      ...formData,
                      comment: value
                    })}
                  />
                </div>}
                <div className="col-12 col-md-4">
                  <TextField
                    label="Customer"
                    block={true}
                    disabled
                    value={pageData?.customer_name}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Ship To"
                    block={true}
                    disabled
                    value={`${pageData?.consignee_name || ""}${pageData?.consignee_contact ? `, ${pageData?.consignee_contact}` : ""}`}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Trip #"
                    block={true}
                    disabled
                    value={padStart(pageData?.trip_id, 4, '0')}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Truck #"
                    block={true}
                    disabled
                    value={pageData?.vehicle_registration_number}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Driver"
                    block={true}
                    disabled
                    value={pageData?.driver_name}
                  />
                </div>
                {formData.transaction_type === "credit" &&
                <div className="col-12 col-md-4">
                  <DateInput
                    ref={inputs[4]}
                    renderEndIcon={() => (
                      <span className="material-icons">calendar_today</span>
                    )}
                    label="Payment Due Date"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        due_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>}
              </div>
              {/*Transaction Items list */}
              <div className="card mt-4">
                <div className="card-body">
                  <div>
                    <Table
                      title="Items"
                      noExport
                      noSearch
                      hideIndex
                      columns={[
                        {label: "Description", name: "description", customRender: true},
                        {label: "Rate, TZS", name: "freight_rate", customRender: true},
                        {label: "Qty/Weight, Kg", name: "quantity", customRender: true},
                        {label: "Amount, TZS", name: "amount", customRender: true},
                      ]}
                      items={data?.payment_trip_details ? [data.payment_trip_details] : []}
                      customRenders={[
                        {
                          columnName: "description",
                          render: (item) => <span>{`Shipping ${item.cargos} cargo from ${item.start_location} to ${item.end_location}`}</span>
                        },
                        {
                          columnName: "freight_rate",
                          render: (item) => <span>{numberFormat(item.freight_rate)}</span>
                        },
                        {
                          columnName: "quantity",
                          render: (item) => <span>{numberFormat(item.cargo_weight)}</span>
                        },
                        {
                          columnName: "amount",
                          render: (item) => <span>{numberFormat(item.freight_rate * 1)}</span>
                        },
                      ]}
                    />
                  </div>
                  <div className="mt-3 d-flex justify-end">
                    <div className="col-12 col-md-5 col-sm-8">
                      <Table
                        key={2}
                        noHeaderRow
                        noExport
                        noSearch
                        noTitle
                        hideIndex
                        tableCellClassName="w-50"
                        columns={[
                          {name: "label"},
                          {name: "value", customRender: true},
                        ]}
                        items={transactionSummary}
                        customRenders={[
                          {
                            columnName: "value",
                            render: (item: any) => <span className={item.className}>{item.value}</span>
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
        {formData?.document_type &&
        <div className="card-footer d-flex justify-center">
          <button
            className="btn bg-secondary"
            onClick={() => save()}
          >
            {formData?.document_type === "invoice" ? "Create Invoice" : "Create Receipt"}
          </button>
        </div>}
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default TransactionCreate;

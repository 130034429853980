import { AppService } from '../AppService';

export const ReportService = AppService.injectEndpoints({
  endpoints: (builder) => ({
    getSummaryReport: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/reports/summary",
            method: "GET",
            params: params,
        }),
        providesTags: ['App', 'Reports', 'GetSummaryReport'],
    }),
    getTripsReport: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/reports/trips",
            method: "GET",
            params: params,
        }),
        providesTags: ['App', 'Reports', 'GetTripsReport'],
    }),
    getExpensesReport: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/reports/expenses",
            method: "GET",
            params: params,
        }),
        providesTags: ['App', 'Reports', 'GetExpensesReport'],
    }),
    getIncomeReport: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/reports/income",
            method: "GET",
            params: params,
        }),
        providesTags: ['App', 'Reports', 'GetIncomeReport'],
    }),
    getIncomeAndExpenseReport: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/reports/income-and-expense",
            method: "GET",
            params: params,
        }),
        providesTags: ['App', 'Reports', 'GetIncomeAndExpenseReport'],
    }),
    getUserActivitiesReport: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/reports/user-activities",
            method: "GET",
            params: params,
        }),
        providesTags: ['App', 'Reports', 'GetUserActivitiesReport'],
    }),
  }),
});

export const {
    useGetSummaryReportQuery,
    useGetTripsReportQuery,
    useGetExpensesReportQuery,
    useGetIncomeReportQuery,
    useGetIncomeAndExpenseReportQuery,
    useGetUserActivitiesReportQuery,
} = ReportService;

import { useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "../../components/modal";
import { useGetEmployeeQuery } from "../../services/employees/EmployeeService";
import ExpenseList from "../expenses/ExpenseList";
import { useParams } from "react-router-dom";
import { ItemDetails } from "../../components/layout/item-details";
import { getModulePermissions, getNoPermissionMessage, hasPermission, numberFormat } from '../../utils/helpers/helpers';
import { useDispatch, useSelector } from "react-redux";
import { clearAlert, setAlert } from "../../states/AlertState";
import EmployeeCreate from "./EmployeeCreate";
import TripList from "../trips/TripList";
import { Table } from "../../components/table";
import PasswordChange from "../app/change-password/PasswordChange";
import { Permissions } from "../settings/hr/permissions";

interface EmployeeDetailsProps {
  employee?: any,
}

const EmployeeDetails = (props: EmployeeDetailsProps) => {
  const user = useSelector((state: any) => state.userState);
  const dispatch = useDispatch();
  const { employeeID } = useParams();

  const modal = useRef<any>();

  const [basicInfo, setBasicInfo] = useState<any>([]);
  const [summary, setSummary] = useState<any>([]);
  const [academicInfo, setAcademicInfo] = useState<any>([]);
  const [bankcInfo, setBankcInfo] = useState<any>([]);
  const [accountInfo, setAccountInfo] = useState<any>([]);

  const { data, isLoading, isError, error, isSuccess } = useGetEmployeeQuery(employeeID);

  const permission = useMemo(
    () => getModulePermissions("employees", user?.permissions),
    [user]
  )

  const onNoAccess = (action?: string, item?: string) => {
		dispatch(setAlert({type: "error", message: getNoPermissionMessage(action, item)}));
	}

  let editButton = (
    <button
      className={`btn bg-secondary mt-3 ${!permission?.update && "disabled"}`}
      onClick={() =>
        permission?.update ?
        editEmployee(data?.employee) :
        onNoAccess("edit", "employee")
      }
    >
      Edit Employee
    </button>
  );

  const editEmployee = (employee: any) => {
    modal.current?.show(
      "Edit Employee Info",
      (<EmployeeCreate
        employeeID={employee.user_id}
        modal={modal}
      />),
    );
  }

  const changePassword = () => {
    modal.current?.show(
      "Change Employee Password",
      <PasswordChange
        modal={modal}
        user={data?.employee}
      />,
      window.pageYOffset,
      "small"
    );
  }

  const showPermissions = () => {
    modal.current?.show(
      `${data?.employee?.role_name} Permissions`,
      <Permissions
        id={data?.employee?.role_id}
        name={data?.employee?.role_name}
        type="role"
        unEditable
        modal={modal}
      />,
    );
  }

  useEffect(() => {
    let employee = data?.employee;

    setSummary({
      title: `${employee?.first_name} ${employee?.last_name}`,
      summary: [
        {value: employee?.email || employee?.phone_number},
        {value: employee?.role_name},
      ],
      details: [
        {label: "Vehicle", value: employee?.vehicle_registration_number || 'N/A'},
        {label: "Trips", value: numberFormat(employee?.total_trips || 0)},
        {label: "Income", value: numberFormat(employee?.total_income || 0)},
        {label: "Allowances", value: numberFormat(employee?.total_allowance || 0)},
      ]
    });

    setBasicInfo([
      {
        label: "First Name",
        value: employee?.first_name,
      },
      {
        label: "Last Name",
        value: employee?.last_name,
      },
      {
        label: "Gender",
        value: employee?.sex == 1 ? 'Male' : 'Female',
      },
      {
        label: "Birth Date",
        value: employee?.birth_date|| '---',
      },
      {
        label: "Birth Place",
        value: employee?.birth_place|| '---',
      },
      {
        label: "Nationality",
        value: employee?.nationality || '---',
      },
      {
        label: "Language(s)",
        value: employee?.languages || '---',
      },
      {
        label: "Marital Status",
        value: employee?.marital_status_name || '---',
      },
      {
        label: "Phone No",
        value: employee?.phone_number || '---',
      },
      {
        label: "Email",
        value: employee?.email || '---',
      },
      {
        label: "Present Address",
        value: employee?.present_address || '---',
      },
      {
        label: "Permanent Address",
        value: employee?.permanent_address || '---',
      },
      {
        label: "ID Card Type",
        value: employee?.id_card_type || '---',
      },
      {
        label: "ID Card Number",
        value: employee?.id_card_number || '---',
      },
      {
        label: "Joining Date",
        value: employee?.joining_date || '---',
      },
      {
        label: "Driving Licence No",
        value: employee?.driving_licence_number || '---',
      },
      {
        label: "Driving Licence Expire Date",
        value: employee?.driving_licence_expire_date || '---',
      },
      {
        label: "Driving Licence Issue Date",
        value: employee?.driving_licence_issue_date || '---',
      },
      {
        label: "Passport No",
        value: employee?.passport_number || '---',
      },
      {
        label: "Passport Expire Date",
        value: employee?.passport_expire_date || '---',
      },
      {
        label: "Passport Issue Date",
        value: employee?.passport_issue_date || '---',
      },
    ]);

    setAcademicInfo([
      {
        label: "Role",
        value: employee?.role_name,
      },
      {
        label: "Designation",
        value: employee?.designation_name || '---',
      },
      {
        label: "Qualification",
        value: employee?.qualification  || '---',
      },
      {
        label: "Total Experience",
        value: employee?.total_experience  || '---',
      },
       {
        label: "Employment Status",
        value: employee?.employment_status_name  || '---',
      },
    ]);

    setBankcInfo([
      {
        label: "Bank Name",
        value: employee?.bank_name || '---',
      },
      {
        label: "Account No",
        value: employee?.bank_account_number || '---',
      },
      {
        label: "Account Name",
        value: employee?.bank_account_name || '---',
      },
    ]);

    setAccountInfo([
      {
        label: "Username",
        value: employee?.username,
      },
      {
        label: "Role",
        value: employee?.role_name,
      },
    ]);

  }, [data]);

  useEffect(() => {
    isError &&  dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError]);

  useEffect(() => {
    isLoading && dispatch(setAlert({type: "progress"}));
  }, [isLoading])

  useEffect(() => {
    isSuccess && dispatch(clearAlert());
  }, [isSuccess]);

  return (
    <>
      <div className="card shadow-sm">
        <div className="card-header">Employee Details</div>
        <div className="card-body">
          <ItemDetails
            itemName="Employee"
            permission={permission}
            details={basicInfo}
            detailsEditAction={editButton }
            summary={summary}
            tabs={[
              <div title="Academic Info">
                <Table
                  key={2}
                  permission={permission}
                  noHeaderRow
                  noExport
                  noSearch
                  noTitle
                  hideIndex
                  tableCellClassName="w-50"
                  columns={[
                    {name: "label"},
                    {name: "value", customRender: true},
                  ]}
                  items={academicInfo}
                  customRenders={[
                    {
                      columnName: "value",
                      render: (item: any) => <span className={item.className}>{item.value}</span>
                    },
                  ]}
                />
                {editButton}
              </div>,
              <div title="Bank Info">
                <Table
                  key={3}
                  permission={permission}
                  noHeaderRow
                  noExport
                  noSearch
                  noTitle
                  hideIndex
                  tableCellClassName="w-50"
                  columns={[
                    {name: "label"},
                    {name: "value", customRender: true},
                  ]}
                  items={bankcInfo}
                  customRenders={[
                    {
                      columnName: "value",
                      render: (item: any) => <span className={item.className}>{item.value}</span>
                    },
                  ]}
                />
                {editButton}
              </div>,
               <div title="Account Info">
                <Table
                  key={3}
                  permission={permission}
                  noHeaderRow
                  noExport
                  noSearch
                  noTitle
                  hideIndex
                  // tableCellClassName="w-50"
                  columns={[
                    {name: "label"},
                    {name: "value"},
                    {name: "action", customRender: true},
                  ]}
                  items={accountInfo}
                  customRenders={[
                    {
                      columnName: "action",
                      render: (item: any) =>
                        item.label === "Username" ?
                        <button
                          className={`btn bg-primary small ${!permission?.update && "disabled"}`}
                          onClick={() =>
                            permission?.update ?
                            changePassword() :
                            onNoAccess("change", "employee info")
                          }
                        >
                         Change Password
                        </button> :
                        item.label === "Role" ?
                        <button
                          className="btn bg-secondary small"
                          onClick={() => showPermissions()}
                        >
                         Permissions
                        </button> :
                        null
                    },
                  ]}
                />
              </div>,
              <TripList
                key={4}
                title="Trips"
                className={!hasPermission("trips", user.permissions) && "disabled"}
                onClick={!hasPermission("trips", user.permissions) ? () => onNoAccess("access", "trips") : undefined}
                exportTitle={`Employee(${data?.employee?.first_name} ${data?.employee?.last_name}) Trips`}
                employee={data?.employee}
                useLocalFilters
              />,
              <ExpenseList
                key={5}
                title="Allowances"
                className={!hasPermission("expenses", user.permissions) && "disabled"}
                onClick={!hasPermission("expenses", user.permissions) ? () => onNoAccess("access", "expenses") : undefined}
                exportTitle={`Employee(${data?.employee?.first_name} ${data?.employee?.last_name}) Expenses`}
                employee={data?.employee}
                expenseCategoryID={1}
                useLocalFilters
              />,
            ]}
          />
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default EmployeeDetails;
